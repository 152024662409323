import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import connect from 'react-redux/es/connect/connect';
import { getUserDetails } from "../../state/reducers/user";
import { ModulActivationController } from '../../controllers/ModulActivationController';
import { history } from '../../state/store';
import renderHTML from 'react-render-html';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import Cookies from 'universal-cookie';


function DashboardBox(props) {
    let cookies = new Cookies();
    const ModulActivationControllerInst = ModulActivationController.getInstance();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [modulName, setModulName] = useState('');
    const [domain, setDomain] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [active, setActive] = useState(false);
    const [isActivationOpen, setIsActivationOpen] = useState(false);
    const [modulCookieValues, setModulCookieValues] = useState([]);


	useEffect(() => {
        setModulName(props.title);
        if (typeof window !== 'undefined') {
            setDomain(window.location.hostname);
        }
        setUserId(props.userDetails[0].id);
        const firstName = props.userDetails[0].firstname || '';
        const lastName = props.userDetails[0].lastname || '';
        setUserName(`${lastName} ${firstName}`);
        setUserEmail(props.userDetails[0]?.email || '');
        setActive(props.active);
        setIdentifier(props.identifier);
	}, [props]);
    
	useEffect(() => {
        getCookies();
	}, [identifier]);

    function getCookies() {
        let existingArray = [];
        const cookieValue = cookies.get('modul-activation');
        
        if (!cookieValue) {
            console.log('No cookie found, returning empty array');
            return existingArray;
        }
        
        try {
            if (cookieValue.includes(',')) {
                existingArray = cookieValue.split(',').map(item => item.trim());
            } else {
                existingArray = [cookieValue.trim()];
            }
        } catch (e) {
            console.error('Failed to parse cookie value:', e);
        }

        setModulCookieValues(existingArray);
    }
    

    function updateCookie(newIdentifier) {

        let existingArray = modulCookieValues;

        if (!existingArray.includes(newIdentifier)) {
            existingArray.push(newIdentifier)
        }

        setModulCookieValues(existingArray);
        const expiresOneDay = new Date(Date.now() + 24 * 60 * 60 * 1000); // 1 day from now
        cookies.set('modul-activation', existingArray.toString(), { path: '/', expires: expiresOneDay });
    }

    function handleActivation() {
        setLoading(true);
        ModulActivationControllerInst.activationEmail(userId, userEmail, userName, modulName, domain)
            .then(response => {
                if (response.statusCode === 200) {
                    Toastify({
                        text: "Sikeres üzenetküldés",
                        close: true,
                        stopOnFocus: false, 
                        duration: 3000,
                        style: {
                            background: "#63c965"
                        },
                    }).showToast();

                    updateCookie(identifier);
                    ModulActivationControllerInst.activationLog(userId, userEmail, userName, modulName, domain)
                } else {
                    Toastify({
                        text: "Sikertelen üzenetküldés",
                        close: true,
                        stopOnFocus: false,
                        duration: 3000,
                        style: {
                            background: "#f06640"
                        },
                    }).showToast();
                }
            })
            .catch(error => {
                Toastify({
                    text: "Sikertelen üzenetküldés",
                    close: true,
                    stopOnFocus: false,
                    duration: 3000,
                    style: {
                        background: "#f06640"
                    },
                }).showToast();
            })
            .finally(() => {
                setLoading(false);
                setIsActivationOpen(false);
            });
    }

    function navigateToLink() {
        if(props.route) {
            if(props.route.includes('http')) {
                window.open(props.route, '_blank');
            }else {
                history.push(props.route)
            }
        }
    }
    
    const handleCloseActivation = () => setIsActivationOpen(false);
 
    return(
        <>
        <Container className={active ? "dashboard-card-body" : "inactive-card dashboard-card-body"} onClick={props.type !== "new" ? navigateToLink : undefined} >
            <div className="dashboard-card-inside">
                <Row className="dashboard-card-header">
                    {props.active && 
                    <Col lg="12">
                        <i role="button" className="dashboard-card-button" style={{backgroundColor: `${(process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT)}`}} onClick={() => { navigateToLink() }}>...</i>
                    </Col>}
                    <Col lg="12" className="dashboard-header-col">
                        <p className="dashboard-card-type">{props.type}</p>
                        <p className="dashboard-card-title" style={{color: props.active ? "black" : "#ababab"}}>{props.title}</p>
                        <div className="dashboard-card-desc">{renderHTML(props.body)} {props.children} </div>
                    </Col>
                </Row>
                <div className="dashboard-card-footer">
                    {props.smart ?
                    <>
                        <Row className="dashboard-card-footer-header">
                            <Col sm={12} md={6} lg={7} xl={7} xs={12/props.smart.data.length} className="footer-title">Utolsó módosítás</Col>
                            {props.smart.title.map((title, index) => {
                                return(<Col key={index} sm={12} md={6} lg={5} xl={5} xs={12/props.smart.data.length}>{title}</Col>);
                            })}
                        </Row>
                        <Row className="dashboard-card-footer-details">
                            {props.smart.data.map((data, index) => {
                                return(
                                    <div key={index} className="footer-details-answer">
                                        <Col sm={12} md={6} lg={7} xl={7} xs={12/props.smart.data.length}>{data}</Col>
                                    </div>
                                );
                            })}
                        </Row>
                    </>
                    :
                    null
                    }
                </div>
            </div>
        </Container>
        {!active && 
            <>
            {!modulCookieValues.includes(identifier) ?
                <div className="dashboard-activate" onClick={() => {setIsActivationOpen(true)}}>
                    Aktiválja a szolgáltatást
                </div>
            :
                <div className="dashboard-activate-requested">
                    Aktiválási igény elküldve
                </div>
            }
            </>
        }
        <Modal size="lg" show={isActivationOpen} onHide={handleCloseActivation}>
            <Modal.Header closeButton>
                <Modal.Title>Aktiválás</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Jelenleg Önnek/szervezetének nincs jogosultsága a modul használatához.
                    További információért vegye fel kollégáinkkal a kapcsolatot.
                    Az alábbi gomb megnyomásával tudja igényét jelezni: “Aktiválás kérése”,
                    és munkatársunk hamarosan megkeresi Önt!</p>
            </Modal.Body>  
            <Modal.Footer>
                <Button variant="primary" onClick={handleActivation}>
                    {loading ? <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div> : 'Aktiválás kérése'} 
                </Button>
            </Modal.Footer>         
        </Modal>
        </>
        )
}

const mapStateToProps = state => ({
	userDetails: getUserDetails(state)
});

export default connect(
    mapStateToProps
)(DashboardBox);