import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { history } from '../state/store';
import { ReactComponent as Logo } from '../assets/images/header_dashboard_logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import connect from 'react-redux/es/connect/connect';
import {getNavigationState, toggleView, setMapType, getView, toggleSettings, toggleSidebar, resetUiState} from '../state/reducers/ui';
import { getUserName, resetUserSettings, getUserPhoto, getUserRole } from '../state/reducers/user';
import SettingPopup from './ui/SettingPopup';
import ReactTooltip from "react-tooltip";
import mqtt from 'mqtt';

/**
 * Header Component
 * 
 * Basic Functionality :
 *  - Routing comp for Map, Project and Dashboard. 
 * 	- Highlight selected Componenet
 * 	- Access to Profile, Notifications and ModeSwitcher (SMART or Normal view)
 * 
 * **/
function Header(props) {

	const options = {
		"host": process.env.REACT_APP_MQTT_SERVER_HOST,
		"protocol": process.env.REACT_APP_MQTT_SERVER_PROTOCOL,
		"port": process.env.REACT_APP_MQTT_SERVER_PORT,
		"path": process.env.REACT_APP_MQTT_SERVER_PATH,
		"username":process.env.REACT_APP_MQTT_SERVER_USERNAME,
		"password":process.env.REACT_APP_MQTT_SERVER_PASSWORD,
	};

	const [status, setConnectStatus] = useState('');
	const [payload, setPayload] = useState('');
	const [client, setClient] = useState(null);
	const [subbed, setSubbed] = useState(false);
	const location = useLocation();
	

	function resetAlert() {
		setPayload('')
	}

	function showSettings(e) {
		props.toggleSettings();
	}
	
	function handleLogout() {
		sessionStorage.clear();
		localStorage.clear();
		history.push("/login");

		//clear everything
		props.resetUiState();
		props.resetUserSettings();
	}

	function toggleView(e) {
  	
		e.preventDefault();
		const id = e.currentTarget.attributes.id.value;
		history.push('/'+id);
		props.toggleView(id);
	}

	function hideSetting() {
		props.toggleSettings();
	}

	const mqttConnect = (mqttOption) => {
		let client = mqtt.connect(`${options.protocol}://${options.host}:${options.port}`, mqttOption);
		setClient(client);
		if(!subbed) {
			client.subscribe('app/erp/alert', function(err) {
				setSubbed(true);
			})
		}
	};

	useEffect(() => {
		if (client) {
			client.on('connect', () => {
				setConnectStatus('Csatlakozva');
			});

			client.on('error', (err) => {
				console.error('Connection error: ', err);
				setConnectStatus('Nincs kapcsolat');
				client.end();
			});

			client.on('reconnect', () => {
				setConnectStatus('Újracsatlakozás...');
			});

			client.on('message', (topic, message) => {
				const payload = { 
					topic, 
					message: JSON.parse(message)
				};

				if(payload.topic === 'app/erp/alert') {
					setPayload(payload.message);
				}
			});
		}
	}, [client]);

	useEffect(() => {
		if(!client && process.env.REACT_APP_MQTT_ENABLED === 'true') {
			mqttConnect(options);
		}
	}, []) // eslint-disable-line

	return (
		<header>
			<div className={props.navigationState.sidebar === true ? 'mobile-menu-opener active' : 'mobile-menu-opener'} role="button" onClick={() => props.toggleSidebar()}><span></span><span></span><span></span></div>
			<nav className="navbar navbar-expand-lg navbar-light fixed-top">
				<div className='navbar-brand-block'>
					<Link className="navbar-brand" to="/">
						<Logo height="29" />
					</Link>
					{process.env.REACT_APP_TITLE && <div className='city-name'>{process.env.REACT_APP_TITLE}</div>}
				</div>
				{(() => {
					if (props.view === "dashboard" && location.pathname !== "/interface") {
						return (
							<p className="header-page-heading">VEZÉRLŐPULT</p>
						)
					} else if (props.view === "smart") {
						return (
							<p className="header-page-heading">SMART ELEMEK</p>
						)
					} else if (props.view === "kataszter") {
						return (
							<p className="header-page-heading">KATASZTEREK</p>
						)
					} else if (props.view === "thirdparty" && location.pathname !== "/interface") {
						return (
							<p className="header-page-heading">KÜLSŐ SZOFTVERKAPCSOLAT</p>
						)
					}
					else if (props.view === "citymarketing") {
						return (
							<p className="header-page-heading">VÁROSÜZEMELTETÉS</p>
						)
					}
					else if (location.pathname === "/interface") {
						return (
							<p className="header-page-heading">IRÁNYÍTÓFELÜLET</p>
						)
					}else {
						return (
							<div></div>
						)
					}
				})()}
				<ul className="navbar-nav ml-auto">
					<li className={props.navigationState.view ==="project" ? 'active nav-item no-border small ': 'nav-item no-border small '} onClick={ (e) => props.userAuth !== 'USER' ? toggleView(e) : null} id="project">
						{props.userAuth !== 'USER' ?
						<>
							<i><FontAwesomeIcon size="lg" icon={['far', 'list']} /></i>
							<span>Projekt</span>
						</>
						:
						<div style={{float:"left", padding:"4px 3px 3px 7px"}}>
							<a data-tip data-for="project-tooltip" data-iscapture="true" href={() => false} >
								<i><FontAwesomeIcon size="lg" icon={['far', 'list']} /></i>
								<span>Projekt</span>
							</a>
							<ReactTooltip
								id="project-tooltip"
								type="light"
								place="bottom"
								multiline={true}>
								<span>Nincs jogosultsága a projektek megtekintéséhez.</span> 
							</ReactTooltip>
						</div>}
					</li>
					<li className={props.navigationState.view ==="kataszter" ? 'active nav-item no-border small ': 'nav-item no-border small '} onClick={toggleView} id="kataszter">
						<i><FontAwesomeIcon size="lg" icon={['far', 'clipboard-list']} /></i>
						<span>Kataszter</span>
					</li>
					<li className={props.navigationState.view ==="map" ? 'active nav-item no-border small ': 'nav-item no-border small '} onClick={toggleView} id="map">
						<i><FontAwesomeIcon size="lg" icon={['far', 'map-marked-alt']} /></i>
						<span>Térkép</span>
					</li>
					<li className="nav-item no-border small user">							
						<div>
							<div className="user-img"><img alt="user-img" src={'/logos/def_user.png'} width="35" height="35"/></div>
							<span>{props.userName}</span>
							{payload !== '' && <div className="alert-message">1</div>}
						</div>
					</li>
					<li onClick={handleLogout} className="nav-item no-border small logout">
						<i><FontAwesomeIcon size="2x" icon={['far', 'sign-out']} /></i>	
						<span>Kilépés</span>
					</li>
				</ul>
			</nav>
			{ props.navigationState.settingsShown ? <SettingPopup closeSetting={hideSetting} shown={props.navigationState.settingsShown ? 'shown' : ''} alerts={payload} mqttStatus={status} resetAlert={resetAlert} /> : null }
		</header>
	)
}

const mapStateToProps = state => ({
	navigationState: getNavigationState(state),
	userName: getUserName(state),
	userPhoto: getUserPhoto(state),
	userAuth: getUserRole(state),
	view: getView(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleView: (id) => {
		dispatch(toggleView(id));
	},
	setMapType: (maptype) => {
		dispatch(setMapType(maptype));
	},
	toggleSettings: () => {
		dispatch(toggleSettings())
	},
	toggleSidebar: () => {
		dispatch(toggleSidebar());
	},
	resetUserSettings: () => {
		dispatch(resetUserSettings())
	},
	resetUiState: () => {
		dispatch(resetUiState())
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Header);