import React, { useEffect, useState, useRef } from 'react';
import OlSourceXYZ from 'ol/source/XYZ';
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import {defaults as defaultInteractions, DragRotateAndZoom} from 'ol/interaction';
import Config from '../../../config/config';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Overlay from 'ol/Overlay';

export default function OwletMap(props) {
 
    const [olmap, setMap] = useState();
    const [selected, setSelected] = useState(false);
    const [displayData, setDisplayData] = useState("");

    const popup = useRef();

    let url = Config.map.availableTiles.default.url;
    const layers = {};
    layers.tile = {};
    layers.tile.source = new OlSourceXYZ({ url });
    layers.tile.layer = new OlLayerTile({
        source: layers.tile.source
    });


    useEffect(() => {
        
        let overlay = new Overlay({
            element: popup.current,
            autoPan: true,
            autoPanAnimation: {
              duration: 250,
            },
        });

		setTimeout(() => {

            let olmap = new OlMap({
                interactions: defaultInteractions().extend([
                    new DragRotateAndZoom()
                ]),
                target: null,
                layers: [
                    layers.tile.layer
                ],
                overlays: [overlay],

                view: new OlView({
                    center: fromLonLat(Config.map.center),
                    zoom: Config.map.zoom,
                })
            });
            olmap.setTarget("inner-map");
            setMap(olmap);
            
            olmap.on('singleclick', function (evt) {
                var coordinate = evt.coordinate;			
                const feature = olmap.forEachFeatureAtPixel(evt.pixel, function(feature) {
                    return feature;
                });
                if(feature) {
                    setSelected(feature);
                    overlay.setPosition(coordinate);
                }  else {
                    setSelected(false)
                    overlay.setPosition(undefined);
                }
              });

        }, 1000);	

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function style() {
        let dummyIcon = {
			anchor: [0.5,1],
			src: "/markers/lights.png",
			scale: 1
		}
		return new Style({
			image: new Icon(dummyIcon)
        });
    }

    function setupLamps(lamps) {
        let active = false;
        let features = [];
        lamps.forEach(lamp => {
            let feature = new Feature(new Point(fromLonLat(lamp.device.geometry.coordinates)))
            feature.properties = lamp.device
            features.push(feature);
        });
        let source = new VectorSource({crossOrigin: 'Anonymous'});
        source.addFeatures(features);

        olmap.getLayers().forEach(function(layer) {
            if(layer.get('id') === "lamps") {
                active = layer;
            }
        });
        
        if(active) {
            active.setSource(source);
        }else {
            let layer = new VectorLayer({
                source: source,
                style: style
            });
            layer.set('id', "lamps");
            olmap.addLayer(layer);
        }

    }

    useEffect(() => {
        if(olmap !== undefined) {
            setupLamps(props.lamps)
        }
    }, [props.lamps, olmap]); // eslint-disable-line

    useEffect(() => {
        if(selected) {
            // let data = selected.properties;
            setDisplayData(
                <div className="loader-container">
                        <div className="loader" style={{margin:"auto"}} />
                    </div>
            );
        }
    }, [selected]) // eslint-disable-line

    return(
        <div>
            <div id="inner-map" className="inner-map" style={{height:"450px", width:"100%"}}/>

            <div ref={popup} id="popup" className="ol-popup">
                <div id="popup-content">{displayData}</div>
            </div>
        </div>
    );
}