import Config from "../config/config";


export class ProjectController {

    private static instance: ProjectController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {
    }

    /**
     * Controlling the singleton
     */
    public static getInstance(): ProjectController {
        if (!ProjectController.instance) {
            ProjectController.instance = new ProjectController();
        }
        return ProjectController.instance;
    }



    public getAllProject() {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.project.list;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username,
                    'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`,
                    'Access-Control-Allow-Origin': '*'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        });
    };

};
