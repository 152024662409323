// Import interfaces
import { ModulActivationObject } from "../ts/interfaces/modulactivationcontroller_interfaces";
import Config from "../config/config";

export class ModulActivationController {

    private static instance: ModulActivationController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): ModulActivationController {
        if (!ModulActivationController.instance) {
            ModulActivationController.instance = new ModulActivationController();
        }
        return ModulActivationController.instance;
    }

    /**
     * Send message to the backend with parameters: 
     * @param userId
     * @param userEmail
     * @param modulName
     * @param domain
     */
    public activationEmail(userId: string, userEmail: string, userName: string, modulName: string, domain: string) {

        let data: ModulActivationObject = {
            userId,
            userEmail,
            userName,
            modulName,
            domain
        };

        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.modul.activation.email;
            // const url = 'http://localhost:3000/api/modul/activation/email';

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username,
                    'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(data)
            })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
            })
            .then(data => {
                if (data.error) {
                    resolve({ statusCode: 401, data: data.error });
                } else {
                    resolve({ statusCode: 200, data: data });
                }
            })
            .catch(error => {
                console.error("failed to fetch", error);
                resolve({ statusCode: 500, error: error.message || "Internal Server Error" });
            });
        });
    }

public activationLog(userId: string, userEmail: string, userName: string, modulName: string, domain: string) {

    let data: ModulActivationObject = {
        userId,
        userEmail,
        userName,
        modulName,
        domain
    };

    return new Promise((resolve, reject) => {
        // const url = process.env.REACT_APP_API_URL + Config.endpoints.list.modul.activation.log;
        const url = 'http://localhost:3000/api/modul/activation/log';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': sessionStorage.username,
                'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`,
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res.json();
        })
        .then(data => {
            if (data.error) {
                resolve({ statusCode: 401, data: data.error });
            } else {
                resolve({ statusCode: 200, data: data });
            }
        })
        .catch(error => {
            console.error("failed to fetch", error);
            resolve({ statusCode: 500, error: error.message || "Internal Server Error" });
        });
    });
}
}