import update from 'immutability-helper';
import merge from 'lodash/merge'
import Cookies from 'universal-cookie';

let cookies = new Cookies();

const INITIAL_STATE = {
    navigationState: {
        loadingData: false,
        loadingMessage: '',
        dataLoaded: false,
        approved:0,
        toggled: null,
        sidebar: true,
        sidebarType: "closed",
        sidebarLayout: null,
        sidebarPermanent: false,
        smartMode: true,
        view: 'dashboard',
        settingsShown: false,
        openedBox: null,
        canDraw: false,
        detail: false,
        markerdata: null,
        mapWidth: 0,
        projectSidebar: false,
        isFiltering: false,
        isDataChanged: false,
        newVersion: false,
        activeProject: "",
        activeCadastre: null,
        layers: {
            smart: true,
            lights: true
        },
        query: {
            term: '',
            searchWords: [],
            searchFilters: {
                state: '0',
            },
        },
        filterOptions: [
            {
                group: 'lights',
                fields: {
                    type:[
                        {
                            label: 'Teceo',
                            value: '1'
                        }
                    ],
                    utpadka: [
                        {
                            label: '5m',
                            value: 5,
                        }
                    ]
                }
            }
        ]
    },
    mapState: {
        type: 'global',
        typefilter: null,
        selectedMapInteraction: "",
        layerOptions : {
            loadingLayer:false,
            allLoadedLayers: [],
            loadedCadastreLayers: [],
            loadedProjectLayers: [],
            loadedDynamicCadastreLayers: [],
            loadedTiles: [],
            searchWords: [],
            searchFilters: {

            }
        }

    },
    projectState: {
        type: 'list',
        listfile: null,
        tablefile: null,
        category: 'kozvil',
        // openedProjectViews:sessionStorage.projects ? sessionStorage.projectHeader.join(",") : [],
        openedProjectViews:cookies.get("projectHeader") !== undefined ? cookies.get("projectHeader") : [],

    }
};

const uiReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
    case RESET:
        return INITIAL_STATE;

    case DATA_CHANGE_TOGGLE:
        return update(state, {
            navigationState: {
                isDataChanged: { $apply:(isDataChanged) => !isDataChanged },
               
            }
        });
    case SIDEBAR_TOGGLE:
        return update(state, {
            navigationState: {
                sidebar: { $apply:(sidebar) => !sidebar },
                toggled: { $set: null}
            }
        });
    case SIDEBAR_TYPE:
        return update(state, {
            navigationState: {
                sidebarType: { $set: payload.type}
            }
        });
    case SIDEBAR_CLOSE:
        return update(state, {
            navigationState: {
                sidebar: { $set:false },
                toggled: { $set: null},
                sidebarPermanent: {$set: true},
            }
        });

    case SET_SMART_MODE:
        return update(state, {
            navigationState: {
                smartMode: { $set: payload.mode}
            }
    });
    case SET_PROJECT_APPROVED:
        return update(state, {
            navigationState: {
                approved: { $set: payload.approved}
            }
    });

    case PROJECT_SIDEBAR_TOGGLE:
        return update(state, {
            navigationState: {
                projectSidebar: { $apply:(projectSidebar) => !projectSidebar },
            }
        });
    case PROJECT_SIDEBAR_CLOSE:
        return update(state, {
            navigationState: {
                projectSidebar: { $set: false },
            }
        });
    case PROJECT_SIDEBAR_OPEN:
        return update(state, {
            navigationState: {
                projectSidebar: { $set: true },
            }
        });
    case TOGGLE_DRAW:
        return update(state, {
            navigationState: {
                canDraw: { $apply:(canDraw) => !canDraw },

            }
    });
    case TOGGLE_SETTINGS:
        return update(state, {
            navigationState: {
                settingsShown: { $apply:(settingsShown) => !settingsShown },

            }
        });
    case SET_LAYER:
        return update(state, {
            navigationState: {
                layers : {
                    [payload.item] : { $set: payload.value }
                }

            }
    });
    case SET_MAP_DETAIL_DISPLAY:
        return update(state, {
            navigationState: {
                detail: { $set: true },

            }
        });
    case SET_MAP_DETAIL_HIDE:
        return update(state, {
            navigationState: {
                detail: { $set: false },

            }
        });
    case NAVIGATION_ITEM_TOGGLE:
        return update(state, {
            navigationState: {
                toggled: { $set: payload.itemId },

            }
        });
    case SET_OPENED_BOX:
        return update(state, {
            navigationState: {
                openedBox: { $set: payload.box },

            }
        });
    case VIEW_TOGGLE:
        return update(state, {
            navigationState: {
                view: { $set: payload.itemId }

            }
        });
    case SET_MAP_TYPE:
        return update(state, {
            mapState: {
                type: { $set: payload.maptype }
            }
        });
    case SET_MAP_WIDTH:
        return update(state, {
            navigationState: {
                mapWidth: { $set: payload.width }
            }
        });
    case SET_PROJECT_TYPE:
        return update(state, {
            projectState: {
                type: { $set: payload.projecttype },

            }
        });
    case SET_MAP_DETAIL:
        return update(state, {
            navigationState: {
                markerdata: { $set: payload.markerdata }
            }
        });
    case SET_LIST_FILE:
        return update(state, {
            projectState: {
                listfile: { $set: payload.file }
            }
        });
    case SET_LOADING:
        return update(state, {
            navigationState: {
                loadingData: { $set: payload.loadingData }
            }
        });
    case SET_LOADING_MESSAGE:
        return update(state, {
            navigationState: {
                loadingMessage: { $set: payload.message }
            }
        });
    case SET_DATALOADED:
        return update(state, {
            navigationState: {
                dataLoaded: { $set: payload.dataLoaded }
            }
        });
    case SET_TABLE_FILE:
        return update(state, {
            projectState: {
                tablefile: { $set: payload.file }
            }
        });

    //TEMP
    case SET_TYPE_FILTER:
        return update(state, {
            mapState: {
                typefilter: { $set: payload.type },

            }
        });
    case SET_CATEGORY:
        return update(state, {
            projectState: {
                category: { $set: payload.category },

            }
        });
    case MAP_SEARCH:
        return update(state, {
            navigationState: {
                query: {
                    term: { 
                        $apply: (current) => {
                            if(payload.query === "" && !Object.keys(state.navigationState.query.searchFilters).length) {
                                state.navigationState.isFiltering = false;
                            }else {
                                state.navigationState.isFiltering = true;
                            }
                            return payload.query
                        }
                    },
                }
            }
        });
    case MAP_FILTER_ADD:
        return update(state, {
            navigationState: {
                query: {
                    searchFilters: {
                        $apply: (current) => {
                            let obj = {}
                            let key = Object.keys(payload.filterObject)[0];
                            if(Object.values(payload.filterObject)[0] === "null") {
                                delete current[key];
                                obj = merge({}, current, {})
                            }
                            else{
                                if(current !== null) {
                                    obj = merge({}, current, payload.filterObject)
                                }
                                else {
                                    obj = payload.filterObject
                                }
                            }
                            if(Object.keys(obj).length && !state.navigationState.isFiltering) {
                                state.navigationState.isFiltering = true;
                            }
                            
                            if(!Object.keys(obj).length && state.navigationState.isFiltering && state.navigationState.query === "") {
                                state.navigationState.isFiltering = false;
                            }
                            return obj;
                        }
                    }
                },

            }
        });
    case FILTER_JSON_INFO:
        return update(state, {
             navigationState: {
                filterOptions : {
                     $set: payload.filters
                }

            }
        });
    case MAP_FILTER_REMOVE:
        return update(state, {
            navigationState: {
                query: {
                    searchFilters: {
                        $unset: [payload.attribute]
                    }
                },

            }
        });
    case MAP_FILTER_RESET:
        return update(state, {
            navigationState: {
                query: {
                    term: { $set: '' },
                    searchWords: { $set: [] },
                    searchFilters: {
                        $set: {}
                    }
                },
                isFiltering:{ $set: false }

            }
        });
    case SET_MAP_INTERACTION:
        return update(state, {
            mapState: {
                selectedMapInteraction: { $set: payload.interaction },

            }
        });
        case SET_MAP_LAYEROPTION_FILTER:
            return update(state, {
                mapState: {
                    layerOptions: {
                        searchFilters: {
                            $apply: (current) => {
                                let key = Object.keys(payload.filterObject)[0];
                                if(Object.values(payload.filterObject)[0] === "null") {
                                    delete current[key];
                                    return merge({}, current, {})
                                }
                                else{
                                    if(current !== null) {
                                        return merge({}, current, payload.filterObject)
                                    }
                                    else {
                                        return payload.filterObject
                                    }
                                }
                            }
                        }
                    },
    
                }
            });
    case SET_MAP_LAYEROPTION_SELECTED:
        return update(state, {
            mapState: {
                layerOptions: { 
                    allLoadedLayers: { $apply: (current) => {
                        if(current.includes(payload.name)) {
                            return current.filter(x => x !== payload.name)
                        }else {
                            return [...current, payload.name]

                        }
                    }}
                 },

            }
        });
    case SET_MAP_LAYEROPTION_FILTER_CLEAR:
        return update(state, {
            mapState: {
                layerOptions: {
                    searchFilters: { $set: {} },
                    searchWords: { $set: [] }
                },

            }
        });
    case SET_MAP_LAYEROPTION_FILTER_SEARCHWORD:
        return update(state, {
            mapState: {
                layerOptions: {
                    searchWords: {$set: payload.query ? payload.query.split(QUERY_SEPARATOR) : []  },
                }
            }
        });
    case SET_NEW_VERSION:
        return update(state, {
            navigationState: {
                newVersion: { $set: payload.version}
            }
        });
    case SET_SIDEBAR_LAYOUT:
        return update(state, {
            navigationState: {
                sidebarLayout: { $set: payload.layout }
            }
        });

    case SET_PROJECTEXPORT_TYPE:
        return update(state, {
            navigationState: {
                activeProject: { $set: payload.activeProject}
            }
            
        });    
    case SET_LAYER_LOADING:
        return update(state, {
            mapState: {
                layerOptions: {
                    loadingLayer : {$set: payload.loading}
                }
            }
            
        });  
    case SET_LOADED_CADASTRE_LAYERS:
        return update(state, {
            mapState: {
                layerOptions: { 
                    loadedCadastreLayers: { 
                        $apply: (current) => {
                        if(current.map(x => x.name).includes(payload.cadastre_ref.name)) {
                            return current.filter(x => x.name !== payload.cadastre_ref.name)
                        }else {
                            return [...current, payload.cadastre_ref]

                        }
                    }}
                 },

            }
            
        });
    case UPDATE_LOADED_DYNAMIC_CADASTRE_LAYERS:
        return update(state, {
            mapState: {
                layerOptions: { 
                    loadedDynamicCadastreLayers: { 
                        $apply: (current) => {
                        if(current.map(x => x.cadastre_ref).includes(payload.cadastre.cadastre_ref)) {
                            return current.filter(x => x.cadastre_ref !== payload.cadastre.cadastre_ref)
                        }else {
                            return [...current, payload.cadastre]

                        }
                    }}
                    },

            }
            
        });
    case SET_LOADED_DYNAMIC_CADASTRE_LAYERS:
        return update(state, {
            mapState: {
                layerOptions : {
                    allLoadedLayers: {
                        $splice: [
                            [payload.dragIndex, 1],
                            [payload.hoverIndex, 0, payload.dragCard],
                        ]
                    }
                }
            }
        })
    case SET_LOADED_PROJECT_LAYERS:
        return update(state, {
            mapState: {
                layerOptions: { 
                    loadedProjectLayers: { 
                        $set: payload.project_id
                    }
                 },

            }
            
        });
    case SET_CADASTRE_LAYER_STYLE:
        return(update(state, {
            mapState: {
                layerOptions: {
                    loadedCadastreLayers: {
                        $apply: (current) => {
                            if(current.map(x => x.name).includes(payload.cadastre.ref)) {
                                let updated = [];
                                current.forEach(element => {
                                    if(element.name === payload.cadastre.ref) {
                                        element.style = payload.cadastre.style
                                    }
                                    updated.push(element);
                                });
                                return updated;
                            }else {
                                return current
                            }
                        }
                    }
                }
            }
        }));
    
    case SET_OPENED_PROJECT_VIEW:
        return(update(state, {
            projectState: {
                openedProjectViews : {
                    $apply : (current) => {

                        let pinned = current.filter(x => x.pinned === true).length
                        let max_length = 5;
                        if(max_length !== 0) {
                            if(!current.filter(x => x.id === payload.project.id).length) {
                                let temp = current;
                                temp.splice(pinned, 0, payload.project);
                                if(current.length < max_length) {
                                    cookies.set('projectHeader', temp, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });

                                    return temp;
                                }else {
                                    temp.pop();
                                    cookies.set('projectHeader', temp, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });

                                    return temp;
                                }
                            }else {

                                return current
                            }
                        }
                        else {
                            return current;
                        }
                    }
                }
            }
        }));

    case REMOVE_OPENED_PROJECT_VIEW:
        return(update(state, {
            projectState: {
                openedProjectViews: {
                    $apply: (current) => {
                        let temp = current.filter(x => x.id !== payload.projectid)
                        cookies.set('projectHeader', temp, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
                        return temp;
                    }
                }
            }
        }));

    case SET_OPENED_PRODUCT_VIEW_PIN:
        return(update(state, {
            projectState: {
                openedProjectViews: {
                    $apply: (current) => {
                        if(current.filter(x => x.pinned === true).length < 3) {
                            let temp = current.filter(x => x.id !== payload.projectid);
                            let title = current.filter(x => x.id === payload.projectid)[0];
                            let pinned = !title.pinned
                            title.pinned = pinned;
                            if(pinned) {
                                temp.unshift(title);
                            }else {
                                temp.push(title);
                            }
                            cookies.set('projectHeader', temp, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
                            return temp;
                        }
                        else{
                            return current;
                        }
                    }
                }
            }
        }))

    case SET_ACTIVE_CADASTRE:
        return(update(state, {
            navigationState:{
                activeCadastre:{$set: payload.cadastre}
            }
        }))

    case SET_LOADED_TILE_LAYERS:
        return(update(state, {
            mapState: {
                layerOptions: {
                    loadedTiles: {
                        $set: payload.tiles
                    }
                }
            }
        }))
    case SET_LOADED_TILE_ORDER:
            return update(state, {
                mapState: {
                    layerOptions : {
                        loadedTiles: {
                            $splice: [
                                [payload.dragIndex, 1],
                                [payload.hoverIndex, 0, payload.dragCard],
                            ]
                        }
                    }
                }
            })
    default:
        return state;
    }
};
export default uiReducer;

/* state selectors */
export const getNavigationState = (state) => state.ui.navigationState;

export const getIsDataChanged = (state) => state.ui.navigationState.isDataChanged;

export const getMapState = (state) => state.ui.mapState;

export const getProjectState = (state) => state.ui.projectState;

export const getFilterOptions = (state) => state.ui.navigationState.filterOptions;

export const getSmartMode = (state) => state.ui.navigationState.smartMode;

export const getLoadingData = (state) => state.ui.navigationState.loadingData;

export const getLoadingMessage = (state) => state.ui.navigationState.loadingMessage;

export const getDataLoaded = (state) => state.ui.navigationState.dataLoaded;

export const getIsFiltering = (state) => state.ui.navigationState.isFiltering;

export const getView = (state) => state.ui.navigationState.view;

export const getFilters = (state) => state.ui.navigationState.query;

export const getApproved = (state) => state.ui.navigationState.approved;

export const getSidebarType = (state) => state.ui.navigationState.sidebarType;

export const getSelectedMapInteraction = (state) => state.ui.mapState.selectedMapInteraction;

export const getMapLayerOptions = (state) => state.ui.mapState.layerOptions;

export const getNewversion = (state) => state.ui.navigationState.newVersion;

export const getSidebarLayout = (state) => state.ui.navigationState.sidebarLayout;

export const getActiveProject = (state) => state.ui.navigationState.activeProject;

export const getActiveTileLayers = (state) => state.ui.mapState.layerOptions.loadedTiles;

export const RESET = 'RESET';
export const NAVIGATION_ITEM_TOGGLE = 'NAVIGATION_ITEM_TOGGLE';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';
export const PROJECT_SIDEBAR_TOGGLE = 'PROJECT_SIDEBAR_TOGGLE';
export const PROJECT_SIDEBAR_CLOSE = 'PROJECT_SIDEBAR_CLOSE';
export const PROJECT_SIDEBAR_OPEN = 'PROJECT_SIDEBAR_OPEN';
export const VIEW_TOGGLE = 'VIEW_TOGGLE';
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS';
export const SET_MAP_TYPE = 'SET_MAP_TYPE';
export const FILTER_JSON_INFO = 'FILTER_JSON_INFO';
export const SET_LAYER = 'SET_LAYER';
export const SET_PROJECT_TYPE = 'SET_PROJECT_TYPE';
export const SET_MAP_DETAIL_DISPLAY = 'SET_MAP_DETAIL_DISPLAY';
export const SET_MAP_DETAIL_HIDE = 'SET_MAP_DETAIL_HIDE';
export const SET_MAP_DETAIL = 'SET_MAP_DETAIL';
export const SET_LIST_FILE = 'SET_LIST_FILE';
export const SET_TABLE_FILE = 'SET_TABLE_FILE';
export const SET_OPENED_BOX = 'SET_OPENED_BOX';
export const TOGGLE_DRAW = 'TOGGLE_DRAW';
export const SET_CATEGORY = 'SET_CATEGORY';
export const MAP_SEARCH = 'MAP_SEARCH';
export const QUERY_SEPARATOR = '|';
export const MAP_FILTER_ADD = 'MAP_FILTER_ADD';
export const MAP_FILTER_REMOVE = 'MAP_FILTER_REMOVE';
export const MAP_FILTER_RESET = 'MAP_FILTER_RESET';
export const SET_MAP_WIDTH = 'SET_MAP_WIDTH';
export const SET_SMART_MODE = 'SET_SMART_MODE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const SET_DATALOADED = 'SET_DATALOADED';
export const DATA_CHANGE_TOGGLE = 'DATA_CHANGE_TOGGLE';
export const SET_PROJECT_APPROVED = 'SET_PROJECT_APPROVED';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';
export const SET_MAP_INTERACTION = 'SET_MAP_INTERACTION';
export const SET_NEW_VERSION = 'SET_NEW_VERSION';
export const SET_SIDEBAR_LAYOUT = 'SET_SIDEBAR_LAYOUT';

export const SET_MAP_LAYEROPTION_SELECTED = 'SET_MAP_LAYEROPTION_SELECTED';
export const SET_MAP_LAYEROPTION_FILTER = 'SET_MAP_LAYEROPTION_FILTER';
export const SET_MAP_LAYEROPTION_FILTER_CLEAR = 'SET_MAP_LAYEROPTION_FILTER_CLEAR';
export const SET_MAP_LAYEROPTION_FILTER_SEARCHWORD = 'SET_MAP_LAYEROPTION_FILTER_SEARCHWORD';

export const SET_PROJECTEXPORT_TYPE = 'SET_PROJECTEXPORT_TYPE';

export const SET_LOADED_PROJECT_LAYERS = 'SET_LOADED_PROJECT_LAYERS';
export const SET_LOADED_CADASTRE_LAYERS = 'SET_LOADED_CADASTRE_LAYERS';
export const UPDATE_LOADED_DYNAMIC_CADASTRE_LAYERS = 'UPDATE_LOADED_DYNAMIC_CADASTRE_LAYERS';
export const SET_LOADED_DYNAMIC_CADASTRE_LAYERS = 'SET_LOADED_DYNAMIC_CADASTRE_LAYERS';
export const SET_CADASTRE_LAYER_STYLE = 'SET_CADASTRE_LAYER_STYLE';
export const SET_LAYER_LOADING = 'SET_LAYER_LOADING';
export const SET_LOADED_TILE_LAYERS = 'SET_LOADED_TILE_LAYERS';

export const SET_OPENED_PROJECT_VIEW = 'SET_OPENED_PROJECT_VIEW';
export const REMOVE_OPENED_PROJECT_VIEW = 'REMOVE_OPENED_PROJECT_VIEW';
export const SET_OPENED_PRODUCT_VIEW_PIN = 'SET_OPENED_PRODUCT_VIEW_PIN';

export const SET_ACTIVE_CADASTRE = 'SET_ACTIVE_CADASTRE';
export const SET_LOADED_TILE_ORDER = 'SET_LOADED_TILE_ORDER';
//TEMP
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';

export const resetUiState = () => ({
    type: RESET,
    payload: {}
});


export const toggle = (itemId) => ({
    type: NAVIGATION_ITEM_TOGGLE,
    payload: { itemId }
});

export const setSidebarType = (type) => ({
    type: SIDEBAR_TYPE,
    payload: { type }
});

export const toggleDataChanged = () => ({
    type: DATA_CHANGE_TOGGLE,
    payload: {}
});

export const toggleSettings = () => ({
    type: TOGGLE_SETTINGS,
    payload: {}
});

export const projectSidebarToggle = () => ({
    type: PROJECT_SIDEBAR_TOGGLE,
    payload: {}
});

export const projectSidebarClose = () => ({
    type: PROJECT_SIDEBAR_CLOSE,
    payload: {}
});
export const projectSidebarOpen = () => ({
    type: PROJECT_SIDEBAR_OPEN,
    payload: {}
});


export const setLayer = (item,value) => ({
    type: SET_LAYER,
    payload: { item,value }
})
export const toggleView = (itemId) => ({
    type: VIEW_TOGGLE,
    payload: { itemId }
});

export const setSmartMode = (mode) => ({
    type: SET_SMART_MODE,
    payload: { mode }
});

export const setApproved = (approved) => ({
    type: SET_PROJECT_APPROVED,
    payload: { approved }
});

export const setMapWidth = (width) => ({
    type: SET_MAP_WIDTH,
    payload: { width }
})

export const categoryChanged = (category) => ({
    type: SET_CATEGORY,
    payload: { category }
});

export const toggleSidebar = () => ({
    type: SIDEBAR_TOGGLE,
    payload: {}
});

export const closeSidebar = () => ({
    type: SIDEBAR_CLOSE,
    payload: {}
});
export const toggleDraw = () => ({
    type: TOGGLE_DRAW,
    payload: {}
});

export const setMapType = (maptype) => ({
    type: SET_MAP_TYPE,
    payload: { maptype }
});

export const setOpenedBox = (box) => ({
    type: SET_OPENED_BOX,
    payload: { box }
});

export const setMapDetailDisplay = () => ({
    type: SET_MAP_DETAIL_DISPLAY,
    payload: {}
});

export const setMapDetailHide = () => ({
    type: SET_MAP_DETAIL_HIDE,
    payload: {}
});

export const setMapDetail = (markerdata) => ({
    type: SET_MAP_DETAIL,
    payload: {markerdata}
});

export const projectTypeChanged = (projecttype) => ({
    type: SET_PROJECT_TYPE,
    payload: { projecttype }
});

export const setListFile = (file) => ({
    type: SET_LIST_FILE,
    payload: { file }
});

export const setTableFile = (file) => ({
    type: SET_TABLE_FILE,
    payload: { file }
});
//TEMP
export const setTypeFilter = (type) => ({
    type: SET_TYPE_FILTER,
    payload: { type }
});

export const startMapSearch = (query) => ({
    type: MAP_SEARCH,
    payload: { query }
});

export const removeMapFilter = (attribute) => ({
    type: MAP_FILTER_REMOVE,
    payload: { attribute }
});

export const resetMapFilter = () => ({
    type: MAP_FILTER_RESET,
    payload: {  }
});

export const setFilterOptions = (filters) => ({
    type: FILTER_JSON_INFO,
    payload: { filters }
});

export const addMapFilter = (filterObject) => ({
    type: MAP_FILTER_ADD,
    payload: { filterObject }
});

export const setLoadingData = (loadingData) => ({
    type: SET_LOADING,
    payload: { loadingData }
});

export const setLoadingMessage = (message) => ({
    type: SET_LOADING_MESSAGE,
    payload: { message }
});

export const setDataLoaded = (dataLoaded) => ({
    type: SET_DATALOADED,
    payload: { dataLoaded }
});

export const setMapInteraction = (interaction) => ({
    type: SET_MAP_INTERACTION,
    payload: { interaction }
})

export const setAllLoadedLayers = (name) => ({
    type: SET_MAP_LAYEROPTION_SELECTED,
    payload: { name }
});

export const setMapLayerOptionsFilter = (filterObject) => ({
    type: SET_MAP_LAYEROPTION_FILTER,
    payload: { filterObject }
});

export const clearMapLayerOptionsFilter = () => ({
    type: SET_MAP_LAYEROPTION_FILTER_CLEAR,
    payload: {  }
});

export const setMapLayerOptionsFilterSearchword = (query) => ({
    type: SET_MAP_LAYEROPTION_FILTER_SEARCHWORD,
    payload: {query}
});

export const setNewVersion = (version) => ({
    type: SET_NEW_VERSION,
    payload: {version}
});

export const setSidebarLayout = (layout) => ({
    type:SET_SIDEBAR_LAYOUT,
    payload: {layout}
});

export const activeProjectChanged = (activeProject) => ({
    type:SET_PROJECTEXPORT_TYPE,
    payload: {activeProject}
});

export const setLoadedCadastreLayers = (cadastre_ref) => ({
    type:SET_LOADED_CADASTRE_LAYERS,
    payload: {cadastre_ref}
});

export const updateLoadedDynamicCadastreLayers = (cadastre) => ({
    type:UPDATE_LOADED_DYNAMIC_CADASTRE_LAYERS,
    payload: {cadastre}
});

export const setLoadedDynamicCadastreLayers = (dragIndex, dragCard, hoverIndex) => ({
    type:SET_LOADED_DYNAMIC_CADASTRE_LAYERS,
    payload: {dragIndex, dragCard, hoverIndex}
});

export const setLoadedTileOrder = (dragIndex, dragCard, hoverIndex) => ({
    type:SET_LOADED_TILE_ORDER,
    payload: {dragIndex, dragCard, hoverIndex}
});

export const setLoadedProjectLayers = (project_id) => ({
    type:SET_LOADED_PROJECT_LAYERS,
    payload: {project_id}
});

export const setLoadedCadastreLayerStyle = (cadastre) => ({
    type:SET_CADASTRE_LAYER_STYLE,
    payload: {cadastre}
});

export const setOpenedProjectView = (project) => ({
    type:SET_OPENED_PROJECT_VIEW,
    payload:{project}
});

export const removeOpenedProjectView = (projectid) => ({
    type:REMOVE_OPENED_PROJECT_VIEW,
    payload:{projectid}
});

export const setPinndedProjectTitle = (projectid) => ({
    type:SET_OPENED_PRODUCT_VIEW_PIN,
    payload:{projectid}
});

export const setLayerLoading = (loading) => ({
    type:SET_LAYER_LOADING,
    payload:{loading}
});

export const setActiveTileLayers = (tiles) => ({
    type:SET_LOADED_TILE_LAYERS,
    payload:{tiles}
});

export const setActiveCadastre = (cadastre) => ({
    type:SET_ACTIVE_CADASTRE,
    payload:{cadastre}
})