import React, {useState, useEffect} from "react";
import DataTable from "./DataTable"
import AcceptButton from "../AcceptButton";
import AcceptStatuses from "../ui/AcceptStatuses";
import { getStatus, modifySelectedData, getTaskPercentage } from "../_helper/DataTable_helper";
import Progress from "./Progress";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateUpdatePopup from "../_helper/DateUpdatePopup";
import CommentSection from '../CommentSection';
import moment from "moment";
import MqttMultipleActionPopup from "../_helper/_mqtt_helper/MqttMultipleActionPopup";
import { Button, Row, Col } from 'react-bootstrap';

import ActiveFilterContainer from "./ActiveFilterContainer";
import ActiveCadastreFilterContainer from "../../../components/Map/ui/ActiveCadastreFilterContainer";
import ReportProblemPopup from "../_helper/ReportProblemPopup";

export default function DataTableWrapper(props) {

    const [selectedRows, setSelectedRows] = useState([]);
    const [update, setUpdate] = useState(false)
    //popup state input popup
    const [showDatePopup, setShowDatePopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    //Actual task that we working on
    const [currentTask, setCurrentTask] = useState(null);
    const [selectedTasks, setSelectedTasks] = useState([]);

    const [multipleSelectType, setMultipleSelectType] = useState("");

    const [showMultipleUpdatePopup, setShowMultipleUpdatePopup] = useState(false);
    
    const [showReportPopup, setShowReportPopup] = useState(false);

    const [localData, setLocalData] = useState([]);

    useEffect(() => {
        setUpdate(false);
    }, [update])

    useEffect(() => {
        if(props.data.length) {
            setLocalData(props.data.sort((a, b) => {
                let s = false;
                try {
                    s = a.device.location.localeCompare(b.device.location)
                }catch(e) {
                }
                return s
            }));
        }else {
            setLocalData([]);
        }
    }, [props.data])


    function updateMultipleData(row, type) {

        setMultipleSelectType(type)
        setSelectedTasks([])
        
        setSelectedTasks(modifySelectedData(row));
        setShowMultipleUpdatePopup(true)
    }

    

    function getOldStartDate(leafValues) {
        let values = leafValues.filter(x => x !== "N/A")
        if(!values.length) {
            return 'N/A'
        }
        let smallest = new Date(values[0]);


        values.forEach(element => {
            if(smallest > new Date(element)){
                    smallest = new Date(element);
            }
        });

        return smallest !== null ? moment(smallest).format("YYYY-MM-DD") : "N/A";

    }

    function getNewEndDate(leafValues) {
        let values = leafValues.filter(x => x !== "N/A");
        if(!values.length) {
            return 'N/A'
        }
        let largest = new Date(values[0]);

        values.forEach(element => {
            if(largest < new Date(element)){
                largest = new Date(element)
            }
        });

        return largest !== null ? moment(largest).format("YYYY-MM-DD") : "N/A";
    }

    function getLastActionDate(leafValues) {
        let largest = null;
        let values = leafValues.filter(x => x !== "N/A")

        values.forEach(element => {
            if(element !== "N/A") {
                if(largest === null){
                    largest = new Date(element)
                }else if(largest < new Date(element)) {
                    largest = new Date(element)
                }
            }
        });

        return largest !== null ? moment(largest).format("YYYY-MM-DD") : "N/A"
    }

    function reportProblem(row) {
        setCurrentTask(row);
        setShowReportPopup(true);
    }

    //function for update single row date
    function updateRowDate(row) {
        setCurrentTask(row)
        setShowDatePopup(true)
    }

    //function for update single row commnet
    function updateRowComment(row) {
        setCurrentTask(row)
        setShowCommentPopup(true)
    }

    function gotoPage(page) {
        props.setPage(page)
    }

    function previousPage() {
        if(props.page-1 >= 0) {
            props.setPage(props.page - 1)
        }
    }

    function nextPage() {
        if(props.page+1 <= props.maxPage) {
            props.setPage(props.page + 1)
        }
    }

    //Base 2 columns for testing.
    const columns = React.useMemo(
        () => [
            {
                // Make an expander cell
                Header: () => null, // No header
                id: 'expander', // It needs an ID
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                    {!row.isGrouped ? row.isExpanded ? 
                        <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-down']} /></span>
                        : <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-right']} /></span>
                        : null }
                    </span>
            ),
            },
            {
                Header: 'Feladat típusa',
                accessor: row => {
                    switch (parseInt(row.action)) {
                        case 1:
                            return 'Felszerelés';
                        case 2:
                            return 'Leszerelés';
                        case 3:
                            return 'Fejlesztés';
                        default:
                            return '';
                    }
                },
                aggregate: getStatus,
                Aggregated: ({ value }) => value,
                Cell: ({ row, value }) => {
                    let actionClass = "";
                    let actionText = "";
                
                    if (row.original !== undefined && row.original.action !== null) {
                        const action = parseInt(row.original.action);
                        switch (action) {
                            case 1:
                                actionClass = 'planned-circle';
                                actionText = 'Felszerelés';
                                break;
                            case 2:
                                actionClass = 'assessed-circle';
                                actionText = 'Leszerelés';
                                break;
                            case 3:
                                actionClass = 'upgraded-circle';
                                actionText = 'Fejlesztés';
                                break;
                            default:
                                actionText = '';
                        }
                    } else {
                        actionText = row.groupByVal;
                    }
                
                    return (
                        <>
                            <span className={actionClass}>
                                {actionText}
                            </span>
                        </>
                    );
                }
                // Cell: ({ row, value }) => (
                //     <>
                //     <span className={row.original !== undefined ? parseInt(row.original.action) === 1 ? 'planned-circle' : parseInt(row.original.action) === 2 ? 'assessed-circle' : 'upgraded-circle' : ""}>
                //         {row.original !== undefined ? row.original.action !== null ? parseInt(row.original.action) === 1 ? 'Felszerelés' : parseInt(row.original.action) === 2 ? 'Leszerelés' : 'Fejlesztés' : "N/A" : row.groupByVal}
                //     </span>
                //     </>
                // ),
            },
            {
                Header: 'Előrehaladás',
                id: "donestatus",
                accessor: row => {
                    if(parseInt(row.status) === 0) {
                        return 'Új';
                    } else if(parseInt(row.status) === 1) {
                        return 'Kivitelezve';
                    } else if(parseInt(row.status) === 2) {
                        return 'Jóváhagyva';
                    } else if(parseInt(row.status) === 3) {
                        return 'Elfogadva';
                    } else {
                        return ''
                    }
                },
                aggregate: getTaskPercentage,
                Aggregated: ({ value }) => `${value} % kész`,
                // Cell: ({ row }) => (
                //     row.original === undefined ? parseInt(row.groupByVal) ? "Befejezett" : "Befejezetlen" : row.original.approved ? "Befejezett" : "Befejezetlen"
                // ),
                Cell: ({ row }) => {
                    const status = row.original !== undefined ? row.original.status : row.groupByVal;
                
                    if (!parseInt(status)) {
                        return 'Új';
                    } else if (parseInt(status) === 1) {
                        return 'Kivitelezve';
                    } else if (parseInt(status) === 2) {
                        return 'Jóváhagyva';
                    } else if (parseInt(status) === 3) {
                        return 'Elfogadva';
                    } else {
                        return '';
                    }
                }
                // Cell: ({ row }) => (
                //     row.original === undefined 
                //         ? parseInt(row.groupByVal) 
                //             ? "Befejezett" 
                //             : "Befejezetlen" 
                //         : row.original.status === 2 
                //             ? "Befejezett" 
                //             : "Befejezetlen"
                // )
            },
            {
                Header: 'Helyszín',
                accessor: 'device.location',
                sortType: (a, b, columnId, desc) => {
                    let result = false;
                    if(a.original === undefined) {
                        result = a.groupByVal.localeCompare(b.groupByVal)
                    }else{
                        result = a.original.device.location.localeCompare(b.original.device.location)
                    }
                    return desc ? result : !result;
                }
              
            },
            {
                Header: <FontAwesomeIcon title="Elvégezve" alt="Applikációból feltöltve" size="1x" icon={['fas', 'upload'] }/>,
                accessor: row => row.approved_by,
                id: 'approved_by',
                canGroupBy:false,
                aggregate: getTaskPercentage,
                Aggregated: ({ value }) => (<Progress value={value}/>),
                Cell: ({ row }) => (
                    <AcceptStatuses row={row.original} checked={row.original.approved_by} type={'approved'} />
                ),
              
            },
            {
                Header:<FontAwesomeIcon title="Jóváhagyva" alt="Műszaki ellenőr" size="1x" icon={['fas', 'check-square'] }/>,
                accessor: row => row.done_by,
                id: 'done_by',
                canGroupBy: false,
                aggregate: getTaskPercentage,
                Aggregated: ({ value }) => (<Progress value={value}/>),
                Cell: ({ row }) => (
                    <AcceptStatuses row={row.original} checked={row.original.done_by} type={'done'} />

                )
            },
            {
                Header: <FontAwesomeIcon title="Elfogadva" alt="Önkormányzat" size="1x" icon={['fas', 'building'] }/>,
                accessor: row => row.accepted_by,
                id: 'accepted_by',
                canGroupBy:false,
                aggregate: getTaskPercentage,
                Aggregated: ({ value }) => (<Progress value={value}/>),
                Cell: ({ row }) => (
                    <AcceptStatuses row={row.original} checked={row.original.accepted_by} type={'accepted'} />

                )

            },
            {
                Header: 'Azonosító',
                accessor: 'uuid',
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} elem`,
            },
            {
                Header: 'Privát jegyzet',
                accessor: 'note_private',
                Aggregated: ({ value }) =>  "",
                Cell: ({ row }) => (
                    <p>{row.original !== undefined ? row.original.note_private : "H/A" }</p>
                )
            },
            {
                Header: 'Publikus jegyzet',
                accessor: 'note_public',
                Aggregated: ({ value }) =>  "",
                Cell: ({ row }) => (
                    <p>{row.original !== undefined ? row.original.note_public : "N/A" }</p>
                )
            },
            {
                Header: 'Kezdete',
                accessor: row => {
                    return row.dateo !== null ? moment(row.dateo).format("YYYY-MM-DD"): "N/A"
                },
                aggregate: getOldStartDate,
                Aggregated: ({ value }) => `${value}`,
                Cell: ({ row }) => (
                    <span>{row.original !== undefined ? row.original.dateo !== null ? moment(row.original.dateo).format("YYYY-MM-DD") : "N/A" : row.groupByVal} </span>
                )
            },
            {
                Header: 'Vége',
                accessor: row => {
                    return row.datee !== null ? moment(row.datee).format("YYYY-MM-DD") : "N/A"
                },
                aggregate: getNewEndDate,
                Aggregated: ({ value }) => `${value}`,
                Cell: ({ row }) => (
                    <span>{row.original !== undefined ? row.original.datee !== null ? moment(row.original.datee).format("YYYY-MM-DD") : "N/A" : row.groupByVal}</span>
                )
            },
            {
                Header: 'Szerelve',
                accessor: row => {
                    return row.updated !== null ? moment(row.updated).format("YYYY-MM-DD") : "N/A"
                },
                aggregate: getLastActionDate,
                Aggregated: ({ value }) => `${value}`,
                Cell: ({ row }) => (
                    <span>{row.original !== undefined ? row.original.updated !== null ? moment(row.original.updated).format("YYYY-MM-DD") : "N/A" : row.groupByVal}</span>
                )
            },
            // {
            //     Header: () => null, 
            //     id: 'accept',
            //     Aggregated: ({ value }) =>  "",
            //     Cell: ({ row, value }) => (
            //             <div title="Elfogadás">
            //                 {(props.userRole === "PROJECTADMIN" || props.userRole === 'PROJECTAPPROVAL') ? <AcceptButton row={row.original} role={props.userRole} update={setUpdate} /> : null}

            //             </div>

            //     ),
            // },
            // {
            //     Header: () => null, 
            //     id: 'problem',
            //     Aggregated: ({ value }) =>  "",
            //     Cell: ({ row, value }) => (
            //             <div title="Probléma bejelentése">
            //                 <i role="button" className={row.original.app === 2 || row.original.done === 2 || row.original.approved === 2 ? "has-warning" : ""} >
            //                     <FontAwesomeIcon icon={['fas', 'exclamation-circle']} onClick={() => reportProblem(row.original)}/>
            //                 </i>
            //             </div>

            //     ),
            // },
            // {
            //     Header: () => null, 
            //     id: 'date',
            //     Aggregated: ({ value }) =>  "",
            //     Cell: ({ row, value }) => (

            //             <div title="">
            //                 <i role="button">
            //                     {props.userRole === "SUPERADMIN" ? <FontAwesomeIcon icon={['fas', 'calendar-alt']} onClick={() => updateRowDate(row.original)}/> : null}
            //                 </i>
            //             </div>
            //     ),
                
            // },
            // {
            //     Header: () => null, 
            //     id: 'comment',
            //     Aggregated: ({ value }) =>  "",
            //     Cell: ({ row, value }) => (
            //             <div title="Üzenetek">
            //                 <i role="button" className={row.original.has_message ? "has-message" : ""}>
            //                     <FontAwesomeIcon icon={['fas', 'comment']} onClick={() => updateRowComment(row.original)}/>
            //                 </i>
            //             </div>
            //     ),
                
            // },
            // {
            //     Header: () => null, 
            //     id: 'map',
            //     Aggregated: ({ value }) =>  "",
            //     Cell: ({ row, value }) => (
            //             <div title="Mutasd tréképen">
            //                 <i role="button" ><FontAwesomeIcon icon={['fas', 'map']} onClick={() => props.navigateToMap(row.original)}/> </i>
            //             </div>
            //     ),
                
            // },
        ], [])  // eslint-disable-line

    return(
        <React.Fragment>
            <div style={{marginTop:'10px', backgroundColor:'none'}}>
                <ActiveFilterContainer />
            </div>
            <div style={{marginTop:'10px', backgroundColor:'none'}}>
                <ActiveCadastreFilterContainer />
            </div>
            <DataTable
                columns={columns}
                data={localData}
                userRole={props.userRole} 
                navigateToMap={props.handleAction}         
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                showDatePopup={showDatePopup}
                setShowDatePopup={setShowDatePopup}
                showCommentPopup={showCommentPopup}
                setShowCommentPopup={setShowCommentPopup}
                setMultipleSelectType={setMultipleSelectType}
                updateMultipleData={updateMultipleData}
                showMultipleUpdatePopup={showMultipleUpdatePopup}
                setShowMultipleUpdatePopup={setShowMultipleUpdatePopup}
                selectedTasks={selectedTasks}
                multipleSelectType={multipleSelectType}
                total={props.total}
                fetchingData={props.fetchingData}
                totalTasks={props.totalTasks}
            />
            <DateUpdatePopup showDatePopup={showDatePopup} setShowDatePopup={setShowDatePopup} currentTask={currentTask} update={setUpdate}/>
            <CommentSection showCommentPopup={showCommentPopup} setShowCommentPopup={setShowCommentPopup} currentTask={currentTask} userRole={props.userRole} update={setUpdate}/>
            <ReportProblemPopup show={showReportPopup} setShow={setShowReportPopup} currentTask={currentTask} update={setUpdate} />
            <Row>
                <Col>
                    <div className="pagination">
                        <div className="items-per-page">
                            <span>Csoportok oldalanként</span>
                            <select id="pagenumber" defaultValue={10} name="pagenumber" onChange={(e) => {props.setLimit(Number(e.target.value))}}>
                                <option value="10">10</option>
                                <option value="25" >25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className="pages">
                            <Button variant="light" id="grantButton" onClick={() => gotoPage(1)} disabled={props.page === 1}><FontAwesomeIcon icon={['fas', 'step-backward']} /></Button>
                            <Button variant="light" id="grantButton" onClick={() => previousPage()} disabled={props.page <= 1}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></Button>
                            <span>{props.page} of {props.maxPage}</span>
                            <Button variant="light" id="grantButton" onClick={() => nextPage()} disabled={props.page >= props.maxPage}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></Button>
                            <Button variant="light" id="grantButton" onClick={() => gotoPage(props.maxPage)} disabled={props.page === props.maxPage}><FontAwesomeIcon icon={['fas', 'step-forward']} /></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <MqttMultipleActionPopup
                showUpdatePopup={showMultipleUpdatePopup}
                setShowUpdatePopup={setShowMultipleUpdatePopup}
                data={selectedTasks}
                selectedTasks={selectedTasks}
                type={multipleSelectType}
                useRole={props.userRole}
                update={setUpdate}
            />
        </React.Fragment>

    );
}