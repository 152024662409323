import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNavigationState } from '../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { history } from '../../state/store';

/**
 * SideBar Helper Component
 * 
 * Basic Functionality :
 *  - Create a modifiable list element with :
 *      - Name
 *      - icon
 *      - toggled
 *      - backgroundcolor
 *  - Possible submenu elements :
 *      - Icon
 *      - Name
 * **/
function SidebarButton(props) {

    function navigateToLink() {
        if(props.route) {
            if(props.route.includes('http')) {
                window.open(props.route, '_blank');
            }else {
                history.push(props.route)
            }
        }
    }

    const [onMouseOver, setOnMouseOver] = useState(false);
    const isActive = props.parent.navigationState.view === props.toggled;

    const activeStyle = {
        backgroundColor: isActive ? (process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT) : '',
        borderRadius: isActive ? '30px 0 0 30px' : '',
        marginLeft: isActive ? '0px' : '',
        marginRight: isActive ? '-4px' : ''
    };

    const combinedStyle = {
        ...activeStyle,
        backgroundColor: onMouseOver ? (process.env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR_DEFAULT) : activeStyle.backgroundColor,
        borderRadius: onMouseOver ? '0px 0 0 0px' : activeStyle.borderRadius
    };

    return(
        <li
            className={isActive ? "active" : ""}
            style={combinedStyle}
            onMouseOver={() => setOnMouseOver(true)}
            onMouseOut={() => setOnMouseOver(false)}
        >
            <a style={{cursor: 'pointer'}} onClick={navigateToLink} id={props.toggled} target={props.target}>
                <div><span><i><FontAwesomeIcon icon={props.icon} /></i></span> <span>{props.name}</span></div>    
            </a>
        </li>
    );

}

const mapStateToProps = state => ({
    navigationState: getNavigationState(state),
});

export default connect(
    mapStateToProps,
)(SidebarButton);