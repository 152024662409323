import React,{useEffect} from 'react';
import BrowseLayout from '../layouts/BrowseLayout';
import { Container } from "react-bootstrap";
import Box from '../../components/dashboard/Box';
import connect from 'react-redux/es/connect/connect';
import Config from "../../config/config";
import { getUserAccess } from "../../state/reducers/user";

import { useTranslation } from 'react-i18next';
import { project } from "../_helper/_styles/styles_project";
import {toggleView,getApproved} from '../../state/reducers/ui';
import { history } from '../../state/store';

function DashboardCityMarketing (props) {
    const { t } = useTranslation();

    useEffect(() => {
        if(props?.userAccess?.dashboard?.citymarketing !== "active") {
            history.push("/dashboard");
        }
        props.toggleViewState("citymarketing")
    })


    let imageId = null;
    if(props.lastImage && props.lastImage.images){
        props.lastImage.images.slice().reverse().forEach(function(item) {
        if(item.file !== "" && imageId === null) {
            imageId = item.file;
        }
        });
    }

    const lastImageUrl = imageId ? Config.static.img + imageId : false;
    const lastImageDate = props.lastImage ? props.lastImage.ts : null;
    const lightProgress = props.lightsProgress;

    return(
        <BrowseLayout>
            <Container className="dashboard-smart-container">
                <div className="row">
                    <Box status="active" boxClass="box-project" title={t('Projekt')} bgColor="#f3f0e7" color="#c3b388" iconType="fas" icon="list" widgets={project({lightProgress, lastImage: {ts:lastImageDate, url: lastImageUrl}})} logo="utu_dark.svg" logo_h="30" top="8" redirect={"/project"}/>
                    <Box status="active" boxClass="box-last-image" iconType="fas" icon="list" widgets={project({lightProgress, lastImage: {ts:lastImageDate, url: lastImageUrl}})} redirect={"/project"}/>
                </div>
            </Container>
        </BrowseLayout>
    )
};

const mapStateToProps = state => ({
    lightsProgress: getApproved(state),
    userAccess: getUserAccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	}
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardCityMarketing);

