import React, { useState, useEffect } from 'react';
import Box from "../../components/dashboard/Box";
import Config from '../../config/config';
import mqtt from 'mqtt';
import { DashboardController } from '../../controllers/DashboardController'

export default function TotemBox(props) {
    
    const [alerts, _setAlerts] = useState({});
	const alertsRef = React.useRef(alerts);
	const setAlerts = data => {
		alertsRef.current = data;
		_setAlerts(data);
	};

	const [client, setClient] = useState(null);
	const [subbed, setSubbed] = useState(false);
	const [status, setConnectStatus] = useState(''); // eslint-disable-line
	const [data, setData] = useState(false);
	const [widgets, setWidgets] = useState(false);

    const DashboardControllerInst = DashboardController.getInstance();
    const options = {
		"host": process.env.REACT_APP_MQTT_SERVER_HOST,
		"protocol": process.env.REACT_APP_MQTT_SERVER_PROTOCOL,
		"port": process.env.REACT_APP_MQTT_SERVER_PORT,
		"path": process.env.REACT_APP_MQTT_SERVER_PATH,
		"username":process.env.REACT_APP_MQTT_SERVER_USERNAME,
		"password":process.env.REACT_APP_MQTT_SERVER_PASSWORD,
	};

    const mqttConnect = (mqttOption) => {
		let client = mqtt.connect(`${options.protocol}://${options.host}:${options.port}`, mqttOption);
		setClient(client);
		if(!subbed) {
			client.subscribe('app/erp/door/open', function(err) {
				setSubbed(true);
			});
			client.subscribe('app/erp/door/close', function(err) {
				setSubbed(true);
			});
		}
	};

    useEffect(() => {
		if (client) {
			client.on('connect', () => {
				setConnectStatus('Csatlakozva');
			});

			client.on('error', (err) => {
				console.error('Connection error: ', err);
				setConnectStatus('Nincs kapcsolat');
				client.end();
			});

			client.on('reconnect', () => {
				setConnectStatus('Újracsatlakozás...');
			});

			client.on('message', (topic, message) => {
				const payload = { 
					topic, 
					message: JSON.parse(message)
				};

				if(props.userRights.door_alert) {
					if(payload.topic === 'app/erp/door/open') {
						handleOpen(payload.message);
					}
	
					if(payload.topic === 'app/erp/door/close') {
						handleClose(payload.message);
					}
				}
			});
		}
	}, [client]); // eslint-disable-line

	useEffect(() => {
		if(!client) {
			mqttConnect(options);
		}
		if(!data) {
			DashboardControllerInst.getWidgetData('totem').then(response => {
				if(response.statusCode === 200) {
					setData(response.data)
				}
			});
		}
	}, []); // eslint-disable-line

	useEffect(() => {
		if(data) {
			let temp = [...props.widgets]
			temp[0].value = data.total + " / " + data.active
			temp[1].value = data.under_voltage
			temp[2].value = data.active_connection
			temp[3].value = data.closed_doors

			//temp inside
			temp[5].value = data.inside_temperature.value !== null ? data.inside_temperature.value : '-'
			temp[5].unit = data.inside_temperature.unit

			//pressure inside
			temp[6].value = data.inside_pressure.value !== null ? data.inside_pressure.value : '-'
			temp[6].unit = data.inside_pressure.unit

			//humidity inside
			temp[7].value = data.inside_humidity.value !== null ? data.inside_humidity.value : '-'
			temp[7].unit = data.inside_humidity.unit

			setWidgets(temp);
		}
	}, [data]) // eslint-disable-line

    function handleOpen(data) {
        if(!Object.keys(alertsRef.current).includes(data.device_id)) {
            let temp = {...alertsRef.current};
            temp[data.device_id] = data;
            setAlerts(temp);
        }
    }

    function handleClose(data) {
        if(Object.keys(alertsRef.current).includes(data.device_id)) {

			let res = {};
			Object.keys(alertsRef.current).forEach(x => {
				if(x !== data.device_id) {
					res[x] = alertsRef.current[x]
				}
			})

			setAlerts(res);
        }
    }

    return (
        <Box key={0} {...props} alerts={alerts} widgets={widgets ? widgets : props.widgets}/>
    );
};
