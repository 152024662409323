import React, {useState, useEffect} from 'react';
import { Row, Col } from "react-bootstrap";
import {Tabs, Tab} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DoughnutChart from '../charts/DoughnutChart';
import LineChart from '../charts/LineChart';
import {getLightTimes} from "../../../helpers/lighttimes";
import { getUserName } from '../../../state/reducers/user';
import OwletDetails from '../owlet/owlet_details';
import connect from 'react-redux/es/connect/connect';
import { DashboardController } from '../../../controllers/DashboardController';
//import { getOwletData } from '../../Map/_helpers/owletData';
import moment from 'moment';
import { ExedraAPIController } from '../../../controllers/ExedraAPIController';

const dateTypes = [
    { title: "Napi", value: "Day" },
    { title: "Heti", value: "Week" },
    { title: "Havi", value: "Month" },
]

function LightsDetails(props) {

    const [consumptionData, setConsumptionData] = useState([])
    const [threeDaysConsumption, setThreeDaysConsumption] = useState(0)
    const [fullConsumption, setFullConsumption] = useState(0)
    const [yesterdayConsumption, setYesterdayConsumption] = useState(0)
    const [dateType, setDateType] = useState(dateTypes[0].value)

    const [lights, setLights] = useState({smart: "0", smart_ready: "0", non_smart: "0"});
    const [key, setKey] = useState('közvilágítás');
	const [lightProblem, setLightProblem] = useState(0);
    const [loading, setLoading] = useState(false);
    const [consumptionLoading, setConsumptionLoading] = useState(false);
    const [doughnutLoading, setDoughnutLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);
    
    const [failures, setFailures] = useState([])

    const DashboardControllerInst = DashboardController.getInstance();
    const ExedraAPIControllerInst = ExedraAPIController.getInstance();

    // let from = moment().subtract(2,'days').format("YYYY-MM-DD");
    // let to = moment().format("YYYY-MM-DD");

    useEffect(() => {
        setDoughnutLoading(true);

        ExedraAPIControllerInst.getFailures().then(response => {
            if(response?.data?.entries?.length) {
                setFailures(response.data.entries);

                const uniqueIds = new Set();

                response.data.entries.forEach(failure => {
                    uniqueIds.add(failure.id);
                });
                const uniqueCount = uniqueIds.size;

                setLightProblem(uniqueCount);
            }
        })

        DashboardControllerInst.getLampData(props?.username).then(response => {
            if(response.statusCode === 200) {
                setLights(response.data[0]);
                setDoughnutLoading(false);
            }
        }).catch(err => {
            console.log("err", err);
        });
        
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setConsumptionLoading(true);
            try {
                await getThreeDaysConsumption();
                await getYesterdayConsumption();
                await getFullConsumption();
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
            setConsumptionLoading(false);
            }
        };

        fetchData();
    }, []);
    

    useEffect(() => {
        setLoading(true);
        setChartLoading(true);

        // async function getOwlet() {
           // let lampsWithProblem = await getOwletData();
           // setLightProblem(lampsWithProblem ? lampsWithProblem.length : 0);
           // setLoading(false);
        // }

        // getOwlet();

        // get consumption data
        ExedraAPIControllerInst.getConsumption(100, dateType).then((response) => {
            if (response.statusCode === 200) {
                setConsumptionData(response.data)
            }
            setChartLoading(false);
            setLoading(false);
        })
  
    }, [dateType]) // eslint-disable-line

    async function getFullConsumption() {
        await ExedraAPIControllerInst.getFullConsumption().then((response) => {
            if (response.statusCode === 200) {
                const sum = response.data.reduce((total, item) => total + item.activeEnergy, 0);
                setFullConsumption(sum)
                setConsumptionLoading(false);
            }
        })
    }

    async function getYesterdayConsumption() {
        await ExedraAPIControllerInst.getYesterdayConsumption().then((response) => {
            if (response.statusCode === 200) {
                const sum = response.data.reduce((total, item) => total + item.activeEnergy, 0);
                setYesterdayConsumption(sum)
            }
        })
    }

    async function getThreeDaysConsumption() {
        await ExedraAPIControllerInst.getConsumption(3, "Day").then((response) => {
            if (response.statusCode === 200) {
                const sum = response.data.reduce((accumulator, data) => {
                    return accumulator + data.activeEnergy;
                  }, 0)
                setThreeDaysConsumption(sum.toFixed(2))
            }
        })
    }

    // async function getLampData() {
    //     await ExedraAPIControllerInst.getConsumption(3, "Day").then((response) => {
    //         if (response.statusCode === 200) {
    //             const sum = response.data.reduce((accumulator, data) => {
    //                 return accumulator + data.activeEnergy;
    //               }, 0)
    //             setThreeDaysConsumption(sum.toFixed(2))
    //         }
    //     })
    // }

    function getConsumptionData() {
        const sortedData = consumptionData.sort((a, b) => new Date(a.lastDate) - new Date(b.lastDate));

        return {
            labels: sortedData.map((data) => moment(data.lastDate).format("YYYY.MM.DD.")),
            data: sortedData.map((data) => data.activeEnergy),
        }
    }

    const wifiColor = ["#ffd573", "#e9e9e9"]
    const lampDetailsColor = ["#ffd573", "#e9e9e9", "#5c9aff"]

    return(
        <Tabs defaultActiveKey="közvilágítás"       
        activeKey={key}
        onSelect={(k) => setKey(k)}
        >
            <Tab eventKey="közvilágítás" title="Közvilágítás">
                <Tab.Content className="detailed-lights-body">
                    <Row>
                        <Col lg={3} className="detailed-lights-data">
                            <Row>
                                <Col lg={12} className="detailed-lights-body-lightswitch">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'lightbulb']} /></i>
                                            <p>{getLightTimes().down}</p>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#ffd573" icon={['far', 'lightbulb-on']} /></i>
                                            <p>{getLightTimes().up}</p>
                                        </Col>
                                    </Row>
                                    <h4>Kapcsolási idők</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="detailed-lights-data-cols">
                                    <Row >
                                        <Col lg={12}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'plug']} /></i>
                                            <div className="consumption-value">
                                                {consumptionLoading ? (
                                                    <div className="consumption-loader"></div>
                                                ) : (
                                                    `${Math.round(threeDaysConsumption).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} `
                                                )}
                                                
                                             kWh</div>
                                        </Col>
                                    </Row>
                                    <h4>SMART fogyasztás (3 nap)</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="detailed-lights-data-cols">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'plug']} /></i>
                                            <div className="consumption-value">
                                                {consumptionLoading ? (
                                                        <div className="consumption-loader"></div>
                                                ) : (
                                                    `${Math.round(yesterdayConsumption).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} `
                                                )}
                                            kWh</div>
                                            <h4>Fogyasztás<br />tegnap</h4>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#ffd573" icon={['far', 'bolt']} /></i>
                                            <div className="consumption-value">
                                                {consumptionLoading ? (
                                                    <div className="consumption-loader"></div>
                                                ) : (
                                                    `${Math.round(fullConsumption).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} `
                                                )}
                                            kWh</div>
                                                <h4>Fogyasztás<br />összesen</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="detailed-lights-data-cols">
                                    <Row>
                                        <Col lg={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#FFE6A9" icon={['far', 'piggy-bank']} /></i>
                                            <p>N/A</p>
                                            <h4>Tervezett<br />megtakaritás</h4>
                                        </Col>
                                        <Col lg ={6}>
                                            <i className="icon"><FontAwesomeIcon size="3x" color="#ffd573" icon={['far', 'coins']} /></i>
                                            <p>N/A</p>
                                            <h4>Megtakaritás<br />összesen</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={9} className="detailed-lights-chart">
                            <Row>
                                <Col lg={6}>
                                    <DoughnutChart loading={doughnutLoading} data={[lights.smart, lights.non_smart, lights.smart_ready]} color={lampDetailsColor} title={"Világitási ezközök"} value={lights.non_smart} types={['Smart', 'Hagyományos', 'Smart Ready']}/>
                                </Col>
                                <Col lg={6}>
                                    <DoughnutChart loading={doughnutLoading} data={[lights.smart-lightProblem, lightProblem]} color={wifiColor} title={"Smart"} value={lights.smart} types={['Aktiv', 'Inaktiv']}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} >
                                    <LineChart step={10}
                                        labels={getConsumptionData().labels}
                                        data={getConsumptionData().data}
                                        types={dateTypes} title={'Átlagos fogyasztás'}
                                        setDateType={setDateType}
                                        chartLoading={chartLoading}
                                        setChartLoading={setChartLoading}
                                        />
                                </Col>
                            </Row>
                        </Col>
                    </Row>                    
                </Tab.Content>
            </Tab>
            <Tab eventKey="owlet" title="Owlet">
                <Tab.Content className="detailed-owlet-body">
                    <OwletDetails
                        failures={failures}
                        active={key === "owlet" ? true : false} />
                </Tab.Content>
            </Tab>
        </Tabs>
      );
}
const mapStateToProps = state => ({
    username: getUserName(state),
});

export default connect(
	mapStateToProps,
	null,
)(LightsDetails);
