import React, {Component} from 'react';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';

import connect from 'react-redux/es/connect/connect';
import { getNavigationState, toggleSidebar } from '../../state/reducers/ui';
import { history } from '../../state/store';
import Cookies from 'universal-cookie';


const classes = (navigationState,path) => {
    let classes = '';
    if(navigationState.sidebar) {
        classes = 'page-content';
    }
    else {
        classes = 'page-content closed';
    }
    if(path === '/map' || path=== '/project' || path==='/dashboard' || path==='/kataszter' || path === '/thirdparty' || path.includes('smart') || path.includes('citymarketing') || path === '/interface' || path === '/pharos') {
        classes += ' full';
    }

    if(path === '/dashboard' || path === '/thirdparty' || path === '/interface' || path === '/pharos') {
        classes += ' dashboard';
    }

    if(path === '/map') {
        classes += ' no-padding map';
    }


    return classes;

};

class BrowseLayout extends Component {

    constructor(props, context) {
        super(props, context);
        this.handleLogout = this.handleLogout.bind(this);
        this.cookies = new Cookies();
    }

    handleLogout(msg) {
        const { addUserAuthWarning } = this.props;
        addUserAuthWarning(msg ? msg : "Munkamenet lejárt. Kérem jelentkezzen be újra.")
        sessionStorage.clear();
        this.cookies.remove("username")
        this.cookies.remove("password")

		history.push("/login");
    }

    componentDidMount() {
        const { toggleSidebar, navigationState } = this.props;

        if(window.innerWidth < 768) {
            if(navigationState.sidebar !== false){
                toggleSidebar();
            }
        }
    }

    render() {
        const { children, navigationState, path } = this.props;
        return (
            <React.Fragment>
                <Header/>
                <Sidebar/>
                <div className={classes(navigationState, path)}>
                    <div className="container-fluid browse-layout">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 pr-0">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    toggleSidebar: () => {
      dispatch(toggleSidebar());
    },
});

const mapStateToProps = state => ({
    navigationState: getNavigationState(state),
    path: state.router.location.pathname,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrowseLayout);
