import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Polygon from 'ol/geom/Polygon';
import Draw, { createBox, createRegularPolygon } from 'ol/interaction/Draw';
import Select from 'ol/interaction/Select';
import { CirclePicker, AlphaPicker } from 'react-color';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import RangeSlider from 'react-bootstrap-range-slider';
import FontPicker from "font-picker-react";
import { Text } from "ol/style";
import Config from "../../../config/config";

export default function MapInteractions(props) {

    const [active, setActive] = useState(false);
    const [addedTypes, setAddedTypes] = useState([]);
    const [infoData, setInfoData] = useState(<p></p>);
    const [selectSetupDone, setSelectSetupDone] = useState(false);

    //edit feature
    const [editFeatureType, setEditFeatureType] = useState(false);

    //colors and shapes
    const [interactionColor, setInteractionColor] = useState(false);
    const [interactionWidth, setInteractionWidth] = useState(3);
    const [interactionFillColor, setInteractionFillColor] = useState(false);

    const [textStyle, setTextStyle] = useState({font:'Open Sans', fontSize:'18px', fill:'#444444' });


    const [freehandSelect, _setFreehandSelect] = useState({});

    const freehandSelectRef = React.useRef(freehandSelect);
    const setFreehandSelect = data => {
		freehandSelectRef.current = data;
		_setFreehandSelect(data);
	};

    useEffect(() => {
        if(props.layers.draw !== undefined) {
            setFreehandSelect(
                new Select({
                    layers:[props.layers.draw.freehand, props.layers.draw.arrow, props.layers.draw.freehandDrawing, props.layers.text]
                })
            );
        }
    }, [props.layers.draw]); // eslint-disable-line

    useEffect(() => {
        if(props.removeInteraction === true) {
            clearAllInteractions();
            props.setRemoveInteractions(false);
        }
    }, [props.removeInteractions]) // eslint-disable-line

    //style effects
    useEffect(() => {
        if(props.editInteractionFeature) {
            props.setEditing(true);
            if(props.editInteractionFeature !== editFeatureType) {
                setInteractionColor(false);
                setInteractionFillColor(false);
                setInteractionWidth(2);
            }
            setEditFeatureType(props.editInteractionFeature.geometryName_);

            if(props.editInteractionFeature.getStyle() === null) {
                let selectedStyle = new Style({
                    stroke: new Stroke({
                        color: 'black',
                        width: '4'
                    }),
                    fill: new Fill({
                        color: 'rgb(245, 245, 245, 0.7)'
                    }),
                });

                props.editInteractionFeature.setStyle(selectedStyle);
            }
        }
    }, [props.editInteractionFeature]); // eslint-disable-line

    useEffect(() => {
        if(props.editInteractionFeature) {
            if(props.editInteractionFeature.values_.name === 'text') {

                const text = props.editInteractionFeature.getStyle().getText().getText();
                let style = new Style({
                    text: new Text({
                        text: text,
                        placement: 'point',
                        textAlign: 'center',
                        offsetY: -25,
                        font: textStyle.fontSize + " " + textStyle.font,
                        fill: new Fill({
                            color: textStyle.fill
                        }),
                        backgroundFill : new Fill({
                            color: 'rgb(245, 245, 245, 0.7)'
                        })
                    })
                });
                props.editInteractionFeature.setStyle(style);

            }else {
                let refactStyle = new Style({
                    stroke: new Stroke({
                        color: interactionColor ? interactionColor : 'blue',
                        width: interactionWidth ? interactionWidth : '2'
                    }),
                    fill: new Fill({
                        color: interactionFillColor ? 'rgb(' + interactionFillColor.r + ',' + interactionFillColor.g + ',' + interactionFillColor.b + ',' + interactionFillColor.a +')' : 'rgb(245, 245, 245, 0.7)',
                    }),
                });
                props.editInteractionFeature.setStyle(refactStyle);
            }
        }
    }, [interactionColor, interactionFillColor, interactionWidth, textStyle]); // eslint-disable-line
    
    function addInteraction(value) {
        freehandSelect.setActive(false);
        props.removeMeasureInteractions();
        props.setReduxInteraction('');
        props.setType(value);
        props.setWriteText(false);
        props.setCanDraw(true);
        removeInteractions();

        if (value !== 'None') {
            let geometryFunction;
            if (value === 'Square') {
                value = 'Circle';
                geometryFunction = createRegularPolygon(4);
                setInfoData(<p>Kattintson a rajzolás megkezdéséhez, majd kattintson újra a befejezéshez.</p>);
            } else if (value === 'Box') {
                value = 'Circle';
                geometryFunction = createBox();
                setInfoData(<p>Kattintson a rajzolás megkezdéséhez, majd kattintson újra a befejezéshez.</p>);
            } else if (value === 'Star') {
                value = 'Circle';
                geometryFunction = function (coordinates, geometry) {
                let center = coordinates[0];
                let last = coordinates[coordinates.length - 1];
                let dx = center[0] - last[0];
                let dy = center[1] - last[1];
                let radius = Math.sqrt(dx * dx + dy * dy);
                let rotation = Math.atan2(dy, dx);
                let newCoordinates = [];
                let numPoints = 12;
                for (let i = 0; i < numPoints; ++i) {
                    let angle = rotation + (i * 2 * Math.PI) / numPoints;
                    let fraction = i % 2 === 0 ? 1 : 0.5;
                    let offsetX = radius * fraction * Math.cos(angle);
                    let offsetY = radius * fraction * Math.sin(angle);
                    newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
                };
                newCoordinates.push(newCoordinates[0].slice());
                if (!geometry) {
                    geometry = new Polygon([newCoordinates]);
                } 
                else {
                    geometry.setCoordinates([newCoordinates]);
                };
                return geometry;
                };
                setInfoData(<p>Kattintson a rajzolás megkezdéséhez, majd kattintson újra a befejezéshez.</p>);
            }else if(value === 'text') {
                //create a feature but not to draw just add the feature to the map draw layer, and prompt some text input field to write
                props.setWriteText(true);
                setInfoData(<p>Kattintson a kezdéshez, töltse ki a mezőt, majd a pipára kattintva helyezze a térképre a szöveget. Lehelyezés után a szövegre kattintva mozgathatja azt.</p>);

            }
            else if(value === 'freehand') {
                props.layers.draw.freehandDrawing = new Draw({
                    source: props.layers.draw.freehandDrawingSource,
                    type: 'LineString',
                    freehand: true,
                    geometryName:'freehandDraw'
                });
                props.olmap.addInteraction(props.layers.draw.freehandDrawing);
                setInfoData(<p>Az egér gombot lenyomva tartva rajzolhat a térképre.</p>);
            }else if('arrow') {

                props.layers.draw.arrow = new Draw({
                    source: props.layers.draw.arrowSource,
                    type: 'LineString',
                    geometryName:'arrow'                   
                });
                props.olmap.addInteraction(props.layers.draw.arrow);
                setInfoData(<p>Kattintson a kezdéshez, majd a nyil lerakásához dupla kattintson a kivánt pozicióra.</p>);
            };

            if(value !== 'text' && value !== 'freehand' && value !== 'arrow') {
                props.layers.draw.freehand = new Draw({
                    source: props.layers.draw.freehandSource,
                    type: value,
                    geometryFunction: geometryFunction,
                    geometryName:'freehand'
                });
                props.olmap.addInteraction(props.layers.draw.freehand);   
            };
            setAddedTypes([...addedTypes, value]);
        };
    };

    function clearAllInteractions() {
        props.setWriteText(false);
        props.setCanDraw(false);
        props.setType(false);
        removeInteractions();
        props.setCanEditInteraction(false);
        props.setEditing(false);
    };

    function removeInteractions() {

        props.olmap.removeInteraction(props.layers.draw.freehand);
        props.olmap.removeInteraction(props.layers.draw.freehandDrawing);
        props.olmap.removeInteraction(props.layers.draw.arrow);
    }

    function removeDrawElements() {
        props.layers.draw.freehandSource.clear();
        props.layers.draw.arrowSource.clear();
        props.layers.draw.freehandDrawingSource.clear();
        props.layers.textSource.clear();

        props.setType(false);
        props.setCanDraw(false);
        props.setWriteText(false);
        props.setCanEditInteraction(false);
        props.setEditing(false);
    };

    function removeAllElementsWarning() {
        props.setType("remove");
        setInfoData(
            <div className="remove-warning">
                <p>Biztosan eltávolitja az összes rajzolt elemet?</p>
                <button className="yes" onClick={removeDrawElements}>Igen</button>
                <button className="no" onClick={() => props.setType(false)}>Nem</button>
            </div>
        );
    }

    function editInteraction() {
        removeInteractions();
        props.setWriteText(false);
        props.setType("edit");
        setInfoData(<p>Egy elhelyezett rajzra kattintva van lehetőség szerkeszteni azt.</p>);
        props.setCanEditInteraction(true);
    }

    function setupEditContainer() {
        if(editFeatureType === 'freehand') {
            return(
                <div className="style-container">
                    <p>Keret szine</p>
                    <CirclePicker color={interactionColor} onChangeComplete={(color) => setInteractionColor(color.hex)} circleSize={15} circleSpacing={8}/>
                    <p>Kitöltés szine</p>
                    <CirclePicker color={interactionFillColor} onChangeComplete={(color) => setInteractionFillColor(color.rgb)} circleSize={15} circleSpacing={8}/>
                    <p>Kitöltés átlátszósága</p>
                    <AlphaPicker width="100%" color={interactionFillColor} onChange={(color) => setInteractionFillColor({...interactionFillColor, a:color.rgb.a})}/>
                    <p>Keret vastagsága</p>
                    <RangeSlider value={interactionWidth} onChange={event => {setInteractionWidth(event.target.value)}} min={0} max={10} step={0.1} />
                </div>
            );
        }
        else if(editFeatureType === 'freehandDraw') {
            return(
                <div className="style-container">
                    <p>Vonal szine</p>
                    <CirclePicker color={interactionColor} onChangeComplete={(color) => setInteractionColor(color.hex)} circleSize={15} circleSpacing={8}/>
                    <p>Vonal vastagsága</p>
                    <RangeSlider value={interactionWidth} onChange={event => {setInteractionWidth(event.target.value)}} min={0} max={10} step={0.1} />
                </div>
            );
        }
        else if(editFeatureType === 'geometry') {
            return(
                <div className="style-container">
                    <h6>Szöveg serkesztése</h6>
                    <div className="font-picker-container">
                        <p>Szöveg betütipus</p>
                        <FontPicker
                            apiKey={Config.api_keys.google_font}
                            activeFontFamily={textStyle.font}
                            onChange={(nextFont) =>
                                setTextStyle({...textStyle, font:nextFont.family})
                            }
                        />
                    </div>
                    <div className="font-size-picker-container">
                            <p>Szöveg méret</p>
                            <select onChange={(evt) => setTextStyle({...textStyle, fontSize:evt.target.value})}>
                                <option value='12px'>12</option>
                                <option value='15px'>15</option>
                                <option value='17px'>17</option>
                                <option value='19px'>19</option>
                                <option value='21px'>21</option>
                                <option value='28px'>28</option>
                                <option value='36px'>36</option>
                                <option value='44px'>44</option>
                            </select>
                    </div>
                    <div className="font-color-picker-container">
                            <p>Szöveg szin</p>
                            <CirclePicker color={textStyle.fill} onChangeComplete={(color) => setTextStyle({...textStyle, fill:color.hex})} circleSize={15} circleSpacing={7}/>
                    </div>
                </div>
            )

        }
        else {
            return(
                <p className="default-edit-feedback">A kijelölt elem jelenleg nem szerkeszthető.</p>
            );
        }
    }

    function deleteFeature() {

        props.setType("remove-feature");
        removeInteractions();
        setInfoData(<p>Egy elemre kattintva eltávolithatja azt</p>);
        props.setWriteText(false);

        if(selectSetupDone) {
            freehandSelect.setActive(true);
        }
        else {

            //setup remove functionality for all layers
            freehandSelect.getFeatures().on('add', function(feature){
                if(feature.element.geometryName_ === 'freehand') {
                    props.layers.draw.freehandSource.removeFeature(feature.element);
                }else if(feature.element.geometryName_ === 'freehandDraw'){
                    props.layers.draw.freehandDrawingSource.removeFeature(feature.element);
                }else if(feature.element.geometryName_ === 'arrow'){
                    props.layers.draw.arrowSource.removeFeature(feature.element);
                }else if(feature.element.get('name')=== 'text'){
                    props.layers.textSource.removeFeature(feature.element);
                    
                }
                feature.target.remove(feature.element);

            });
            props.olmap.addInteraction(freehandSelect);
            freehandSelect.setActive(true);
            setSelectSetupDone(true);
        }
    }
 
    return(
        <>
            <div className={active ? "map-interactions-container" : "map-interactions-container notextended"} onClick={!active ? () => setActive(true) : null} role={!active ? "button" : ""}>
                {active ?
                    <div className="map-interactions-active">
                        <FontAwesomeIcon role="button" size="lg" icon={['far', 'times']} onClick={() => {
                            setActive(false);
                            clearAllInteractions();
                        }}/>
                        <p>Rajz lehetőségek</p>
                        <div className="map-interactions-active-button-container">
                            <button className={props.type === 'Square' ? "map-interaction-button active":"map-interaction-button"} onClick={() => addInteraction('Square')}>Négyzet</button>
                            <button className={props.type === 'Box' ? "map-interaction-button active":"map-interaction-button"} onClick={() => addInteraction('Box')}>Téglalap</button>
                            <button className={props.type === 'Star' ? "map-interaction-button active":"map-interaction-button"} onClick={() => addInteraction('Star')}>Csillag</button>
                            <button className={props.type === 'freehand' ? "map-interaction-button active":"map-interaction-button"} onClick={() => addInteraction('freehand')}>Rajz</button>
                            <button className={props.type === 'text' ? "map-interaction-button active":"map-interaction-button"} onClick={() => addInteraction('text')}>Szöveg</button>
                            <button className={props.type === 'arrow' ? "map-interaction-button active":"map-interaction-button"} onClick={() => addInteraction('arrow')}>Nyil</button>
                            <button className={props.type === 'edit' ? "map-interaction-button active":"map-interaction-button"} onClick={() => editInteraction()}>Szerkesztés</button>

                        </div>
                        <div className="map-interaction-remove-button-container">
                            <button className="remove" title="Összes rajzolt elem törlése" onClick={() => removeAllElementsWarning()}><FontAwesomeIcon size="lg" icon={['far', 'trash']}/></button>
                            <button className="end" title="Rajzolás befejezése" onClick={() => clearAllInteractions()}><FontAwesomeIcon size="lg" icon={['far', 'stop-circle']}/></button>
                            <button className="history" title="Kiválasztott elemek törlése" disabled={!addedTypes.length} onClick={() => deleteFeature()}><FontAwesomeIcon size="lg" icon={['far', 'history']}/></button>
                        </div>
                    </div>
                :
                    <FontAwesomeIcon role="button" size="lg" icon={['far', 'pencil']}/>
                }
            </div>
            {props.type && 
            <div className="map-interactions-info-block">
                <div>
                    <FontAwesomeIcon size="1x" icon={['fas', 'info-circle']} />

                    {infoData}
                </div>
            </div>}
            {props.editing && 
            <div className="map-interactions-edit-block">
                <FontAwesomeIcon role="button" size="lg" icon={['far', 'times']} onClick={() => {
                    props.setEditing(false);
                    clearAllInteractions();
                }}/>
                {setupEditContainer()}
            </div>}
        </>
    );
}