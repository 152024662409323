import React, { useState, useEffect } from 'react';
import Config from '../../config/config';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import LoginUser from './pharos_login';
import { Row, Col } from "react-bootstrap";


const DashboardPharos = () => {
    const [token, setToken] = useState(sessionStorage.pharosToken);
    const [loading, setLoading] = useState(false);

    const pharosList = [
        { id: 'cloud', path: Config.pharosFunctions.cloud.path },
        { id: 'config', path: Config.pharosFunctions.config.path },
        { id: 'controller', path: Config.pharosFunctions.controller.path },
        { id: 'project', path: Config.pharosFunctions.project.path },
        { id: 'input', path: Config.pharosFunctions.input.path },
        { id: 'log', path: Config.pharosFunctions.log.path },
        { id: 'timeline', path: Config.pharosFunctions.timeline?.path }
    ];

    const [controllers, setControllers] = useState([]);
    const [logData, setLogData] = useState('');
    const [cloudData, setCloudData] = useState({});
    const [projectData, setProjectData] = useState({});
    const [configData, setConfigData] = useState({});
    const [inputData, setInputData] = useState([]);
    const [timelineData, setTimelineData] = useState([]);
    
    useEffect(() => {
        if (token) {
            setLoading(true);
            sessionStorage.setItem('pharosToken', token);
            pharosList.forEach((post) => {
                getApi(post.path, post.id);
            });
        }
    }, [token]);

    function pharosLogout() {
        setToken('')
        sessionStorage.setItem('pharosToken', '');
    }

    function getApi(path, id) {
        setLoading(true);
        const apiUrl = process.env.REACT_APP_PHAROS_API_URL;
        fetch(apiUrl, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ function: path, token })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        }).then(data => {
            if (data?.body) {
                switch (id) {
                    case "controller":
                        setControllers(data?.body?.controllers);
                        break;
                    case "log":
                        setLogData(data?.body?.log);
                        break;
                    case "cloud":
                        setCloudData(data?.body?.state);
                        break;
                    case "project":
                        setProjectData(data?.body);
                        break;
                    case "config":
                        setConfigData(data?.body);
                        break;
                    case "input":
                        setInputData(data?.body?.gpio);
                        break;
                    case "timeline":
                        setTimelineData(data?.body?.timelines);
                        break;
                    default:
                        break;
                }
                setLoading(false);
            }
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    }
    

    if (!token || token === 'undefined' || token === '') {
        return <LoginUser token={token} setToken={setToken} />;
    }
        return (
            <BrowseLayout>
                <div style={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
                    <div className='text-white'>Dashboard</div>
                    {loading && <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div>}
                    <div className='text-white' style={{cursor: "pointer", fontSize: "0.8rem"}} onClick={pharosLogout}>Kilépés</div>
                </div>
                <div className="pharos-dashboard-boxes">
                    <Row className="dasboard-container-row">
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Controllers</div>
                                <div className="thirdparty-description">
                                {controllers?.length > 0 ?
                                    controllers?.map((controller, index) => (
                                        <div key={index}>
                                            <p>Name: {controller?.name}</p>
                                            <p>IP Address: {controller?.ip_address}</p>
                                            <p>Serial: {controller?.serial}</p>
                                            <p>Type: {controller?.type}</p>
                                            <p>Online: {controller?.online ? "Yes" : "No"}</p>
                                            <p>Is Network Primary: {controller?.is_network_primary ? "Yes" : "No"}</p>
                                            <br />
                                        </div>

                                    ))
                                    :
                                    <div onClick={() => getApi(Config.pharosFunctions.controller.path, "controller")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Log</div>
                                <div className="thirdparty-description">
                                {logData ? 
                                    logData.split('\n').map((log, index) => (
                                        <p key={index}>{log}</p>
                                    ))
                                :
                                    <div onClick={() => getApi(Config.pharosFunctions.log.path, "log")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Cloud</div>
                                <div className="thirdparty-description">
                                {cloudData ? (<>
                                    <p>Connected: {cloudData?.connected ? "True" : "False"}</p>
                                    <p>Connecting: {cloudData?.connecting ? "True" : "False"}</p>
                                </>) :
                                    <div onClick={() => getApi(Config.pharosFunctions.cloud.path, "cloud")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Config</div>
                                <div className="thirdparty-description">
                                {configData ? <>
                                    <p>Day: {configData?.day}</p>
                                    <p>DHCP Enabled: {configData?.dhcp_enabled ? "True" : "False"}</p>
                                    <p>Gateway: {configData?.gateway}</p>
                                    <p>Hour: {configData?.hour}</p>
                                    <p>HTTP Port: {configData?.http_port}</p>
                                    <p>HTTPS Port: {configData?.https_port}</p>
                                    <p>IP: {configData?.ip}</p>
                                    <p>Log Level: {configData?.log_level}</p>
                                    <p>Minute: {configData?.minute}</p>
                                    <p>Month: {configData?.month}</p>
                                    <p>Name Server 1: {configData?.name_server_1}</p>
                                    <p>Name Server 2: {configData?.name_server_2}</p>
                                    <p>NTP Enabled: {configData?.ntp_enabled ? "True" : "False"}</p>
                                    <p>NTP IP: {configData?.ntp_ip}</p>
                                    <p>Second: {configData?.second}</p>
                                    <p>Subnet Mask: {configData?.subnet_mask}</p>
                                    <p>Syslog Enabled: {configData?.syslog_enabled ? "True" : "False"}</p>
                                    <p>Syslog IP: {configData?.syslog_ip}</p>
                                    <p>Watchdog Enabled: {configData?.watchdog_enabled ? "True" : "False"}</p>
                                    <p>Year: {configData?.year}</p>
                                </> :
                                    <div onClick={() => getApi(Config.pharosFunctions.config.path, "config")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Input</div>
                                <div className="thirdparty-description">
                                {inputData?.length > 0 ?
                                    inputData?.map((input, index) => (
                                        <div key={index}>
                                            <p>Input: {input?.input}</p>
                                            <p>Type: {input?.type}</p>
                                            <p>Value: {input?.value ? "True" : "False"}</p>
                                            <br />
                                        </div>
                                    ))
                                :
                                    <div onClick={() => getApi(Config.pharosFunctions.input.path, "input")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Timeline</div>
                                <div className="thirdparty-description">
                                {timelineData?.length > 0 ?
                                    timelineData?.map((timeline, index) => (
                                        <div key={index}>
                                            <p>Audio Band: {timeline?.audio_band}</p>
                                            <p>Audio Channel: {timeline?.audio_channel}</p>
                                            <p>Audio Peak: {timeline?.audio_peak ? "True" : "False"}</p>
                                            <p>Custom Properties: {JSON.stringify(timeline?.custom_properties)}</p>
                                            <p>Group: {timeline?.group}</p>
                                            <p>Length: {timeline?.length}</p>
                                            <p>Name: {timeline?.name}</p>
                                            <p>Number: {timeline?.num}</p>
                                            <p>Onstage: {timeline?.onstage ? "True" : "False"}</p>
                                            <p>Position: {timeline?.position}</p>
                                            <p>Priority: {timeline?.priority}</p>
                                            <p>Source Bus: {timeline?.source_bus}</p>
                                            <p>State: {timeline?.state}</p>
                                            <p>Time Offset: {timeline?.time_offset}</p>
                                            <p>Timecode Format: {timeline?.timecode_format}</p>
                                            <br />
                                        </div>
                                    ))
                                :
                                    <div onClick={() => getApi(Config.pharosFunctions.timeline.path, "timeline")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                            <Col className="col-kaptalan" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="pharos-category-title">Project</div>
                                <div className="thirdparty-description">
                                {projectData ? <>
                                    <p>Name: {projectData?.name}</p>
                                    <p>Author: {projectData?.author}</p>
                                    <p>Filename: {projectData?.filename}</p>
                                    <p>Unique ID: {projectData?.unique_id}</p>
                                    <p>Upload Date: {projectData?.upload_date}</p>
                                </>:
                                    <div onClick={() => getApi("Project")}>Frissítés</div>
                                }
                                </div>
                            </Col>
                    </Row>
                </div>
            </BrowseLayout>
        );
}

export default DashboardPharos;