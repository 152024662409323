import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../../config/config';
import { Button, Modal } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import {getUserCadastreData} from '../../state/reducers/user';
import DropZoneUpload from '../../helpers/DropZoneUpload';
import { KataszterController } from '../../controllers/KataszterController';

const Information = (props) => {


    const [popup, setPopup] = useState({visible: false, imageUrl: '', note:false, rotation:0});
    const [data, setData] = useState(false);
    // const [warning, setWarning] = useState("");
    // const [selected, setSelected] = useState({});
    // const [loadingData, setLoadingData] = useState(false); // eslint-disable-line
	const [edit, setEdit] = useState(false);

    // const KataszterControllerInst = KataszterController.getInstance();

    useEffect(() => {
        if(props.data.cadastre_id !== null) {
            setData(true);
        }
    }, []) // eslint-disable-line

    // useEffect(() => {
    //     if(props.data && edit) {
    //         setupModifiableData(props.data);
    //     }
    // }, [edit]);

    //Setup base URL for the pictures folder.

    const imgURL = Config.static.img;
    const thumbnailURL = Config.static.imgThumbnail;

    let datasheet = false;
    let catalogue = false;

	const cadastre_id = props.data.hasOwnProperty("cadastre_id") && props.data.cadastre_id;
    
    // function getTaskData() {
        
    //     if (props.cadastreData && props.data.cadastre_id !== undefined && props.cadastreData[props.data.cadastre_id]) {
    //         let form_json = props.cadastreData[props.data.cadastre_id].form_json;

    //         if (typeof form_json === 'string') {
    //             try {
    //                 form_json = JSON.parse(form_json);
    //             } catch (e) {
    //                 console.error('Error parsing form_json:', e);
    //             }
    //         }

    //         if (Array.isArray(form_json)) {
    //             form_json = form_json.filter(x => x.input !== 'image' && x.input !== 'file');
    //         }
        

    //         let task_device = props.data.device;
    //         return(
    //             form_json.map(field_data => { // eslint-disable-line
    //                 if(field_data.visible.visible_details) {
    //                     return(
    //                         <tr key={task_device[field_data.name]}><td className="key">{field_data.label}:</td><td>{checkValueMask(field_data, task_device[field_data.name])}</td></tr>
    //                     );
    //                 }
    //             })
    //         )
    //     }
    //     else {
    //         return(<p>Nincs megjelenitendő adat.</p>)
    //     }
    // };

    // function getTaskData() {

	// 	if (props.cadastreData && props.data.cadastre_id !== undefined && props.cadastreData[props.data.cadastre_id]) {
    //         let formData = props.cadastreData[props.data.cadastre_id].form_json;

    //         if (typeof formData === 'string') {
    //             try {
    //                 formData = JSON.parse(formData);
    //             } catch (e) {
    //                 console.error('Error parsing formData:', e);
    //             }
    //         }

    //         if (Array.isArray(formData)) {
    //             formData = formData.filter(x => x.input !== 'image' && x.input !== 'file');
    //         }
        

    //         let task_device = props.data.device;
            
    //         console.log('data', data);
            
	// 		return(formData.map(element => { // eslint-disable-line
    //             // formData = task_device.find(x => (x.name === element.name));
	// 			if(element.input !== 'image') {
	// 				if(formData) {
	// 					if(formData.editable && edit) {
	// 						return setupModifiableData(formData, element);
	// 					}else {
	// 						return(
	// 							<tr key={element.name}>
	// 								<td className="key">{element.label}:</td>
	// 								<td>{formData.input === 'list' ? formData.values[element.value] :element.value}</td>
	// 							</tr>
	// 						)
	// 					}
	// 				}
	// 			}
	// 		}));
	// 	}
	// 	else {
	// 		return(Object.entries(props.data.values_).map(([key, value]) => { // eslint-disable-line
	// 			if(typeof value === 'string') {
	// 				return(<tr key={key}><td className="key">{key}</td><td>{value}</td></tr>)
	// 			}
	// 		}));
	// 	}
	// }



    function getTaskData() {
        
        if (props.cadastreData && props.data.cadastre_id !== undefined && props.cadastreData[props.data.cadastre_id]) {
            let formData = props.cadastreData[props.data.cadastre_id].form_json;

            if (typeof formData === 'string') {
                try {
                    formData = JSON.parse(formData);
                } catch (e) {
                    console.error('Error parsing formData:', e);
                }
            }

            if (Array.isArray(formData)) {
                formData = formData.filter(x => x.input !== 'image' && x.input !== 'file');
            }
        

            let task_device = props.data.device;
            return(
                formData.map(field_data => { // eslint-disable-line
                    if(field_data.visible.visible_details) {
                        return(
                            <tr key={task_device[field_data.name]}><td className="key">{field_data.label}:</td><td>{checkValueMask(field_data, task_device[field_data.name])}</td></tr>
                        );
                    }
                })
            )

            // if(element.input !== 'image') {
            //     if(formData) {
            //         if(formData.editable && edit) {
            //             return setupModifiableData(formData, element);
            //         }else {
            //             return(
            //                 <tr key={element.name}>
            //                     <td className="key">{element.label}:</td>
            //                     <td>{formData.input === 'list' ? formData.values[element.value] :element.value}</td>
            //                 </tr>
            //             )
            //         }
            //     }
            // }


            // return(
            //     form_json.map(field_data => { // eslint-disable-line
            //         if(field_data.visible.visible_details) {
            //             return(
            //                 <tr key={task_device[field_data.name]}><td className="key">{field_data.label}:</td><td>{checkValueMask(field_data, task_device[field_data.name])}</td></tr>
            //             );
            //         }
            //     })
            // )
        }
        else {
            return(<p>Nincs megjelenitendő adat.</p>)
        }
    };

    //for future
    function checkValueMask(field_data, value) {
        if(value === null) {
            return "N/A";
        }else {
            if(field_data.hasOwnProperty("values")) {
                return value;
            }else {
                return value;
            }
        }
    };

    // function getValue(key, element) {
    //     let value = element.value
    //     if(selected.hasOwnProperty(cadastre_id)) {
    //         if(selected[cadastre_id].hasOwnProperty(key)) {
    //             value = selected[cadastre_id][key]
    //         }
    //         else {
    //             value = element.value
    //         }
    //     }
    //     return value;
    // }

    // function updateSelected(value, id, key) {
    //     let temp = selected;
    //     if(selected.hasOwnProperty(id)) {
    //         if(selected[id].hasOwnProperty(key)) {
    //             if(value === "null" || value === "") {
    //                 delete temp[id][key];
    //                 if(!Object.keys(temp[id]).length){
    //                     delete temp[id];
    //                 }
    //             }else {
    //                 let obj = {}
    //                 obj[key] = value;

    //                 temp[id] = {
    //                     ...temp[id],
    //                     ...obj
    //                 }
    //             }
    //         }else {
    //             temp[id][key] = value
    //         }
    //     }else {
    //         let obj = {}
    //         obj[key] = value;

    //         temp[id] = {
    //             ...obj
    //         }
    //     }
    //     setSelected({...temp});
    // }

    // function setupModifiableData(formData, element) {
    //     if(formData.input === "string" || formData.input === "number"){
    //         return(
    //             <tr >
    //                 <td style={{fontSize: "11px", fontWeight:"600"}}>{formData.label}</td>
    //                 <td>
    //                     <input type={formData.input === "string" ? "text" : "number"} style={{fontSize:'11px', fontWeight:'200'}} name={formData.name} className="small form-control filter-search" placeholder="Nincs megadva" value={getValue(element.name, element)} onChange={(event) => {updateSelected(event.target.value, cadastre_id, formData.name)}}/>
    //                 </td>
    //             </tr>
    //         );
    //     }
    //     else if(formData.input === "list") {
    //         return(
    //             <tr >
    //                 <td style={{fontSize: "11px", fontWeight:"600"}} >{formData.label}</td>
    //                 <td>
    //                     <select className="form-control " style={{fontSize: '11px', margin: 0, padding: '6px', height: '100%' }} onChange={(event) => {console.log(event)}} value={getValue(formData.name, element)}>
    //                         <option value="null">---</option>
    //                         {Object.entries(formData.values).map(([key, value]) => {
    //                             return(
    //                                 <option value={key}>{value}</option>
    //                             )
    //                         })}
    //                     </select>
    //                 </td>
    //             </tr>);
    //     }
    //     else {
    //         return(<tr key={formData.name}><td className="key">{formData.label}:</td><td>{element.value}</td></tr>)
    //     }
    // }

    const setupModifiableData = () => {
        let formData = props.cadastreData[props.data.cadastre_id].form_json;
        if (typeof formData === 'string') {
            try {
                formData = JSON.parse(formData);
            } catch (e) {
                console.error('Error parsing formData:', e);
            }
        }

        if (Array.isArray(formData)) {
            formData = formData.filter(x => x.input !== 'image' && x.input !== 'file');
        }

        const taskDevice = props.data.device;

        return formData.map((item, index) => {
            console.log('item', item)
            if (item.input === "string" || item.input === "number") {
                return (
                    <tr key={index}>
                        <td style={{ fontSize: "11px", fontWeight: "600" }}>{item.label}</td>
                        <td>
                            <input type={item.input === "string" ? "text" : "number"} 
                                style={{ fontSize: '11px', fontWeight: '200' }} 
                                name={item.name} 
                                className="small form-control filter-search" 
                                placeholder="Nincs megadva" 
                                value={taskDevice[item.name]} 
                                onChange={(event) => { console.log(event) }} 
                            />
                        </td>
                    </tr>
                );
            } else if (item.input === "list") {
                return (
                    <tr key={index}>
                        <td style={{ fontSize: "11px", fontWeight: "600" }}>{item.label}</td>
                        <td>
                            <select className="form-control" 
                                style={{ fontSize: '11px', margin: 0, padding: '6px', height: '100%' }} 
                                onChange={(event) => { console.log(event) }} 
                                value={taskDevice[item.name]}
                            >
                                <option value="null">---</option>
                                {Object.entries(item.values).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                );
            } else {
                return (
                    <tr key={index}>
                        <td className="key">{item.label}:</td>
                        <td>{item.value}</td>
                    </tr>
                );
            }
        });
    };

    // function updateTasks() {
    //     setLoadingData(true);
    //     let updateData = selected[props.updateId];
    //     let temp = {};

    //     props.cadastre_details.filter(x => x.editable || x.required).forEach(x => {
    //         temp[x.name] = updateData.hasOwnProperty(x.name) ? updateData[x.name] : props.data.json[x.name]
    //     });


    //     KataszterControllerInst.updateKataszterById(props.updateId, temp, props.data.loc.coordinates).then(response => {
    //         if(response.statusCode === 200) {
    //             let temp = [];
    //             data.forEach(element => {
    //                 if(Object.keys(updateData).includes(element.name)) {
    //                     let i = element;
    //                     i.value = updateData[element.name]
    //                     temp.push(i);
    //                 }else {
    //                     temp.push(element);
    //                 }
    //             });
    //             setData(temp);
    //         }else {
    //             setWarning("Az adatokat nem sikerült frissiteni.")
    //         }
    //         setLoadingData(false);
    //         props.updateSelected(null)
    //     })
    // }

    return (
        <div>
            {(props.data.lpt_id !== "" || Object.keys(props.data.device).length) ? <div className="infotable-wrapper">
            <div className="infotable row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-9">
                        <h5>Készülék</h5>
                        {/* {data && <div style={{marginTop:'10px'}}>
                            <span style={{fontSize:'12px'}} role="button" onClick={() => setEdit(!edit)}><FontAwesomeIcon style={{marginRight:'5px'}} alt="Szerkesztés" size="1x" icon={['fas', 'edit'] }/>Adatok szerkesztése</span>
                        </div>} */}
                        <div className="table-wrapper">
                            <table>
                                <tbody>
                                    {/* {!edit ?
                                    <> */}
                                        {data ? getTaskData() : <p>Nincs megjelenítendő adat.</p>}
                                    {/* </>
                                    :
                                    <>
                                        {data && setupModifiableData()}
                                    </>
                                    } */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="documents">
                                    <h5>Csatolt dokumentumok</h5>
                                    {catalogue && <div><a rel="noopener noreferrer" target="_blank" href={catalogue}><i><FontAwesomeIcon icon={['fas', 'file'] }/></i>Katalógus</a></div>}
                                    {datasheet && <div><a rel="noopener noreferrer" target="_blank" href={datasheet}><i><FontAwesomeIcon icon={['fas', 'file'] }/></i>Adatlap</a></div>}
                                    {parseInt(props.data.act) === 1 ? <div><a href="#garancia"><i><FontAwesomeIcon icon={['fas', 'file'] }/></i>Garancia papír</a></div> : null}
                                    {(!parseInt(props.data.act) === 1  && !catalogue && !datasheet) ? <p>Nincsenek feltöltött dokumentumok.</p> : null}
                                </div>
                                <div className="images">

                                {(props.data.images !== null && props.data.images !== undefined) &&
                                    <div className="image">
                                        <h5>Képek</h5>
                                        {props.data.images.length ?<div className="img-grid">
                                            {props.data.images.map((image) => (
                                                <div stlye={{rotation:image.th_rotation}} key={image?.file} onClick={() => setPopup({
                                                            visible: true,
                                                            // imageUrl: !props.data.status.action ? imgURL + image.file : oldImgURL + props.data.ref_spot + '.png',
                                                            imageUrl: imgURL + image?.file,
                                                            rotation: image.rotation,
                                                            note:false
                                                        })} className="img" style={{backgroundImage: `url(${thumbnailURL + image.file})`, transform:'rotate(' + image.th_rotation + 'deg)'}} />
                                            ))}
                                            </div>
                                        :<p>Nincsenek megjelenitendő képek</p>}
                                    </div>
                                }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <DropZoneUpload data={props.data} type="task" />
                            </div>  
                        </div>
                    </div> */}


                    {/* {warning !== "" &&
                        <div><p>{warning}</p></div>
                    } */}
                    {/* <div className="acceptbuttons">
                        {props.updateId === props.data.id && <Button variant="primary" onClick={() => updateTasks()} disabled={!selected.hasOwnProperty(props.updateId)}>Változtatások mentése</Button>}
                        {props.updateId === props.data.id && <Button variant="danger" onClick={() => props.updateSelected(null)}>Vissza</Button>}
                    </div> */}
                </div>
            </div>
            </div>
            <Modal className="confirm" show={popup.visible} onHide={() => setPopup({visible: false, imageUrl: popup.imageUrl})} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <img
                    className="img"
                    src={popup.imageUrl}
                    alt={props.data.task}
                    style={{"height" : "auto", "width" : "100%", transform:'rotate(' + popup.rotation + 'deg)' }}
                    />
                    {popup.note ? <p style={{padding:"10px"}}>Megjegyzés: {popup.note}</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {setPopup({visible: false, imageUrl: popup.imageUrl})}}>Bezár</Button>
                </Modal.Footer>
            </Modal>
            </div>:
            <p>Ehez a feladathoz nem tartozik készülék</p>}
        </div>
    );
};

const mapStateToProps = state => ({
    cadastreData : getUserCadastreData(state),
});


export default connect(
    mapStateToProps,
    null,
)(Information);
