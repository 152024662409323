import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import {getNavigationState, toggle, toggleSidebar, getView, toggleSettings, closeSidebar} from '../state/reducers/ui';
import SupportPopup from './ui/SupportPopup';
import { getUserAccess } from "../state/reducers/user";

import SidebarNavigation from "./ui/SidebarNavigationAlter"
import { history } from '../state/store';

const defaultBottomLogo = require('../assets/images/menu_bottom_logo.svg');

/**
 * SideBar  Component
 * 
 * Basic Functionality :
 *  - Create quick access sidebar elements for the dashboard containers.
 * **/
function Sidebar(props) {
  
    const [supportShown, setSupportShow] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);  // eslint-disable-line

    const [bottomLogo, setBottomLogo] = useState(defaultBottomLogo);

    useEffect(() => {
        const loadBottomLogo = async () => {
            try {
                const dynamicBottomLogo = require(`../assets/images/${process.env.REACT_APP_LOGO}`);

                if (dynamicBottomLogo) {
                    setBottomLogo(dynamicBottomLogo);
                } else {
                    throw new Error(`Logo not found`);
                }
            } catch (error) {
                console.error('Error loading dynamic logo:', error);
            }
        };
        
        loadBottomLogo();
      }, []);

    function hideSupport() {
        setSupportShow(false);
    }

    function handleClick(e) {
        e.preventDefault();
        const id = e.currentTarget.attributes.id.value;
        props.toggle(id);

    }

    function handleResize() {
        if(window.innerWidth < 1125) {
            if(props.navigationState.sidebar) {
                props.closeSidebar();
            }
        } else {
            if(!props.navigationState.sidebar) {
                props.toggleSidebar();
            }
        }
    }

    const [onMouseDashboardOver, setOnMouseDashboardOver] = useState(false);
    const [onMouseSupportOver, setOnMouseSupportOver] = useState(false);
    const isActive = props.path === "/dashboard";

    const activeDashboardStyle = {
        backgroundColor: isActive ? (process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT) : '',
        borderRadius: isActive ? '30px 0 0 30px' : '',
        marginLeft: isActive ? '0px' : '',
        marginRight: isActive ? '-4px' : ''
    };

    const combinedDashboardStyle = {
        ...activeDashboardStyle,
        backgroundColor: onMouseDashboardOver ? (process.env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR_DEFAULT) : activeDashboardStyle.backgroundColor,
        borderRadius: onMouseDashboardOver ? '0px 0 0 0px' : activeDashboardStyle.borderRadius
    };

    const combinedSupportStyle = {
        backgroundColor: onMouseSupportOver && (process.env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR_DEFAULT),
        borderRadius: onMouseSupportOver && '0px 0 0 0px',
    };

    return (
        <React.Fragment>
            <SupportPopup closeSupport={hideSupport} shown={supportShown}/>
            <nav id="" className={props.navigationState.sidebar ? 'sidebar-wrapper' : 'sidebar-wrapper closed'}>
                <div className="sidebar-content">
                    <div className="sidebar-menu">
                    <ul className="main-menu">
                        <li
                            className={isActive ? 'active': ''}
                            style={combinedDashboardStyle}
                            onMouseOver={() => setOnMouseDashboardOver(true)}
                            onMouseOut={() => setOnMouseDashboardOver(false)}
                        >
                            <a id="dashboard" style={{cursor: 'pointer'}} onClick={() => history.push("/dashboard")} >
                                <div>
                                    <span><i><FontAwesomeIcon icon={['fas', 'home']} /></i></span> <span>Dashboard</span>
                                </div>
                            </a>
                        </li>

                        {props?.userAccess?.dashboard?.smart === "active" && <SidebarNavigation toggled="smart" backgroundColor='#CA8DEE' icon={['fas', 'router']} name='Smart elemek' handleClick={handleClick} parent={props} route="/smart" target=""/> }
                        {/* <SidebarNavigation toggled="kataszter" backgroundColor='#CA8DEE' icon={['fas', 'clipboard-list']} name='Kataszterek' handleClick={handleClick} parent={props} route="/kataszter"/> */}
                        {/* <SidebarNavigation toggled="ü" backgroundColor='#CA8DEE' icon={['fas', 'user-tie']} name='Ügyintézés' handleClick={handleClick} parent={props} route="/smart"/> */}
                        {props?.userAccess?.dashboard?.thirdparty === "active" && <SidebarNavigation toggled="thirdparty" backgroundColor='#CA8DEE' icon={['fas', 'link']} name='Külső szoftverkapcsolat' handleClick={handleClick} parent={props} route="/thirdparty" target=""/> }
                        {/* <SidebarNavigation toggled="citymarketing" backgroundColor='#CA8DEE' icon={['fas', 'building']} name='Városüzemeltetés' handleClick={handleClick} parent={props} route="https://erp.smart-city.work/" target="_blank"/> */}

                        <li
                            className="support-link"
                            style={combinedSupportStyle}
                            onMouseOver={() => setOnMouseSupportOver(true)}
                            onMouseOut={() => setOnMouseSupportOver(false)}
                        >
                            <a href="https://udg.hu/" id="support">
                                <div>
                                    <span><i><FontAwesomeIcon icon={['fas', 'info-circle']} /></i></span> <span>Kapcsolat</span>
                                </div>
                            </a>
                        </li>  
                        </ul>
                    </div>
                </div>
                <div className="bottom-logo" style={{ marginTop: '10px' }}>
                    <i><img src={bottomLogo} alt="Logo" /></i>
                </div>
                    
            </nav>
        </React.Fragment>
        );
}

const mapStateToProps = state => ({
    navigationState: getNavigationState(state),
    path: state.router.location.pathname,
    view: getView(state),
	userAccess: getUserAccess(state),
});

const mapDispatchToProps = (dispatch) => ({
    toggle: (id) => {
        dispatch(toggle(id));
    },
    toggleSidebar: () => {
        dispatch(toggleSidebar());
    },
    toggleSettings: () => {
        dispatch(toggleSettings())
    },
    closeSidebar: () => {
    	dispatch(closeSidebar());
	},
});

const TranslatedSidebar = withTranslation()(Sidebar);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TranslatedSidebar);
