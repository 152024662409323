import Config from "../config/config";

export class AlertController {

    private static instance: AlertController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): AlertController {
        if (!AlertController.instance) {
            AlertController.instance = new AlertController();
        }
        return AlertController.instance;
    }

    public getAlerts() {

        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.alerts.get.replace('{userid}', sessionStorage.user_id);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        });
    }

    public readAlert(ids:any){
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.alerts.read;

        let formData = new FormData();
        formData.append("userId", sessionStorage.user_id);

        ids.forEach((id:any) => {
            formData.append('alertIds[]', id);
 
        });

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                body:formData
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        });
    }

    public deleteAlert(ids:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.alerts.delete;

        let formData = new FormData();
        formData.append("userId", sessionStorage.user_id);

        ids.forEach((id:any)=> {
            formData.append('alertIds[]', id);
        });

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                body:formData
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        });
    }
    
};
