//Import interfaces
import {
    filterObjectInterface
} from "../ts/interfaces/kataszterconroller_interfaces"
import Config from "../config/config";


export class KataszterController {

    private static instance: KataszterController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {
    }

    /**
     * Controlling the singleton
     */
    public static getInstance(): KataszterController {
        if (!KataszterController.instance) {
            KataszterController.instance = new KataszterController();
        }
        return KataszterController.instance;
    }


    /**
     * Send message to the backend with parameters: 
     * @param projectId string - id of the project to update
     * @param timeStamp Integer - timestamp of the json created, every newer task data should be updated
     */
    public getKatasztersById(id:string, page:string, limit:string, filters:Array<filterObjectInterface>) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.get;
        let urlParams:string = "/?identity=" + sessionStorage.username + "&limit=" + limit + "&page=" + page

        if(Object.keys(filters).length) {
            Object.entries(filters).forEach(([key, value]) => {
                urlParams = urlParams + "&" + key + "=" + value;
            })
        }

        return new Promise((resolve, reject) => {
            fetch(url + urlParams, {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }


    public updateKataszterById(id:string, cadastre_id:string, data:any, coord:any) {

        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.update;
        let formData: any = {};

        let coords: any = {};
        let identity: string = sessionStorage.username;

        Object.keys(data).forEach((key:any) => {
            formData[key] = data[key];
        });

        coords["geometry[0][]"] = coord[0];
        coords["geometry[1][]"] = coord[1];

        const postData = {
            id,
            cadastre_id,
            formData,
            coords,
            identity
        };
    
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postData),
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    reject({statusCode: 500, data: "Failed to fetch data"});
                }
            }).then(response => {
                resolve({statusCode: 200, data: response});
            }).catch(error => {
                reject({statusCode: 500, data: error});
            });
        });
    }

    /**
     * getAllCadastre
     */
    public getAllCadastre() {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.list;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username,
                    'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                response.forEach((cadastre:any) => {
                    if(Config.layers.hasOwnProperty(cadastre.cadastre_ref)) {
                        Config.layers[cadastre.cadastre_ref].title = cadastre.cadastre_label;
                        Config.layers[cadastre.cadastre_ref].icon = cadastre.icon;

                        if(Config.layers[cadastre.cadastre_ref].hasOwnProperty("marker")) {                            
                            Config.layers[cadastre.cadastre_ref].marker.src = "markers/" + cadastre.cadastre_ref + ".png";
                            Config.layers[cadastre.cadastre_ref].marker_highlight.src = "markers/" + cadastre.cadastre_ref + ".png";
    
                        }
                    }
                });
                this.saveCadastre(response);
                resolve({statusCode:200, data:response})
            })
        })
    };

    private saveCadastre(data:any) {
        //Get cadastre data while login
        let temp:any = {};
        data.forEach((cadastre:any) => {
            temp[cadastre.cadastre_id] = {...cadastre,
                form_json: cadastre.form_json,
                map_settings: cadastre.map_settings,
                city_names: cadastre.city_names || ""};
        });
        sessionStorage.setItem("cadastreData", JSON.stringify(temp));
    }

    /**
     * getAllCadastreGroups
     */
    public getAllCadastreGroups() {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.groups;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })

    }
    /**
     * getNumberOfCadastres
     */
    public getNumberOfCadastres(){
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.numberofcadastres;

        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }
    /**
     * getNumberOfItemsInCadastres
     */
    public getNumberOfItemsInCadastres(){
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.numberofitemsincadastres;
        
        return new Promise((resolve, reject) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        })
    }

    public getCadastreSpots(ids:any, page:any, limit:any, filters:any, geojson:any, sort:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.cadastrespots;
        let formData:any = {
            filters: {
                cadastres: ids,
                fields: filters.map((filter: any) => ({
                    type: filter.type,
                    id: filter.id,
                    name: filter.name,
                    value: filter.value
                }))
            },
            type: geojson ? 'geojson' : 'json',
            page: page || 1,
            limit: limit || 0,
            sort: []
        };
        if("name" in sort) {
            formData.sort.push({[sort.name]: sort.desc ? "desc" : "asc"});
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(formData)
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            }).catch(err => {
                reject({statusCode:500, data:'Failed to fetch'})
            })
        });

    }

    public uploadFile(id:any, files:any, desc:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.file.upload;
        let formData = new FormData();

        formData.append("cadastreItemId", id);
        formData.append("userId", sessionStorage.user_id);


        files.forEach((file:any, index:any) => {
            formData.append("files["+ index +"]", file, file.name);
            formData.append("desc["+ index +"]", desc[file.name]);
        });

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData,
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response});
            })
        });
    };

    public deleteFile(id:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.file.delete;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("userId", sessionStorage.user_id);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData,
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:{success:true}});
            })
        });
    };


    public getLiveData(ref:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.kataszter.live.get.replace("{ref}", ref);

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"GET",
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:response})
            })
        });
    }

};
