import React, {useEffect, useState} from 'react';
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import LineChart from '../charts/LineChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import OwletMap from "./owlet_map";
import { ExedraAPIController } from '../../../controllers/ExedraAPIController';

import moment from 'moment';

function OwletDetails(props) {

    const ExedraAPIControllerInst = ExedraAPIController.getInstance();

    const [consumptionData, setConsumptionData] = useState([])

    const [type, setType] = useState(30);
    const [period, setPeriod] = useState('Day');
    const [loading, setLoading] = useState(false);
    const [lamps, setLamps] = useState([]);
    // const [mapType, setMapType] = useState('broken');
    const [failuresHistory, setFailuresHistory] = useState([])

    const [verifiedFailures, setVerifiedFailures] = useState([])
    const [unverifiedFailures, setUnverifiedFailures] = useState([])

    //Get lamps on load
    // useEffect(() => {
    //     setLoadingData(true);
    //     if(mapType === "broken") {
    //         getFailures()
    //     }else if(mapType === "lostnode") {
    //         getFailures()
    //     }else {
    //         getFailures()
    //     }

    // }, [mapType, type]) // eslint-disable-line


    //Get statistics on load, and every type change
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await getConsumption();
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [type]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await getFailures();
                await getFailuresHistory();
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [props]);

    function setupLamps(lamps) {
        let result = [];
        lamps.forEach(lamp => {
            let temp = {
                device: {}
            };
            temp.device["id"] = lamp.id
            temp.device["geometry"] = {type:'Point', coordinates:[lamp.info.coords.coordinates[0], lamp.info.coords.coordinates[1]]}
            temp.device["street"] = lamp.info.address ? lamp.info.address.street_address : ""
            temp.device["type"] = lamp.info.model

            result.push(temp)
        });
        setLamps(result);

    }

    async function getConsumption() {
        await ExedraAPIControllerInst.getConsumption(type, period).then((response) => {
            if (response.statusCode === 200) {
                setConsumptionData(response.data)
            }
        })
    }

    async function getFailuresHistory() {
        await ExedraAPIControllerInst.getFailuresHistory(type).then((response) => {
            if (response.statusCode === 200) {
                setFailuresHistory(response.data);
            }
        })
    }

    async function getFailures() {
        if(props?.failures) {
            setVerifiedFailures(props.failures.filter(entry => entry.value === true));
            setUnverifiedFailures(props.failures.filter(entry => entry.value === false));
            }
        setupLamps([])
    }

    function getTotal() {
        const sum = consumptionData.reduce((accumulator, data) => {
            return accumulator + data.activeEnergy;
          }, 0);
          return Math.round(sum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    function getConsumptionData() {
        const sortedData = consumptionData.sort((a, b) => new Date(a.lastDate) - new Date(b.lastDate));

        return {
            labels: sortedData.map((data) => moment(data.lastDate).format("YYYY.MM.DD.")),
            data: sortedData.map((data) => data.activeEnergy),
        }
    }

    return(
        <Row>
            <Col lg={8} className="owlet-details-left">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="owlet-details-chart-container">
                        <div style={{paddingLeft:'20px', fontSize: '20px', fontWeight: '600', paddingBottom: '10px'}}> Teljes fogyasztás:
                            {loading ?
                                <div style={{marginLeft: '10px', marginRight: '10px'}} className="chart-loader"></div>
                            :
                                ` ${getTotal()} `
                            }
                        kWh</div>
                        <Col sm={10} className="tab-content-wrapper">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <LineChart step={3000}
                                        labels={getConsumptionData().labels}
                                        data={getConsumptionData().data}
                                        hide={3} type="owlet" title='Fogyasztás' sum={`Teljes fogyasztás: ${getTotal()} kWh`}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <LineChart step={3000}
                                        labels={getConsumptionData().labels}
                                        data={getConsumptionData().data}
                                        hide={3} type="owlet" title='Fogyasztás' sum={`Teljes fogyasztás: ${getTotal()} kWh`}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <LineChart step={3000}
                                        labels={getConsumptionData().labels}
                                        data={getConsumptionData().data}
                                        hide={3} type="owlet" title='Fogyasztás' sum={`Teljes fogyasztás: ${getTotal()} kWh`}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <LineChart step={3000}
                                        labels={getConsumptionData().labels}
                                        data={getConsumptionData().data}
                                        hide={3} type="owlet" title='Fogyasztás' sum={`Teljes fogyasztás: ${getTotal()} kWh`}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col sm={2} className="tab-buttons-wrapper">
                            {loading &&
                                <div style={{position: 'absolute', left: '-30px', top: '10px'}} className="chart-loader"></div>
                            }
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" onClick={() => {setType(7); setPeriod("Day")}} disabled={loading}>7 nap</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" onClick={() => {setType(30); setPeriod("Day")}} disabled={loading}>30 nap</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third" onClick={() => {setType(90); setPeriod("Week")}} disabled={loading}>Negyedév</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth" onClick={() => {setType(180); setPeriod("Week")}} disabled={loading}>Félév</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className="owlet-details-notifications-container">
                        <Col lg={12}>
                            <div className="owlet-details-notifications">
                                <p className="title">Riasztások</p>
                                <div>
                                    <div className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />
                                        Nem igazolt
                                        {loading &&
                                            <div style={{marginLeft: '10px'}} className="chart-loader"></div>
                                        }
                                    </div>
                                    <div className="data-container">
                                        {unverifiedFailures?.length > 0 ?
                                            <table className="table table-striped table-failures">
                                                <thead>
                                                    <tr>
                                                        <th>Dátum</th>
                                                        <th>ID</th>
                                                        <th>Probléma</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {unverifiedFailures?.map((alert, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td className="date">{new Date(alert.date).toISOString().split('T')[0]}</td>
                                                                <td className="id">{alert.id}</td>
                                                                <td className="name">{alert.name}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        :
                                            <p className="text">Jelenleg nincsenek nem igazolt riasztások</p>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />
                                        Igazolt
                                        {loading &&
                                            <div style={{marginLeft: '10px'}} className="chart-loader"></div>
                                        }
                                    </div>
                                    <div className="data-container">
                                        {verifiedFailures?.length > 0 ?
                                            <table className="table table-striped table-failures">
                                                <thead>
                                                    <tr>
                                                        <th>Dátum</th>
                                                        <th>ID</th>
                                                        <th>Probléma</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {verifiedFailures?.map((alert, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td className="date">{new Date(alert.date).toISOString().split('T')[0]}</td>
                                                                <td className="id">{alert.id}</td>
                                                                <td className="name">{alert.name}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        :
                                            <p className="text">Jelenleg nincsenek igazolt riasztások</p>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="subtitle"><FontAwesomeIcon size="2x" color="#df3030" icon={['fas', 'bell']} />
                                        Riasztási előzmények
                                        {loading &&
                                            <div style={{marginLeft: '10px'}} className="chart-loader"></div>
                                        }
                                    </div>
                                    <div className="data-container">
                                        {failuresHistory?.length > 0 ?
                                            <table className="table table-striped table-failures-history">
                                                <thead>
                                                    <tr>
                                                        <th>Megnyitva</th>
                                                        <th>Lezárva</th>
                                                        <th>Eszköz</th>
                                                        <th>Probléma</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {failuresHistory?.map((alert, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td className="date-open">{new Date(alert.open).toISOString().split('T')[0]}</td>
                                                                <td className="date-close">{new Date(alert.close).toISOString().split('T')[0]}</td>
                                                                <td className="id">{alert.device}</td>
                                                                <td className="name">{alert.failure}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        :
                                            <p className="text">Jelenleg nincsenek riasztási előzmények</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Col>
            <Col lg={4} className="owlet-details-right">
                <Row>
                    <Col>
                        <div className="title"><FontAwesomeIcon size="2x" color="#212529" icon={['fas', 'wrench']} /> Anomáliák</div>
                    </Col>
                </Row>
                <Row>
                    {/* <Col lg={12} className="owlet-map-buttons">
                        <Button onClick={() => setMapType("broken")} className={mapType === "broken" ? 'active' : ''} disabled={loadingData}>Lámpahiba</Button>
                        <Button onClick={() => setMapType("lostnode")} className={mapType === "lostnode" ? 'active' : ''} disabled={loadingData}>Nincs kapcsolat</Button>
                        <Button onClick={() => setMapType("highpower")} className={mapType === "highpower" ? 'active' : ''} disabled={loadingData}>Magas fogyasztás</Button>
                        {loadingData && <div className="loader-container-owlet">
                            <div className="loader" style={{margin:"auto"}}></div>
                        </div>}
                    </Col> */}
                    <Col lg={12} className="owlet-map">
                        {props.active && <OwletMap lamps={lamps} />}
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Button className="owlet-button" href="https://hu-bekescsaba.schreder-exedra.com/login/" >Kezelés az <img alt="owlet" src="/logos/exedra-small.png" /> felületén</Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="owlet-logo">
                        <div className="logo-box">
                            <img alt="owlet" src="/logos/exedra-small.png" />
                            <p>Powered by</p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OwletDetails));
