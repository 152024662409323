//Import interfaces
import {
    getUpdatedTasks,
    defaultResponseInterface
} from "../ts/interfaces/taskupdate_interfaces"
import Config from "../config/config";

export class TaskUpdateController {

    private static instance: TaskUpdateController;


    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): TaskUpdateController {
        if (!TaskUpdateController.instance) {
            TaskUpdateController.instance = new TaskUpdateController();
        }
        return TaskUpdateController.instance;
    }

    public getTasksByProjectId(projectId:any, limit:any, page:any, filters:any, searchWord:string, cadastrefilters:any) {
        let url = process.env.REACT_APP_API_URL + Config.endpoints.list.task.get + "?projectId=" + projectId;
        // let url = `http://localhost:3000/api/task?projectId=${projectId}`;

        if(limit !== null && page !== null) {
            url = url + "&limit=" + limit +"&page=" + page
        }

        if(Object.keys(filters).length) {
            Object.entries(filters).forEach(([key, value]) => {
                url = url + "&filters[" + key + "]=" + value
            })
        }

        if(Object.keys(cadastrefilters).length) {
            for (const [cadastre_id, filterdata] of Object.entries(cadastrefilters)) {
                let obj:any = filterdata
                for (const [key, value] of Object.entries(obj)) {
                    url = url + "&filters[cadastre][" + cadastre_id + "][" + key + "]=" + value
    
                }    
            }
        }

        if(searchWord !== "") {
            url = url + "&filters[search]=" + searchWord
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username,
                    'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`,
                    'Access-Control-Allow-Origin': '*'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then( response => {
                resolve({statusCode:200, data:response})
            }).catch(error => {
                console.log("failed to fetch", error)
            });
        })

    }


    /**
     * Send message to the backend with parameters: 
     * @param projectId string - id of the project to update
     * @param timeStamp Integer - timestamp of the json created, every newer task data should be updated
     */
    public updateTasksByProjectId(projectId:string, timeStamp:string) {

        let data:getUpdatedTasks = {
            topic: "app/erp/" + sessionStorage.username + "/project/getState",
            projectid: projectId,
            type: "all",
            fromTS: timeStamp,
            identity: sessionStorage.username,
            token: sessionStorage.token,
        }

        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.getTaskUpdates;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            }).then(res => {
                return res.json()
            }).then( data => {
                resolve(data);
            }).catch(error => {
                console.log("failed to fetch", error)
            });
        })
    }

    public updateTaskAcceptStatus(userId:string, taskId:string, type:string, checked:boolean) {

        let data = {
            userId,
            taskId,
            type,
            checked
        }

        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.task.update.acceptStatus;
            // let url = `http://localhost:3000/api/task/update/acceptstatus`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username,
                    'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then( response => {
                resolve({statusCode:200, data:response})
            }).catch(error => {
                console.log("failed to fetch", error)
            });
        })
    }

    /**
     * Send message to the backend with parameters: 
     * @param projectId string - id of the project to update
     * @param timeStamp string - timestamp of the json created, every newer task data should be updated
     * @param updateData object - values to be updated, example : {datee:, dateo}
     */
    public updateTask(projectId:string, taskid:string, updateData:object) {

        let data = {
            topic: "app/erp/" + sessionStorage.username + "/project/setTask",
            projectid: projectId,
            data: updateData,
            taskid:taskid,
            identity: sessionStorage.username,
            token: sessionStorage.token
        }

        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.update;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.update === "success") {
                        let resp:defaultResponseInterface = {
                            statusCode:200,
                            data:"Sucess"
                        }
                        
                        resolve(resp)
                    }else {
                        let resp:defaultResponseInterface = {
                            statusCode:500,
                            data:"Server error"
                        }
                        
                        resolve(resp)
                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    /**
     * Send message to the backend with parameters: 
     * @param projectId string - id of the project to update
     * @param timeStamp string - timestamp of the json created, every newer task data should be updated
     * @param updateData Array of objects - objects in a list, the keys are the taskids, the values the updateable values
     */
    public updateTasks(projectId:string, updateData:Array<Object>) {

        let data = {
            topic: "app/erp/" + sessionStorage.username + "/project/setTasks",
            projectid: projectId,
            datas: updateData,
            identity: sessionStorage.username,
            token: sessionStorage.token
            
        }
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.batch;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.update === "success") {
                        let resp:defaultResponseInterface = {
                            statusCode:200,
                            data:"Sucess"
                        }
                        
                        resolve(resp)
                    }else {
                        let resp:defaultResponseInterface = {
                            statusCode:500,
                            data:"Server error"
                        }
                        
                        resolve(resp)
                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    public uploadFileToTask(id:any, files:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.project.upload;
        let formData = new FormData();

        formData.append("id", id);
        formData.append("identity", sessionStorage.username);

        files.forEach((file:any) => {
            formData.append("files[]", file, file.name);
        });

        return new Promise((resolve, reject) => {
            fetch(url, {
                method:"POST",
                body:formData,
            }).then((res) => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:500, data:"Failed to fetch data"})
                }
            }).then(response => {
                resolve({statusCode:200, data:{success:true}});
            })
        })

    }
}
