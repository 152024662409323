import React, {useEffect, useState} from 'react';
import { getMapState,setMapDetailHide,getNavigationState} from '../../../state/reducers/ui';
import connect from 'react-redux/es/connect/connect';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import MoreDetails from './MoreDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreDetailsTable from './MoreDetailsTable'
import { Tabs, Tab } from "react-bootstrap";
import NewProject from '../../../components/Project/NewProject';
import { getUserCadastreData } from '../../../state/reducers/user';
import ExportDataModal from '../../Project/_helper/exportDataModal';
import Config from '../../../config/config';
import StreetViewIframe from './StreetViewIframe';

const classes = (navigationState) => {
    
	let classes='';

	if(navigationState.detail) {
		classes = ' displayed';
	}
	else {
		classes = '';
	}
    
	return classes;

};

//Possibilities: 1 feature, 1 cluster, kijelölt több
function Details(props) {

    const [warning, setWarning] = useState("");
    const [selectedFeatures, setSelectedFeatures] = useState(false);
    const [selectedFeatureCadastreRefs, setSelectedFeatureCadastreRefs] = useState([]);
	const [newProjectModalShow, setNewProjectModalShow] = useState(false);
	const [show, setShow] = useState(false)

	const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);
    const [streetViewAvailable, setStreetViewAvailable] = useState(false);
    const [googleImageUrl, setGoogleImageUrl] = useState('');
	
	useEffect(() => {
		const center = props?.navigationState?.markerdata?.geometryChangeKey_?.target?.flatCoordinates;
		
		if (!center) {
			// console.error('Center coordinates are missing or invalid:', center);
			return;
		}
	
		const [x, y] = center;
		
		const lon = x / 20037508.34 * 180;
		const lat = y / 20037508.34 * 180;
	
		const latitude = 180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180)) - Math.PI / 2);
		const longitude = lon;
	
		setLon(longitude);
		setLat(latitude);
	
		checkStreetViewAvailability(latitude, longitude);
	
	}, [props]);
	


	const checkStreetViewAvailability = async (latitude, longitude) => {
		const imgUrl = `${Config.map.street_view.base_url_maps}?size=400x400&location=${latitude},${longitude}&fov=80&heading=70&pitch=0&key=${Config.map.street_view.google_maps_api_key}`;
	
		try {
			const response = await fetch(imgUrl);
			if (response.ok) {
				const blob = await response.blob();
				const sizeInBytes = blob.size;
				const sizeInKB = sizeInBytes / 1024; // Size in KB
	
				if (sizeInKB > 6) {
					setStreetViewAvailable(true);
					setGoogleImageUrl(imgUrl);
				} else {
					setStreetViewAvailable(false);
				}
			} else {
				console.error('Failed to fetch image:', response.status);
				setStreetViewAvailable(false);
			}
		} catch (error) {
			console.error('Error fetching image:', error);
			setStreetViewAvailable(false);
		}
	};
	
    // useffect to check navigation state change for selected markers
    useEffect(() => {
		let markerdata = props.navigationState.markerdata;
		
        //select with interaction
        if(markerdata.length) {
            setSelectedFeatures(markerdata)
           
			let unique = [...new Set(markerdata.map(x => x.values_.cadastre_id))];
            setSelectedFeatureCadastreRefs(unique)
        }
        //Cluster
        else if(markerdata.values_.hasOwnProperty("features")) {
            setSelectedFeatures(markerdata.values_.features);
            let unique = [...new Set(markerdata.values_.features.map(x => x.values_.cadastre_id))];
            setSelectedFeatureCadastreRefs(unique)
        }

        //One selected
        else {
            setSelectedFeatures([markerdata])
            setSelectedFeatureCadastreRefs([markerdata.values_.cadastre_id]);
        }

    }, [props.navigationState.markerdata]);

	function hideDetailLocal() {
		props.removeSelectedItem(selectedFeatures, null);
		props.hideDetail()
		setWarning("")
		props.clearDraw();
		props.updateFeature(false)
		props.setShowdetails(true)
	}

 	return (
		<div className={(!props.showdetails ? 'minimized ' : '') + 'card detail' + classes(props.navigationState)} style={{width:'480px'}}>
			<div className="card-header align-items-center" ref={props.refDrag}>
				<div className="color" style={{backgroundColor: '#99d7d8'}}></div>
				<div className="header-item">
					<div>{selectedFeatures.length} Kiválaszott elem</div>
					<div><small>Státusz: {null}</small></div>
				</div>
				<div>
					<div title="Export készítése" className="project-sidebar-export sidebar-tooltip">
						<i role="button" onClick={() => setShow(true)}><FontAwesomeIcon size="sm" icon={['fas', 'file-alt']}/></i>
					</div>
				</div>
				{ /* <div className="sidebar-tooltip" onClick={() =>{ setNewProjectModalShow(!newProjectModalShow) }}>
					<a href="#new"><i><FontAwesomeIcon size="1x" color="#4caeef" icon={['fas', 'plus']}/></i></a>
				</div>	*/ }
				<div className="minimize"><FontAwesomeIcon size="1x" color="#bfbfbf" icon={['far', props.showdetails ? 'window-minimize' : 'window-maximize'] } onClick={() => {props.setShowdetails(!props.showdetails)}}/></div>			
				<div onClick={hideDetailLocal} className="close"><Close/></div>
				<div style={{position: "absolute",top: "58px",fontSize: "11px",right: "10px", color:"red"}}>{warning}</div>
			</div>
			{newProjectModalShow && <NewProject show={newProjectModalShow} setShow={setNewProjectModalShow} selectedFeatures={selectedFeatures} highlighted={props.highlighted}/>}

			{(props.showdetails && selectedFeatures )&&
                <div style={{cursor:'default', height: '70%'}} className="card-body">
                    <div className="map-detail-items">
                            {selectedFeatures.length > 1 ?
                                <Tabs defaultActiveKey={selectedFeatureCadastreRefs.length ? selectedFeatureCadastreRefs[0] : "0"} style={{}}>
                                    {selectedFeatureCadastreRefs.filter(x => x !== null).map(cadastre_id => {
                                        return(
                                            <Tab className="cadastre-tab" eventKey={cadastre_id} title={props.cadastreData[cadastre_id].cadastre_label}>
                                                <MoreDetailsTable cadastre_id={cadastre_id} markerdata={selectedFeatures} removeSelectedItem={props.removeSelectedItem} zoomToFeature={props.zoomToFeature}/>
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                            :
                                <MoreDetails googleImageUrl={googleImageUrl} data={selectedFeatures[0]} zoomToFeature={props.zoomToFeature} />
                            }			
                    </div>
                </div>
			}
			{show && <ExportDataModal type='map' show={show} setShow={setShow} selectedFeatures={selectedFeatures} selectedFeatureCadastreRefs={selectedFeatureCadastreRefs} />}
			
			{lat && lon && streetViewAvailable ?
				<StreetViewIframe lat={lat} lon={lon} apiKey={Config.map.street_view.google_maps_api_key} />
			:
				<p style={{textAlign: "center"}}>Nem érhető el utcanézet.</p>
			}
			
			{/* {lat && lon && streetViewAvailable ?
				<div id="street-view-container">
					<img src={`${Config.map.street_view.base_url}?size=400x400&location=${lat},${lon}&fov=80&heading=70&pitch=0&key=${Config.map.street_view.google_maps_api_key}`} alt="Street View" />
				</div>
				:
				<p style={{textAlign: "center"}}>Nem érhető el utcanézet.</p>
			} */}
		</div>
	);
};

const mapDispatchToProps = dispatch => ({
	hideDetail: () => {
		dispatch(setMapDetailHide());
	}
});

const mapStateToProps = state => ({
	cadastreData: getUserCadastreData(state),
	mapState: getMapState(state),
	navigationState: getNavigationState(state),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Details);