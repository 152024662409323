import React, { useState, useEffect } from "react";
import Layer from './Layer';
import connect from 'react-redux/es/connect/connect';
import {getUserCadastreAuth} from "../../../state/reducers/user";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearMapLayerOptionsFilter, getMapLayerOptions } from '../../../state/reducers/ui';
import ActiveCadastreLayerContainer from '../sidebar/ActiveCadastreLayerContainer';

function CadastreLayerConstructor(props) {

    const [openedCadstreGroup, setOpenedCadastreGroup] = useState(props.loadedLayers);
    const [searchWord, setSearchWord] = useState("");
    const [showCadastres, setShowCadastres] = useState(true);
    const [showActiveCadastres, setShowActiveCadastres] = useState(true);

    //Change output according to filter searchword
    useEffect(() => {
        let filteredLength = props.allCadastre.filter(x => x.cadastre_label.toLowerCase().includes(searchWord.toLowerCase()));
        if(filteredLength.length < 3) {
            filteredLength.forEach(x => {
                if(!openedCadstreGroup.includes(x.cadastre_group_ref)) {
                    handleOpen(x.cadastre_group_ref)
                }
            })
        }else if(filteredLength.length > 5) {
            setOpenedCadastreGroup(props.loadedLayers);
        }
    }, [searchWord]) // eslint-disable-line

    function handleOpen(cadastre_group_ref) {
        if(openedCadstreGroup.includes(cadastre_group_ref)){
            setOpenedCadastreGroup(openedCadstreGroup.filter(x => x !== cadastre_group_ref))
        }else {
            setOpenedCadastreGroup([...openedCadstreGroup, cadastre_group_ref])
        }
    }

    function setUpCadstres() {

        let groupRefact = {};

        props.allCadastreGroups.forEach(cadastreGroup => {
            if(cadastreGroup.parent_id) {
                if(!groupRefact.hasOwnProperty(cadastreGroup.parent_id)) {
                    groupRefact[cadastreGroup.parent_id] = {...props.allCadastreGroups.find(x => x.ref === cadastreGroup.parent_id), childGroups:[cadastreGroup]}
                }else {
                    groupRefact[cadastreGroup.parent_id].childGroups.push(cadastreGroup);
                }
            }
        });
        console.log(groupRefact)
        let groups = Object.values(groupRefact).map((mainGroup, index) => { // eslint-disable-line
            console.log('--MAINGROUP:'+mainGroup.label)
            let childIncludesCadastre = mainGroup.childGroups.filter(childGroup => {
                let childCadastres = props.allCadastre.filter(cadastre => cadastre.cadastre_group_ref === childGroup.ref).filter(x => x.cadastre_label.toLowerCase().includes(searchWord.toLowerCase()));
                return childCadastres.length
            });
            console.log('----LENGTH:'+childIncludesCadastre.length)
            let childCadastres = props.allCadastre.filter(cadastre => cadastre.cadastre_group_ref === mainGroup.ref).filter(x => x.cadastre_label.toLowerCase().includes(searchWord.toLowerCase()));
            if(childIncludesCadastre.length || childCadastres.length) {
                return(
                    <div key={index}>
                        <p style={{fontWeight: "600", borderBottom: "1px solid #a3a3a3", marginBottom: "0", padding: "8px 0"}} role="button" onClick={() => handleOpen(mainGroup.ref) }>
                            <FontAwesomeIcon size="1x" role="button" icon={['fas', openedCadstreGroup.includes(mainGroup.ref) ? "chevron-down" : "chevron-right"]}/>
                            <span style={{paddingLeft:"10px"}} >{mainGroup.label}</span>
                        </p>
                        {openedCadstreGroup.includes(mainGroup.ref) && !!childCadastres.length && <div>
                            {childCadastres.map(filteredCadastre => {
                                return <Layer
                                    key={filteredCadastre.cadastre_label}
                                    loadedLayers={props.loadedLayers}
                                    selectedLayer={props.selectedLayer}
                                    loadLayer={props.loadLayer}
                                    cadastre={filteredCadastre}
                                    enableCadastreFilter={props.enableCadastreFilter}
                                />

                            })}
                        </div>}

                        {mainGroup.childGroups.map((childGroup, childIndex) => { // eslint-disable-line
                            console.log('------CHILDGROUP:'+childGroup.label)
                            let cadastres = props.allCadastre
                                .filter(cadastre => cadastre.cadastre_group_ref === childGroup.ref)
                                .filter(x => x.cadastre_label.toLowerCase().includes(searchWord.toLowerCase()));
                            console.log('--------LENGTH:'+cadastres.length)
                            if(cadastres.length) {
                                return(
                                    openedCadstreGroup.includes(mainGroup.ref) &&
                                    <div key={childIndex} style={{padding:0, marginLeft:'15px'}}>
                                        <p style={{fontWeight: "200", borderBottom: "1px solid #e0e0e0", marginBottom: "0", padding: "8px 0"}} role="button" onClick={() => handleOpen(childGroup.ref) }>
                                            <FontAwesomeIcon size="1x" role="button" icon={['fas', openedCadstreGroup.includes(childGroup.ref) ? "chevron-down" : "chevron-right"]}/>
                                        <span style={{paddingLeft:"10px"}} >{childGroup.label}</span>
                                        </p>
                                        {openedCadstreGroup.includes(childGroup.ref) && <div>
                                            {cadastres.map(filteredCadastre => {
                                                return <Layer
                                                        key={filteredCadastre.cadastre_label}
                                                        loadedLayers={props.loadedLayers} 
                                                        selectedLayer={props.selectedLayer} 
                                                        loadLayer={props.loadLayer} 
                                                        cadastre={filteredCadastre} 
                                                        enableCadastreFilter={props.enableCadastreFilter}
                                                        />
    
                                            })}
                                        </div>}
                                    </div>
                            );
                            }
                        })} 
                    </div>
                );
            }
        })
        props.setLoadingCadastreGroups(false)
        return groups;
    }

    return(
        <React.Fragment>
            <div>
                {props.loadedLayers ? <div style={{borderBottom: showActiveCadastres ? "1px solid black" : "none", paddingBottom: "10px", marginBottom:"20px"}} className="activeLayers" >
                    <h6 style={{textAlign:"left", borderBottom: "1px solid black", paddingBottom: "10px", fontWeight:"600"}} onClick={() =>setShowActiveCadastres(!showActiveCadastres)} role="button" ><span><FontAwesomeIcon size="1x" icon={['fas', showActiveCadastres ? "chevron-down" : "chevron-right"]} style={{marginRight:"10px"}}/>
                    </span>Aktív kataszterek</h6>
                    {showActiveCadastres &&
                        <div>
                            <ActiveCadastreLayerContainer
                                allCadastre={props.allCadastre}
                                loadedLayers={props.loadedLayers} 
                                selectedLayer={props.selectedLayer} 
                                loadLayer={props.loadLayer} 
                                active={true}
                                enableCadastreFilter={props.enableCadastreFilter}

                            />
                        </div>
                    }
                    {(!props.loadedLayers.length && !props.layerOptions.loadedDynamicCadastreLayers.map(x => x.cadastre_ref).length) ? <p className="font-italic">Nincsenek aktív layerek.</p> : null}
                </div>:null}
            </div>
            <div className="float-box">
                <form style={{marginBottom:'15px'}} onSubmit={(e) => e.preventDefault()}>
                    <input type="text" name="search" className="small form-control filter-search" placeholder="Réteg keresése" onChange={(e) => {setSearchWord(e.target.value)}} value={searchWord}/>
                </form>
                <button style={{marginBottom:"20px", backgroundColor:props.enableCluster ? "#ff7a7a" : "#63c965", padding:"3px"}} onClick={() => props.setEnableCluster(!props.enableCluster)}>Cluster</button>
            </div>
            <h6 style={{textAlign:"left", borderBottom: "1px solid black", paddingBottom: "10px", marginBottom: "0px", fontWeight:600}} onClick={() => setShowCadastres(!showCadastres)} role="button">
                <span><FontAwesomeIcon size="1x" icon={['fas', showCadastres ? "chevron-down" : "chevron-right"]} style={{marginRight:"10px"}}/>
                </span> 
            Kataszterek</h6>
            {showCadastres && <div className="cadastre-layer-container">
                {props.loadingCadastreGroups ? 
                    <div className="loader-container" style={{marginLeft:'60px'}}>
                        <div className="loader"></div>
                    </div>
                : 
                    setUpCadstres()
                }
            </div>}
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
   cadastreAuth: getUserCadastreAuth(state),
   layerOptions: getMapLayerOptions(state)

});

const mapDispatchToProps = (dispatch) => ({
    clearLayerFilters: () => {
        dispatch(clearMapLayerOptionsFilter());
    },
});

export default connect(
   mapStateToProps,
   mapDispatchToProps,
)(CadastreLayerConstructor);
