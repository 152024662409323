import React, { useState } from 'react';
import Config from '../../config/config';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { useTranslation } from 'react-i18next';
import PharosLogo from "../../assets/logos/pharos-logo-dark.webp";
import styled from 'styled-components';

const StyledInput = styled.input`
&:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${(process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT)} inset !important;
  }
`;

const LoginPharos = ({ setToken }) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);


    async function LoginUser(credentials) {
        setLoading(true);
        const url = process.env.REACT_APP_PHAROS_API_URL;
        setMessage('');
        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        }).then(json => {
            if (json.body.token) {
                setToken(json.body.token);
            } else {
                setMessage('Hiba történt, próbáld újra később.');
            }
            setLoading(false);

        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            setMessage('Hiba történt, próbáld újra később.');
            setLoading(false);
        });
    }
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        await LoginUser({
            username,
            password,
            function: "authenticate"
        })
    }

    return (
        <BrowseLayout>
            <div className="pharos-container">
                <div className="pharos-logo"><img alt="Pharos" src={PharosLogo} /></div>
                <div className="login-container" style={{backgroundColor: `${process.env.REACT_APP_BGD_COLOR}`}}>
                    <h1 className="login-title">Datalight Pharos Login</h1>
                    <form onSubmit={handleSubmit} id="myForm" className="form-data">
                        <StyledInput className="login-input" type="text" placeholder={t('Username')} required="required" onChange={(e) => setUserName(e.target.value)} id="username" />
                        <StyledInput className="login-input" type="password" placeholder={'Jelszó'} required="required" onChange={(e) => setPassword(e.target.value)} id="password" />
                        <div>
                            <button style={{backgroundColor: `${(process.env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR_DEFAULT)}`}}
                                                className="btn-submit" type="submit">
                                {loading ?
                                    <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                :
                                    'Belépés'
                                }
                            </button>
                        </div>
                    </form>
                    <p id="loginResult">{message}</p>
                </div>
            </div>
        </BrowseLayout>
    );
}

export default LoginPharos;
