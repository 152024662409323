import Config from "../config/config";
import Cookies from 'universal-cookie';

export class UserController {

    private static instance: UserController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): UserController {
        if (!UserController.instance) {
            UserController.instance = new UserController();
        }
        return UserController.instance;
    }

    public saveUserFilters(userId:string, data:any) {

        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.user.saveFilters;

        let formData: any = {};

        Object.keys(data).forEach((key:any) => {
            formData[key] = data[key];
        });

        let body = {
            userId: userId,
            settings: formData
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': sessionStorage.username,
                    'Authorization': `Bearer ${process.env.API_AUTHORIZATION_KEY}`,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(body)
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});
                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    public saveSettings(data:any) {

        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.user.saveSettings;

        let body = {
            id:sessionStorage.user_id,
            settings:data
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    public updateUserData(data:any) {
        const url = process.env.REACT_APP_API_URL + Config.endpoints.list.user.update.replace('{id}', sessionStorage.user_id);

        var urlencoded = new URLSearchParams();

        Object.keys(data).forEach((key:any) => {
            urlencoded.append(key, data[key]);
        });

        const cookies = new Cookies();

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'DOLAPIKEY': cookies.get('apikey')
                },
                body:urlencoded
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }
    
};
