import React, { useEffect, useState } from 'react';
import {ForgotPasswordController} from '../controllers/ForgotPasswordController';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { ReactComponent as Logo } from '../assets/images/data_light_logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/images/utu_blue.svg';


export default function Forgot(props) {
    const ForgotPasswordControllerInst = ForgotPasswordController.getInstance();
    const [message, setMessage] = useState("");
    
    const [warning, setWarning] = useState("");
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const position = [46.6774182,21.0926272];
    const center = [46.6774182,21.037];

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    
    const [token, setToken] = useState("");

    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [passwordCriteria, setPasswordCriteria] = useState(
        {
            length: false,
            uppercase: false,
            number: false,
            specialChar: false
        }
    );

    useEffect(() => {
        checkToken(props.match.params.token);
        setToken(props.match.params.token);
    }, [props.match.params.token])


    function checkToken(token) {
        setLoading(true);
        ForgotPasswordControllerInst.checkForgotToken(token).then(response => {
            if(response.statusCode !== 200) {
                setMessage(response.data);
            }
        })
        setLoading(false);
    }

    const handleSaveNewPassword = (e) => {
        e.preventDefault();
        setWarning("");
        setSaveLoading(true);

        if(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) {
            setWarning("A jelszónak meg kell felelnie a kritériumoknak!")
            return
        }

        if(!isPasswordMatch) {
            setWarning("A két jelszónak meg kell egyeznie!")
            return
        }

        ForgotPasswordControllerInst.updatePassword(token, password)
        .then(response => {
            if (response.statusCode === 200) {
                setMessage(response.data.body.message);
            } else {
                setWarning("Hiba a jelszó megváltoztatása során.");
            }
        })
        .catch(error => {
            setWarning("Hiba a jelszó megváltoztatása során.");
        })
        .finally(() => {
            setSaveLoading(false);
        });
    }

    const handlePasswordChange = (value, isConfirmation = false) => {
        if (!isConfirmation) {
            setPassword(value);
        } else {
            setPasswordConfirmation(value);
        }
        setWarning("");
        setIsPasswordMatch(!isConfirmation ? value === passwordConfirmation : password === value);
        checkPasswordCriterias(value);
    };
    

    
    const checkPasswordCriterias = (value) => {
        const criterias = {
            length: value.length >= 9,
            uppercase: /[A-Z]/.test(value),
            number: /\d/.test(value),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
        }
        setPasswordCriteria(criterias)
    };


    return (
        <div className="forgot-page">
            <div className="map-border">
                <div className="content"></div>
            </div>
            <div className="map-overlay">
                <div className="content"></div>
            </div>
            <div className="map">
                <Map center={center} zoom="14">
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                    />
                    <Marker position={position}>
                    </Marker>
                </Map>
            </div>
            <div id="forgot">
                <div className="container">
                    <div className="row">
                        <div className="logo">
                            <Logo />
                        </div>
                        <div className="text-center">
                            <div className="card text-left">
                                <div className="card-title">Új jelszó</div>
                                <div className="card-body">
                                
                                {!message ?
                                    
                                    <form onSubmit={(e) => {
                                        handleSaveNewPassword(e);
                                    }}>
                                        <div className={`form-group ${loading ? 'text-center' : ''}`}>
                                            {loading ?
                                                <div className="forgot-lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                            : <>
                                            <input type={showPassword ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                className="form-control"
                                                placeholder={'Jelszó'}
                                                onFocus={(e) => {
                                                    handlePasswordChange(e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    handlePasswordChange(e.target.value);
                                                }}
                                                onChange={(e) => {
                                                    handlePasswordChange(e.target.value);
                                                }}
                                            />
                                            <FontAwesomeIcon title="Jelszó megjelenítése" role="button" onClick={() => setShowPassword(!showPassword)} className="show-pass" size="1x" icon={['fas', showPassword ? 'eye-slash' : 'eye'] }/>
                                            </>}
                                        </div>
                                        <div className="form-group">
                                            {!loading &&
                                            <>
                                            <input type={showConfirmPassword ? "text" : "password"}
                                                id="password-confirm"
                                                name="password-confirm"
                                                className="form-control"
                                                placeholder={'Jelszó megerősítése'} 
                                                
                                                onFocus={(e) => {
                                                    handlePasswordChange(e.target.value, true);
                                                }}
                                                onBlur={(e) => {
                                                    handlePasswordChange(e.target.value, true);
                                                }}
                                                onChange={(e) => {
                                                    handlePasswordChange(e.target.value, true);
                                                }}
                                            />
                                            <FontAwesomeIcon title="Jelszó megjelenítése" role="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="show-pass" size="1x" icon={['fas', showConfirmPassword ? 'eye-slash' : 'eye'] }/>
                                            </>}
                                        </div>
                                        {!loading && <>
                                        {(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) &&
                                        <div className="criterias">
                                            <div className="criterias-title">Az jelszavadnak tartalmaznia kell:</div>
                                            <ul>
                                                {!passwordCriteria.length && <li>legalább <span>9 karaktert</span></li>}
                                                {!passwordCriteria.uppercase && <li>kis- és nagybetűt</li>}
                                                {(!passwordCriteria.number || !passwordCriteria.specialChar) && <li>minimum egy számot és egy speciális karaktert</li>}
                                            </ul>
                                        </div>
                                        }
                                        </>}

                                        {warning && <span className="warning">{warning}</span>}
                                        {!loading &&
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-block" >{saveLoading ? <div className="forgot-lds-ellipsis"><div></div><div></div><div></div><div></div></div> : 'Jelszó mentése'} </button>
                                        </div>
                                        }
                                        
                                    </form>
                                    : <span className="message">{message}</span>}
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>

                <div className="bottom-logos">
                    <a target="_blank" rel="noopener noreferrer" href={'https://udg.hu/'}>
                        <img src={logo} alt="Logo" />
                    </a>
                </div>
            </div>
        </div>
    );
};