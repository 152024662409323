import Config from "../config/config"
import moment from 'moment';

export class ExedraAPIController {
    private static instance: ExedraAPIController

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): ExedraAPIController {
        if (!ExedraAPIController.instance) {
            ExedraAPIController.instance = new ExedraAPIController()
        }
        return ExedraAPIController.instance
    }

    public getFullConsumption() {
        const today = new Date().toISOString();
        const startDay = moment(0).toISOString();
        const url = `${process.env.REACT_APP_EXEDRA_API_URL}${Config.endpoints.exedraAPIEndpoints.consumption}?from=${startDay}&to=${today}&period=Year`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.error) {
                    resolve({ statusCode: 401, data: data.error.message });
                } else {
                    resolve({ statusCode: 200, data: data });
                }
            })
            .catch((error) => {
                console.log("failed to fetch consumption", error);
                reject(error);
            });
        });
    }

    public getYesterdayConsumption() {
        const today = moment();
        const startDay = today.clone().subtract(1, 'days').startOf('day').toISOString();
        const endDay = today.clone().subtract(1, 'days').endOf('day').toISOString();
        const url = `${process.env.REACT_APP_EXEDRA_API_URL}${Config.endpoints.exedraAPIEndpoints.consumption}?from=${startDay}&to=${endDay}`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    resolve({ statusCode: 401, data: data.error.message });
                } else {
                    resolve({ statusCode: 200, data: data });
                }
            })
            .catch((error) => {
                console.log("failed to fetch consumption", error);
                reject(error);
            });
        });
    }

    public getConsumption(limit: number, period: "Day" | "Week" | "Month") {
	const today = new Date().toISOString()
        const startDay = moment().subtract(limit,'d').toISOString()
        const url = `${process.env.REACT_APP_EXEDRA_API_URL}${Config.endpoints.exedraAPIEndpoints.consumption}?from=${startDay}&to=${today}&limit=${limit}&period=${period}`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.error) {
                        resolve({ statusCode: 401, data: data.error.message })
                    } else {
                        resolve({ statusCode: 200, data: data })
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch consumption", error)
                })
        })
    }

    // public getFailures(limit: number) {
    public getFailures() {
        const today = moment();
        const start = today.clone().startOf('day').toISOString();
        const end = today.clone().endOf('day').toISOString();
        const url = `${process.env.REACT_APP_EXEDRA_API_URL}${Config.endpoints.exedraAPIEndpoints.failures}?start=${start}&end=${end}`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.error) {
                        resolve({ statusCode: 401, data: data.error.message })
                    } else {
                        resolve({ statusCode: 200, data: data })
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch failures", error)
                })
        })
    }

    public getFailuresHistory(limit: number) {

        const end = new Date().toISOString();
        const start = moment().subtract(limit, 'd').toISOString();
        const url = `${process.env.REACT_APP_EXEDRA_API_URL}${Config.endpoints.exedraAPIEndpoints.failureshistory}?start=${start}&end=${end}`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    if (data.error) {
                        resolve({ statusCode: 401, data: data.error.message })
                    } else {
                        resolve({ statusCode: 200, data: data })
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch failures history", error)
                })
        })
    }
}
