import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { getUserDetails } from '../../../state/reducers/user';
import { TaskUpdateController } from '../../../controllers/TaskUpdateController';

const AcceptStatuses = ( props ) => {

    const [checked, setChecked] = useState(false);
    const [taskId, setTaskId] = useState();
    const [userId, setUserId] = useState();
    const [type, setType] = useState('');
	const TaskUpdateControllerInst = TaskUpdateController.getInstance();

	useEffect(() => {
		if(props?.userDetails.length > 0) {
			setUserId(props?.userDetails[0].id)
		}
		setTaskId(props?.row?.uuid);
		setChecked(props?.checked ? true : false);
		if(props.type) {
			setType(props.type);
		}
    }, [props])

	function updateAcceptStatus() {
		TaskUpdateControllerInst.updateTaskAcceptStatus(userId, taskId, type, checked).then(response => {
			if(response.statusCode === 200){
				setChecked(!checked);
			}
		});
	}

    const Icon = ({ checked }) => {
			if(checked) {
				return <FontAwesomeIcon className="checker" style={{cursor: "pointer"}} size="1x" color="#2CD02A" icon={['far', 'check-square'] }/>
			} else {
				return <FontAwesomeIcon className="checker" style={{cursor: "pointer"}} size="1x" color="#d5d5d5" icon={['far', 'square'] }/>
			}
	};

    return (
    	<div onClick={() => updateAcceptStatus()}><Icon checked={checked} /></div>
    );
};

const mapStateToProps = state => ({
	userDetails: getUserDetails(state)
});

export default connect(
    mapStateToProps,
)(AcceptStatuses);