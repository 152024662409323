//Import interfaces
import { forgotPasswordObject, checkForgotPasswordObject, updatePasswordObject } from "../ts/interfaces/forgotpasswordcontroller_interfaces"
import Config from "../config/config";


export class ForgotPasswordController {

    private static instance: ForgotPasswordController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): ForgotPasswordController {
        if (!ForgotPasswordController.instance) {
            ForgotPasswordController.instance = new ForgotPasswordController();
        }
        return ForgotPasswordController.instance;
    }


    /**
     * Send message to the backend with parameters: 
     * @param username
     * @param token
     * @param password
     */
    public forgot(username:string) {

        let data:forgotPasswordObject = {
            username
        }

        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.forgot;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                return res.json()
            }).then( data => {
                if(data.error) {
                    resolve({statusCode:401, data:data.error});
                }
                else {
                    resolve({statusCode:200, data:data});

                }
            }).catch(error => {
                console.log("failed to fetch", error)
            });
        })
    }

    public checkForgotToken(token:string) {
        
        let data:checkForgotPasswordObject = {
            token
        }
        
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.check;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                return res.json()
            }).then( data => {
                if(data.error) {
                    resolve({statusCode:401, data:data.error});
                }
                else {
                    resolve({statusCode:200, data:data});

                }
            }).catch(error => {
                console.log("failed to fetch", error)
            });
        })
    }


    public updatePassword(token:string, password:string) {
        
        let data:updatePasswordObject = {
            token,
            password
        }
        
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + Config.endpoints.list.updatePassword;

            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                return res.json()
            }).then( data => {
                if(data.error) {
                    resolve({statusCode:401, data:data.error});
                }
                else {
                    resolve({statusCode:200, data:data});

                }
            }).catch(error => {
                console.log("failed to fetch", error)
            });
        })
    }
    
};
