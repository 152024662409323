import React, { useEffect, useState, useRef} from 'react';
import { values } from "lodash/fp";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import VectorLayer from 'ol/layer/Vector';
import {Cluster} from 'ol/source';
import { omit } from 'lodash/fp';
import {Text} from "ol/style";
import { extend, createEmpty } from 'ol/extent';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import OlSourceXYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';

import 'ol/ol.css';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import MapButton from './ui/MapButton';
import * as olProj from 'ol/proj';
import Collection from 'ol/Collection';
import Translate from 'ol/interaction/Translate';

import { Col, Row } from "react-bootstrap";

import Draw from 'ol/interaction/Draw';

import GeoJSON from 'ol/format/GeoJSON';
import {fromLonLat} from 'ol/proj';

import connect from 'react-redux/es/connect/connect';
import {
	getNavigationState,
	setMapWidth,
	setMapDetailHide,
	setMapDetail,
	toggleView,
	startMapSearch,
	setLayer,
	setMapInteraction,
	getMapLayerOptions,
	setLoadingData,
	setLoadingMessage,
	getLoadingData,
	setLoadedProjectLayers,
	setLoadedCadastreLayers,
	getActiveProject,
	setLayerLoading, 
	setActiveTileLayers,
	getActiveTileLayers
} from '../../state/reducers/ui';

import {defaults as defaultInteractions, DragRotateAndZoom} from 'ol/interaction';

import { getNumberStyler } from '../../helpers/common';

import Config from '../../config/config';
import CircleStyle from 'ol/style/Circle';
import ProjectSidebar from "../Project/ProjectSidebar";

import {clearTooltip, createMeasureTooltip, initMeasure, drawStylefunction } from './_helpers/measure-distance';
//import { getOwletData } from './_helpers/owletData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PointOfInterestModal from './ui/PointOfInterestModal';

import {downloadMap} from '../../helpers/mapImageDownload';
import {getUserCadastreData} from '../../state/reducers/user';

import { getCadastreLayerStyle, dynamicCadastreStyleFunction, arrowStyleFunction, TitleLayerStyleFunction } from './_styles/styles'
import { KataszterController } from '../../controllers/KataszterController';
import { TaskUpdateController } from '../../controllers/TaskUpdateController';

import MapInteractions from './ui/MapInteractions';
import Overlay from 'ol/Overlay';

//imports for dragging
import { Box } from './_helpers/dragable_details_components/Box';
import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import update from 'immutability-helper';
import Geocoder from 'ol-geocoder';
import { OpenStreet } from '../../controllers/MapSearchProvider';
import { MapController } from '../../controllers/MapController';
// import {forEach} from "lodash";
import Toastify from "toastify-js";

const provider = new OpenStreet();

function MapComponent(props) {

	let geocoder = new Geocoder('nominatim', {
		provider,
		lang: 'hu',
		placeholder: 'Hely keresése...',
		limit: 5,
		debug: false,
		autoComplete: true,
		keepOpen: true,
		countrycodes: 'hu',
		preventDefault: true,
		// ,
		// preventPanning: true
	});

	const [selectedCity, setSelectedCity] = useState(null);

	const handleCityChange = (event) => {
		provider.setCity(event.target.value)
		setSelectedCity(event.target.value);

		const city = allCities.find(item => (item.name === event.target.value))
		if(city){
			olmap.getView().setCenter(fromLonLat(city.geography.coordinates))
			olmap.getView().setZoom(13);
		}
	};

	const [zoom, setZoom] = useState(Config.map.zoom);
	const [canDraw, _setCanDraw] = useState(false);
	const [olmap, setMap] = useState();
	const [layers, setLayers] = useState({});
	const [tileLayers, setTileLayers] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [highlighted, setHighLighted] = useState([]);
	const [canEditInteraction, _setCanEditInteraction] = useState(false);
	const [loadingTasks, setLoadingTasks] = useState(false);
	const [loadedTasks, setLoadedTasks] = useState(0);
	const [loadedCadastreDevices, setLoadedCadastreDevices] = useState(0);
	const [editInteractionFeature, setEditInteractionFeature] = useState(false);
	const [owletData, setOwletData] = useState({broken:[], lost:[], power:[]});

	//Stores for active layers, projects on Map
	const [loadedProjects, setLoadedProjects] = useState([]);
	const [activeLayers, setActiveLayers] = useState([]);
	const [activeDynamicCadastres, setActiveDynamicCadastres] = useState(false);
	const [projectCluster, _setProjectCluster] = useState(false);
	
	// const [poiLayers, setPoiLayers] = useState([]);

	function setProjectCluster() {
		_setProjectCluster(!projectCluster)
	}

	//State for poi handling
	const [showPOIModal, setShowPOIModal] = useState(false);
	const [POIcoords, setPOIcoords] = useState([]);
	const [canPlacePOI, _setCanPlacePOI] = useState(false);

	//cluster cadastres
	const [enableCluster, setEnableCluster] = useState(false);

    const CadastreConrollerInst = KataszterController.getInstance();
	const TaskUpdateControllerInst = TaskUpdateController.getInstance();
	const MapControllerInst = MapController.getInstance();

	//write text on screen
	const [text, setText] = useState({position:[], text:""});
	const [writeText, setWriteText] = useState(false);
	const writeTextRef = useRef(writeText);

	useEffect(() => {
		writeTextRef.current = writeText;
	}, [writeText]);

	//popup for text
	const popup = useRef();
	const [overlay, setOverlay] = useState({});

	const canPlacePOIRef = React.useRef(canPlacePOI);
	const setCanPlacePOI = data => {
		canPlacePOIRef.current = data;
		_setCanPlacePOI(data);
	};

	//Ref for event hander - Inside listener always the init render valid
	const canDrawRef = React.useRef(canDraw);
	const setCanDraw = data => {
		canDrawRef.current = data;
		_setCanDraw(data);
	};

	const canEditInteractionRef = React.useRef(canEditInteraction);
	const setCanEditInteraction = data => {
		canEditInteractionRef.current = data;
		_setCanEditInteraction(data);
	};

	const activeLayersRef = useRef(activeLayers);
	useEffect(() => {
		activeLayersRef.current = activeLayers;
	}, [activeLayers]);

	const layersRef = useRef(layers);
	useEffect(() => {
		layersRef.current = layers;
	}, [layers]);

	const highlightedRef = useRef(highlighted);
	useEffect(() => {
		highlightedRef.current = highlighted;
	}, [highlighted]);

	const loadedProjectsRef = useRef(loadedProjects);
	useEffect(() => {
		loadedProjectsRef.current = loadedProjects;
	}, [loadedProjects]);

	const activeDynamicCadastresRef = useRef(activeDynamicCadastres);
	useEffect(() => {
		activeDynamicCadastresRef.current = activeDynamicCadastres;
	}, [activeDynamicCadastres]);

	useEffect( () => () => {
		props.setMapDetailHide();
	},[]); // eslint-disable-line

	const center = fromLonLat(Config.map.center);
	// let height = window.innerHeight - 118;
	const [height, setHeight] = useState(window?.innerHeight - 118);

	useEffect( () => {
		if(window?.innerHeight) {
			setHeight(window.innerHeight - 118)
		}
	},[window]);

	//setup map after page load
	useEffect(() => {
		props.toggleView('map');
		const tileLayers = {};

		props.tileConfig.forEach((tile) => {
			const url = tile.ref === 'tile_default' ? (props.mapState.type === 'project' ? tile.project_url : tile.url) : tile.url;
			tileLayers[tile.ref] = new OlLayerTile({
				source: new OlSourceXYZ({ url, crossOrigin: 'Anonymous'}),
				visible: false,
				zIndex:tile.order/10
			});
			tileLayers[tile.ref].set('id', tile.ref);
			tileLayers[tile.ref].tile = tile;
		} );

		//Overlay for text input from user
		let overlay = new Overlay({
			element: popup.current,
			autoPan: true,
			autoPanAnimation: {
			duration: 250,
			},
		});
		setOverlay(overlay);

		const olmap = new OlMap({
			interactions: defaultInteractions().extend([
				new DragRotateAndZoom()
			]),
			target: null,
			layers: values(tileLayers),
			overlays: [overlay],
			renderer: (['webgl', 'canvas']),
			view: new OlView({
				center: center,
				zoom: zoom
			}),
		});
		window.onresize = function()
		{
			setHeight(window.innerHeight - 118)
		}
		setLayers((prevState) => ({ ...prevState, ...tileLayers}));
		setTileLayers(tileLayers);
		setMap(olmap);

		olmap.on('singleclick', function (evt) {
			if(writeTextRef.current) {
				// let coords = olProj.toLonLat(evt.coordinate);
				overlay.setPosition(evt.coordinate);
				let coords = olProj.toLonLat(evt.coordinate);
				setText({...text, position:coords})

			}
		});
		setLoaded(true);
	}, []) // eslint-disable-line

	//update projects after filter
	useEffect(() => {
		if(loaded) {
			loadProject();
		}
	}, [ // eslint-disable-line
		props.navigationState.query.term,
		props.navigationState.query.searchFilters,
		props.layerOptions.searchFilters
	]); // eslint-disable-line

	//event listener to stop drawing
	let stopDrawing = function(evt){
		const charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode === 27 && (canDrawRef.current || canPlacePOIRef.current)){
			resetInteractions();
			clearMapInteractionsParent();
		}
	};

	function handleViewBox(map) {
		const view = map.getView();
		const extent = view.calculateExtent(map.getSize());

		const transformedExtent = olProj.transformExtent(extent, view.getProjection(), 'EPSG:4326');

		const [minX, minY, maxX, maxY] = transformedExtent;
		const viewBox = [maxX, maxY, minX, minY];

		provider.setViewBox(viewBox)
	}

	//load stuff on map after init happend
	useEffect( () => {
		if(olmap !== undefined) {
			init();
			initDraw();
			
			//get multiple api calls inside
			async function getOwlet() {
				//let lampsWithProblem = await getOwletData();
				//setOwletData(lampsWithProblem);
			}
	
			getOwlet();
			toggleView("map");

			olmap.setTarget("inner-map");

			olmap.on('moveend', function (evt) {
			  	setZoom(olmap.getView().getZoom());

				handleViewBox(olmap);

		});


			if(loaded) {
				olmap.addControl(geocoder);
	
				geocoder.on('addresschosen', function (evt) {
					olmap.getView().fit(evt.bbox, {size:olmap.getSize(), maxZoom:18});
					
					removePoi();
					const newPoi = createPoi([evt.place.lon, evt.place.lat])
					// setPoiLayers(prevState => [...prevState, newPoi]);
				});
			}

			// Listen to map changes
			if(props.layerOptions.loadedProjectLayers.length) {
				setLoadedProjects(props.layerOptions.loadedProjectLayers)
			}

			if(props.layerOptions.loadedCadastreLayers.length) {
				props.layerOptions.loadedCadastreLayers.forEach(cadastre => {
					loadLayer(cadastre.name, false, true, cadastre.cadastre_id);
				});
			}

			if(props.layerOptions.loadedDynamicCadastreLayers.length) {
				loadDynamicCadastres();
			}

			if(!props.layerOptions.loadedProjectLayers.includes(props.activeProject) && props.navigationState.isFiltering) {
				loadProject();
			}

			const defaultLayers = props.tileConfig.filter(tile => (tile.default_on === 'true') )
			defaultLayers.forEach(tile => {
				loadTileLayer(tile.ref, true);
			})

		}
	}, [olmap, loaded]) // eslint-disable-line


	//load saved layers from redux
	useEffect(() => {
		if(olmap !== undefined && loaded) {
			if(props.layerOptions.loadedProjectLayers.length) {
					setLoadedProjects(props.layerOptions.loadedProjectLayers)
			}

			if(props.layerOptions.loadedCadastreLayers.length) {
				props.layerOptions.loadedCadastreLayers.forEach(cadastre => {
					if(!activeLayersRef.current.includes(cadastre.name)) {
						loadLayer(cadastre.name, false, true, cadastre.cadastre_id);
					}
				});
			}
		
		}
	}, [props.layerOptions.loadedProjectLayers, props.layerOptions.loadedCadastreLayers]); // eslint-disable-line


	//check for dynamic layer data change, and load accordingly
	useEffect(() => {
		if(loaded) {
			if(props.layerOptions.loadedDynamicCadastreLayers.length) {
				loadDynamicCadastres();
			}else {
				let lay = []
				olmap.getLayers().forEach(function(el) {
					if (el.get('id') === "dynamic_point") {
						lay.push(el)
					}
					if(el.get('id') === "dynamic_poly") {
						lay.push(el)
					}
				});
				lay.forEach(el => {
					olmap.removeLayer(el);
				})

				setActiveDynamicCadastres(false);
			}
		}
	}, [props.layerOptions.loadedDynamicCadastreLayers, props.layerOptions.searchFilters, enableCluster]); // eslint-disable-line


	//todo this is bad coding - For updating the layer zindex from stylefunction.
	useEffect(() => {
		if(loaded) {
			olmap.getView().setZoom(olmap.getView().getZoom() - 0.00005)
		}
	}, [props.layerOptions.allLoadedLayers]) // eslint-disable-line

	useEffect(() => {
		if(loaded) {
			loadProject();
			props.setLoadedProjectLayers(loadedProjects);
		}
	}, [loadedProjects, projectCluster]) // eslint-disable-line

	useEffect(() => {
		if(props.navigationState.isFiltering) {
			if(!props.layerOptions.loadedProjectLayers.includes(props.activeProject)) {
				setLoadedProjects(prevState => [...prevState, props.activeProject]);
			}
		}
	}, []) // eslint-disable-line

	function init() {
		olmap.on('click', function(evt){

			const feature = olmap.forEachFeatureAtPixel(evt.pixel, function(feature) {
				return feature;
			});

			if(!canDrawRef.current) {
				if(canPlacePOIRef.current && feature === undefined) {
					let coords = olProj.toLonLat(evt.coordinate);

					setPOIcoords(coords);
					setShowPOIModal(true);
				}
				else if(feature && feature.geometryName_ !== 'select') {
					props.displayDetail(feature)
					updateFeature(feature)
				}
			}

			if(canEditInteractionRef.current) {
				setEditInteractionFeature(feature);
			}

		});
	}

	function findFeaturesById(ids) {
		olmap.getLayers().forEach(function(el) {
			if (el.get('id') === 3) {
				el.getFeatures().filter(feature => {
					return ids.includes(feature.values_.fa_id)
				})
			}
		})
	}

	function removeSelectedItem(features, id) {
		let foundFeatures = Array.isArray(features) ? features : [features]

		const reduced = foundFeatures.filter(feature => feature.ol_uid !== id);
		props.setMapDetail(reduced);

		updateFeature(reduced);
	}

	function findFeatures(box){

		let loadedFeatures = [];

		olmap.getLayers().forEach(function(el) {
			if (el.get('id') === "projects") {
				loadedFeatures = [...loadedFeatures, ...el.getSource().getFeatures()]
			}
		});

		if(activeDynamicCadastresRef.current) {
			olmap.getLayers().forEach(function(el) {
				if (el.get('id') === "dynamic_point") {
					loadedFeatures = [...loadedFeatures, ...el.getSource().getFeatures()]
				}
				if (el.get('id') === "dynamic_poly") {
					loadedFeatures = [...loadedFeatures, ...el.getSource().getFeatures()]
				}
			})
		}

		let mergedFeatures = []
		loadedFeatures.forEach(feature => {
			if(feature.values_.hasOwnProperty("features")) {
				mergedFeatures = [...mergedFeatures, ...feature.values_.features]
			}else {
				mergedFeatures.push(feature);
			}
		});

		let result = []
		mergedFeatures.forEach(feature => {
			var polygonGeometry = box.getGeometry();
			var coords = feature.getGeometry();
			if(coords !== null) {
				if(polygonGeometry.intersectsCoordinate(coords.getCoordinates())) {
					result.push(feature);
				}
			}

		})
		return result;
	}

	function initDraw() {

		let temp = {};
		temp.draw = {};
		temp.reduce = {};

		temp.draw.source = new VectorSource({
			wrapX: false
		});
		temp.draw.reduceSource = new VectorSource({
			wrapX: false
		});

		temp.draw.measureSource = new VectorSource({
			wrapX: false
		});

		temp.draw.freehandSource = new VectorSource({
			wrapX: false
		});

		temp.draw.freehandDrawingSource = new VectorSource({
			wrapX: false
		});

		temp.draw.interaction = new Draw({
			source: temp.draw.source,
			type: 'Polygon',
			geometryName: 'select',
			zIndex:20
		});

		temp.draw.reduce = new Draw({
			source: temp.draw.reduceSource,
			type: 'Polygon',
			geometryName: 'reduce',
			zIndex:20,
			style: new Style({
				fill: new Fill({
				  color: 'rgba(255, 255, 255, 0.2)'
				}),
				stroke: new Stroke({
				  color: '#fa8787',
				  width: 2
				}),
				image: new CircleStyle({
				  radius: 7,
				  fill: new Fill({
					color: '#fa8787'
				  })
				})
			})
		});


		temp.draw.layer = new VectorLayer({
			source: temp.draw.source,
			name: 'exportPoly',
			zIndex:20
		});

		temp.draw.arrowSource = new VectorSource({wrapX: false});
		temp.draw.arrow = new VectorLayer({
			source: temp.draw.arrowSource,
			name: 'arrow',
			style: arrowStyleFunction,
			zIndex:20
		});


		temp.draw.freehand = new VectorLayer({
			source: temp.draw.freehandSource,
			name: 'freehand',
			zIndex:20
		});

		temp.draw.freehandDrawing = new VectorLayer({
			source: temp.draw.freehandDrawingSource,
			name: 'freehand-drawing',
			zIndex:20
		});

		temp.draw.reduceLayer = new VectorLayer({
			source: temp.draw.reduceSource,
			name: 'exportPoly',
			zIndex:20,
			style: new Style({
				fill: new Fill({
				  color: 'rgba(255, 255, 255, 0.2)'
				}),
				stroke: new Stroke({
				  color: '#fa8787',
				  width: 2
				}),
				image: new CircleStyle({
				  radius: 7,
				  fill: new Fill({
					color: '#fa8787'
				  })
				}),
			  })
		});

		temp.draw.measureLayer = new VectorLayer({
			source: temp.draw.measureSource,
			style: drawStylefunction,
			zIndex:20
		});

		//setup text layer
		temp.textSource = new VectorSource({crossOrigin: 'Anonymous'});
		temp.text = new VectorLayer({
			source: temp.textSource,
			name:'text',
			zIndex:20
		});
		temp.text.set('id', 'text');

		//todo ew polygon created duplicate code below and above. This solution not lookiiiing good.
		//Problem : After drawing, the printed polygon gets the layers Style -> the reduce will be the same as the add.
		temp.draw.reduce.on('drawend',function(evt){
			const foundFeatures = findFeatures(evt.feature);

			if(foundFeatures.length) {
				let result = highlightedRef.current.filter(x => {
					return !foundFeatures.includes(x)
				});

				updateFeature(result);
				if(result !== null) {
					if(result.length) {
						props.displayDetail(result);
					}
				}
			}
		});

		temp.draw.interaction.on('drawend',function(evt){
			handleDrawing(evt);

		});
		olmap.addLayer(temp.text);
		olmap.addLayer(temp.draw.layer);
		olmap.addLayer(temp.draw.reduceLayer);
		olmap.addLayer(temp.draw.measureLayer);
		olmap.addLayer(temp.draw.freehand);
		olmap.addLayer(temp.draw.freehandDrawing);
		olmap.addLayer(temp.draw.arrow);
		setLayers((prevState) => ({ ...prevState, ...temp}));
	}

	function handleDrawing(evt) {

		//For future
		// var geom = evt.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');

		const foundFeatures = findFeatures(evt.feature);
		highlightedRef.current.forEach(x => {
			if(x.hasOwnProperty("properties")) {
				x.properties.highlight = false
			}
		});

		if(foundFeatures.length) {
			updateFeature(foundFeatures);
		}

		if(foundFeatures.length > 0) {
			props.displayDetail(foundFeatures);
		}

	}

	function setDraw(type) {

		clearMapInteractionsParent();

		if(!canDraw) {
			setCanDraw(true);
		}

		if(canDraw) {
			olmap.getInteractions().forEach((interaction) => {
				if (interaction instanceof Draw) {
				  if(type !== interaction.geometryName_) {
					clearInteractions();
					olmap.addInteraction(type === "reduce" ? layers.draw.reduce : layers.draw.interaction);

				  }
				}
			});
		}  else {
			olmap.addInteraction(type === "reduce" ? layers.draw.reduce : layers.draw.interaction);

		}
	}

	function clearDraw() {
		layers.draw.source.clear();
		props.setMapDetailHide();
		layers.draw.reduceSource.clear();
		layers.draw.measureSource.clear();
		clearTooltip(olmap);
	}

	function clearInteractions() {
		olmap.removeInteraction(layers.draw.measure);
		olmap.removeInteraction(layers.draw.interaction);
		olmap.removeInteraction(layers.draw.reduce);
	}

	function resetInteractions() {
		if(layers.draw && olmap) {
			olmap.removeInteraction(layers.draw.measure);
			olmap.removeInteraction(layers.draw.interaction);
			olmap.removeInteraction(layers.draw.reduce);
			clearTooltip(olmap);
			setCanPlacePOI(false);
			setCanDraw(false);
			props.setInteraction("");
		}
	}

	function setMeasure(type) {
		clearInteractions();
		clearMapInteractionsParent();

		if(!canDraw) {
			setCanDraw(true);
		}

		if(type === "area") {
			initMeasure("Polygon", layers, olmap);
		}else if (type === "Square"){
			initMeasure("Square", layers, olmap);
		}else {
			initMeasure("LineString", layers, olmap);
		}

		if(canDraw) {
			clearInteractions();
			olmap.addInteraction(layers.draw.measure);
			createMeasureTooltip(olmap);
		}
		else {
			olmap.addInteraction(layers.draw.measure);
			createMeasureTooltip(olmap);

		}
	}

	function updateFeature(features) {
		highlightedRef.current.forEach(x => {
			if(x.properties) {
				x.properties.highlighted = false;
			}
		});
		let featuresToHighlight;

		if(features.length) {
			featuresToHighlight = features;
			setHighLighted(features);

		}else if(features.values_){
			if(features.values_.hasOwnProperty('features')) {
				featuresToHighlight = features.values_.features
				setHighLighted(features.values_.features);
			}
			else {
				featuresToHighlight = [features];
				setHighLighted([features]);
			}
		}else {
			featuresToHighlight = [features];
			setHighLighted([features]);
		}

		featuresToHighlight.forEach(x => {
			if(x.hasOwnProperty('properties')) {
				x.properties.highlighted = true;
			}
		});

		featuresToHighlight.filter(x => x.hasOwnProperty("properties")).forEach(x => x.properties.highlighted = true);
		//force update on map to load selected stuff
		olmap.getView().setZoom(olmap.getView().getZoom() - 0.00005)

	}

	function zoomToFeature(feature) {
		olmap.getView().fit(feature.getGeometry().getExtent(), {size:olmap.getSize(), maxZoom:17});

	}

	function clusterFunction(feature) {
		if(feature.getGeometry().getType() === 'Point'){
			return feature.getGeometry();
		}else {
			return null
		}
	}

	function loadDynamicCadastres() {
		console.log('LOAD');
		let modifiedFilters = [];
		props.setLayerLoading(true);
		//Refactor filters to work with controller
		Object.keys(props.layerOptions.searchFilters).forEach(key => {
			Object.entries(props.layerOptions.searchFilters[key]).forEach(([filterKey, filterValue] )=> {
				modifiedFilters.push({type:"cadastre", id:key, name:filterKey, value:filterValue, label:""})

			})
		});

		let activeCadastres = props.layerOptions.loadedDynamicCadastreLayers.map(x => x.cadastre_id);
		CadastreConrollerInst.getCadastreSpots(activeCadastres, false, false, modifiedFilters, true, {}).then(response => {
			if(response.statusCode === 200) {
				let activePoint = false;
				let activePoly = false;

				let features = new GeoJSON().readFeatures(response.data, {dataProjection: 'EPSG:4326',featureProjection: 'EPSG:3857'})
				features.forEach(x => x.properties = {highlighted:false});
				let featuresPoint = features.filter(x => {
					return x.getGeometry().getType() === 'Point'
				})
				let featuresPoly = features.filter(x => x.getGeometry().getType() !== 'Point')

				const vectorSourcePoint = new VectorSource({
					features: featuresPoint,
				});

				const vectorSourcePoly = new VectorSource({
					features: featuresPoly,
				});

				const clusterSource = new Cluster({distance: 40, source: vectorSourcePoint,  geometryFunction:clusterFunction});

				olmap.getLayers().forEach(function(el) {
					if (el.get('id') === "dynamic_point") {
						activePoint = el;
					}

					if (el.get('id') === "dynamic_poly") {
						activePoly = el;
					}
				});

				if(activePoint) {
					if(enableCluster) {
						activePoint.setSource(clusterSource);
					}
					else{
						activePoint.setSource(vectorSourcePoint);
					}
				}else {
					let vectorLayer = new VectorLayer({
						source: enableCluster ? clusterSource : vectorSourcePoint,
						renderMode: 'image',
					});
					activePoint = vectorLayer;
					vectorLayer.set('id', 'dynamic_point');
					vectorLayer.setStyle(dynamicCadastreStyleFunction(props.cadastreData, vectorLayer, owletData))

					olmap.addLayer(vectorLayer);
				}


				if(activePoly) {
					activePoly.setSource(vectorSourcePoly);
				}  else {
					let vectorLayer = new VectorLayer({
						source: vectorSourcePoly,
						renderMode: 'image'
					});
					vectorLayer.set('id', 'dynamic_poly');
					vectorLayer.setStyle(dynamicCadastreStyleFunction(props.cadastreData, vectorLayer, owletData))
					activePoly = vectorLayer;
					olmap.addLayer(vectorLayer);
				}
				setLoadedCadastreDevices(features.length)
				props.setLayerLoading(false);
				setActiveDynamicCadastres(true);

				// activePoint és activePoly layereken lévő elemekre zoomolni
				if(response.data.features.length){
					const message = Toastify({

						text: "Adatrétegek sikeresen frissítve.<br/><span style='text-decoration: underline'>Kattintson a bezoomoláshoz.</span>",
						escapeMarkup: false,
						close: true,
						stopOnFocus: false,
						duration: 15000,
						style: {
							background: "#63c965",
						},
						onClick: function (){
							// console.log('activePoly.getSource().getExtent()', activePoly.getSource().getExtent())
							// console.log('activePoint.getSource().getExtent()', activePoint.getSource().getExtent())

							const extent = createEmpty();

							const activePointExtent = activePoint.getSource().getExtent();
							const hasInvalidActivePointValues = activePointExtent.some(value => value === null || !isFinite(value));

							if (activePoint && !hasInvalidActivePointValues) {
								extend(extent, activePointExtent);
							}

							const activePolyExtent = activePoly.getSource().getExtent();
							const hasInvalidActivePolyValues = activePolyExtent.some(value => value === null || !isFinite(value));

							if (activePoly && !hasInvalidActivePolyValues) {
								extend(extent, activePolyExtent);
							}

							olmap.getView().fit(extent, {size: olmap.getSize(), maxZoom: 18});
							olmap.getView().setZoom(olmap.getView().getZoom()-1);
							message.hideToast();
						}

					}).showToast();
				}else{
					Toastify({
						text: "Nincs találat a keresett kifejezésre",
						close: true,
						stopOnFocus: false,
						duration: 3000,
						style: {
							background: "#f06640",
						},
					}).showToast();
				}
			}else {
				Toastify({

					text: "Adatrétegek frissítése sikertelen",
					close: true,
					stopOnFocus: false,
					duration: 3000,
					style: {
						background: "#ff0000",
					},

				}).showToast();

			}
		}).catch(err => {
			props.setLayerLoading(false);
			console.log(err);
		})
	}

	function loadLayer(cadastre_ref, filter, reload, cadastre_id) {
		let deleted = false;
		let activeLayer = null;

		olmap.getLayers().forEach(function(el) {
			if (el.get('id') === cadastre_ref) {
				activeLayer = el;
			}
			if(el.name === 'lights') {
				let currentIndex = el.getZIndex()
				if(currentIndex === undefined) {
					el.setZIndex(5)
				}
			}
		})
		if(activeLayer !== null && !filter && !reload) {
			setActiveLayers(activeLayers.filter(layer => cadastre_ref !== layer));
			olmap.removeLayer(activeLayer);
			deleted = true;
		}
		let style = {};

		if(!deleted) {
			const layerData = Config.layers[cadastre_ref]

			const source = new VectorSource({
				format: new GeoJSON({geometryName:cadastre_ref}),
				url: './geojson/' + layerData.path,
			});

			let savedStyle = props.cadastreData[cadastre_id].style;

			if(layerData.style) {
				style = getNumberStyler(layerData, savedStyle);
			}
			else {
				style = getCadastreLayerStyle(layerData, savedStyle)
			}

			let layer;

			if(layerData.zoom){
				layer = new VectorLayer({source, style, minZoom:layerData.zoom, zIndex:99});
				olmap.getView().animate({
					zoom: layerData.zoom + 1,
					duration: 2000
				  })
			}
			else{
				layer = new VectorLayer({ source, style });
			}

			layer.set('id', cadastre_ref);
			setActiveLayers(prevState => {return [...prevState, cadastre_ref]});
			olmap.addLayer(layer);
		}
		if(!reload) {
			props.setLoadedCadastreLayers({name:cadastre_ref,style:style,cadastre_id:cadastre_id});
		}
	}

	//handle tile layer change
	useEffect(() => {

		if(loaded) {
			props.loadedTiles.forEach((tile, index) => {
				olmap.getLayers().forEach(function(el) {
					if (el.get('id') === tile.name) {
						el.setZIndex(10-index);
					}
				});
			});
		}
		
	}, [props.loadedTiles]) // eslint-disable-line

	function loadTileLayer(tileId, forced) {
		const tile = props.tileConfig.find(x => x.ref === tileId) || false;
		if(tileLayers[tileId]){
			tileLayers[tileId].setVisible(forced ? true : !tileLayers[tileId].getVisible());
		}else{
			const newTileLayer = new OlLayerTile({
				source: new OlSourceXYZ({ url: tile.url, crossOrigin: 'Anonymous'}),
				visible:true,
				opacity: 1
			});
			newTileLayer.set('id', tile.name);
			newTileLayer.tile = tile;
			olmap.addLayer(newTileLayer);

			setLayers((prevState) => ({ ...prevState, [tile.name]: newTileLayer}));
			setTileLayers((prevState) => ({ ...prevState, [tileId]: newTileLayer}));
		}

		props.setActiveTileLayers(values(tileLayers).filter(layer => layer.getVisible()).map(layer => layer.tile))
	}

	function handleProjectLoad(projectid) {
		if(loadedProjects.includes(projectid)) {
			setLoadedProjects(loadedProjects.filter(x => x !== projectid))
		}else {
			setLoadedProjects(prevState => [...prevState, projectid])
		}
	}

	function loadProject() {
		let removed = false;
		let active = false;
		props.setLayerLoading(true);


		olmap.getLayers().forEach(function(layer) {
			if(layer !== undefined ) {
				if(layer.get('id') === "projects") {
					active = layer;
					if(!loadedProjects.length) {
						olmap.removeLayer(layer);
						removed = true;
					}
				}
			}
		})

		if(!removed) {

			let promises = []
			let projectTasks = [];
			setLoadingTasks(true);
			if(loadedProjects.length) {
				loadedProjects.forEach(project => {
					promises.push(
						TaskUpdateControllerInst.getTasksByProjectId(project, null, null, props.navigationState.query.searchFilters, props.navigationState.query.term, {})
							.then(response => {
								if(response.statusCode === 200){
									projectTasks = [...projectTasks, ...response.data.data]
								}else{
									console.log(response);
								}
							})
					);
				})
			}

			Promise.all(promises).then(() => {
				let features = [];

				projectTasks.forEach(task => {
					if(typeof task.device.geometry === 'object' && task.device.geometry !== null) {
						if(Object.keys(task.device.geometry).length) {
							const feature = new Feature(new Point(fromLonLat(task.device.geometry.coordinates)));
							feature.properties = {...task.device}
							let temp = {
								id:task.id,
								app: task.app,
								done: task.done,
								approved:task.approved,
								status:task.status
							}

							feature.setProperties(omit('geometry')({...task.device, task:temp}));
							feature.geometry = task.device.geometry;
							features.push(feature);
						}
					}
				});
	
				let source = new VectorSource({crossOrigin: 'Anonymous'});
				let clusterSource = new Cluster({distance: 40, source: source });
				setLoadedTasks(features.length);
				source.addFeatures(features);
	
				let layer = {};
	
				if(active) {
					if(projectCluster) {
						active.setSource(clusterSource);
					}else {
						active.setSource(source);
					}
				}else {
					if(projectCluster) {
						layer = new VectorLayer({
							source:clusterSource,
						});
						layer.setStyle(dynamicCadastreStyleFunction(props.cadastreData, layer))

					}
					else {
						layer = new VectorLayer({
							source: source,
						});
						layer.setStyle(dynamicCadastreStyleFunction(props.cadastreData, layer))
					}
					layer.set('id', "projects");
					olmap.addLayer(layer);
				}
				setLoadingTasks(false);
			});
		}
		props.setLayerLoading(false);
	}

	function removePoi() {
		olmap.getLayers().forEach(el => {
			if (el?.name === 'poi') {
				olmap.removeLayer(el);
			}
		})
	}

	function createPoi(coords) {
		let temp = {}
		//create layer
		//Create feature
		const feature = new Feature(new Point(fromLonLat(coords || POIcoords)));
		feature.properties = {highlight:false};
		let dummicon = {
			anchor: [0.5,1],
			src: "markers/map_marker.png",
			scale: 1
		}
		const style = new Style({
			image: new Icon(dummicon)
		});

		feature.setStyle(style);

		if(!activeLayers.includes("poi")) {
			temp["poi"] = {};
			temp["poi"].source = new VectorSource({crossOrigin: 'Anonymous'});


			temp["poi"].layer = new VectorLayer({
				source: temp["poi"].source,
			});

			temp["poi"].layer.name = "poi";
			temp["poi"].layer.set('id', "poi");
			temp["poi"].layer.setZIndex(130);

			olmap.addLayer(temp["poi"].layer);
			temp["poi"].source.addFeature(feature);
			setShowPOIModal(false)

		}else {
			layersRef.current.poi.source.addFeature(feature);
			setShowPOIModal(false)
		}
		props.setInteraction("");

		return temp["poi"].layer;
	}

	function modifyStyle(cadastre_ref, style, type) {
		let layer = false;
		const layerData = Config.layers[cadastre_ref]
		let refactStyle = new Style({
			stroke: new Stroke({
				color: style.strokeColor !== "" ? style.strokeColor : layerData ? layerData.stroke.color : "#FF5500",
				width: style.stroke !== "" ? parseInt(style.stroke) : layerData ? layerData.stroke.width : "2"
			}),
			fill: new Fill({
				color: style.fillColor !== "" ? "rgba(" + style.fillColor.r  +","+ style.fillColor.g + "," + style.fillColor.b +  "," + style.fillColor.a + ")" : layerData ? layerData.fill : "rgba(255,85,0,0.2)"
			}),
		});

		if(type === "dynamic") {
			olmap.getLayers().forEach(el => {
				if(el.get('id') === 'dynamic_poly') {
					layer = el;
				}
			});

			if(layer) {
				layer.getSource().getFeatures().forEach(feature => {
					if(feature.values_.cadastre_id === cadastre_ref) {
						feature.setStyle(refactStyle)
					}
				});

			}
		} else {
			olmap.getLayers().forEach(function(el) {
				if (el.get('id') === cadastre_ref) {
					layer = el;
				}
			});
			if(layer && layerData.style) {
				let numberStyle = getNumberStyler(layerData, style);
				layer.setStyle(numberStyle);
			}else {
				layer.setStyle(refactStyle);

			}
		}
	}

	//for child
	const [interactionType, setInteractionType] = useState(false);
    const [interactionEditing, setInteractionEditing] = useState(false);

	function clearMapInteractionsParent() {
		setWriteText(false);
        setCanDraw(false);
        setInteractionType(false);
        setCanEditInteraction(false);
        setInteractionEditing(false);

		olmap.removeInteraction(layers.draw.freehand);
        olmap.removeInteraction(layers.draw.freehandDrawing);
        olmap.removeInteraction(layers.draw.arrow);
	}


	//TITLE LAYER - CSAK SZÖVEG
	const [loadedTitleLayers, setLoadedTitleLayers] = useState([]);

	function loadTitleLayers(layer) {

		if(loadedTitleLayers.includes(layer.id)) {
			//layer törlése
			olmap.getLayers().forEach(function(el) {
				if (el.get('id') === layer.id) {
					olmap.removeLayer(el);
					setLoadedTitleLayers(loadedTitleLayers.filter(y => y !== layer.id))
				}
			})

		}else {
			//layer felrakása
			MapControllerInst.getTitleLayerById(layer.id).then(res => {
				//felrakjuk majd map-re
				let features = new GeoJSON().readFeatures(res.data, {dataProjection: 'EPSG:4326',featureProjection: 'EPSG:3857'})
				const vectorSource = new VectorSource({
					features: features,
				});

				let vectorLayer = new VectorLayer({
					source: vectorSource,
					renderMode: 'image',
					minZoom:layer.zoom
				});

				vectorLayer.setZIndex(130);
				vectorLayer.set('id', layer.id);
				vectorLayer.setStyle(TitleLayerStyleFunction(layer.style));
				setLoadedTitleLayers([...loadedTitleLayers, layer.id]);
				olmap.addLayer(vectorLayer);


				if(olmap.getView().getZoom() < layer.zoom+1) {
					olmap.getView().animate({
						zoom: layer.zoom +1,
						duration: 2000
					});
				}
			})
		}
	}


	function addTextToMap() {
		let style = new Style({
			text: new Text({
				text: text.text,
				placement: 'point',
				textAlign: 'center',
				offsetY: -25,
				font: '18px sans-serif',
				fill: new Fill({
					color: '#444444'
				}),
				backgroundFill : new Fill({
					color: 'rgb(245, 245, 245, 0.7)'
				})
			})
		});
		let feature = new Feature({geometry:new Point(fromLonLat(text.position)),name: 'text'});
		feature.setStyle(style);

		layers.textSource.addFeature(feature);

		const drag = new Translate({
			features: new Collection([feature])
		});
		olmap.addInteraction(drag)
		setText({position:[], text:""});
		overlay.setPosition(undefined);

	}

	const styles = {
		position: 'relative',
	};

	//Dragging details window
	const [boxes, setBoxes] = useState({
		a: { top: 6, right: 840 },
	});
	const moveBox = useCallback((id, right, top) => {
		setBoxes(update(boxes, {
			[id]: {
				$merge: { right, top },
			},
		}));
	}, [boxes, setBoxes]);
	const [, drop] = useDrop(() => ({
		accept: "box",
		drop(item, monitor) {
			const delta = monitor.getDifferenceFromInitialOffset();
			const right = Math.round(item.right - delta.x);
			const top = Math.round(item.top + delta.y);
			moveBox(item.id, right, top);
			return undefined;
		},
	}), [moveBox]);

		const allCities = Object.values(props.cadastreData)
			.flatMap((item) => item.city_names ? item.city_names : [])
			.filter((city, index, self) => (self.findIndex((item) => (city.name === item.name)) === index)); // Ensures unique city names
	
	return (
		<div className={props.navigationState.projectSidebar ? 'mapview' : 'mapview full'} onKeyDown={stopDrawing} tabIndex="0">
			{/* <input value={test} onChange={(evt) => setTest(evt.target.value)}/> */}
			<div ref={drop} style={styles}>
				{Object.keys(boxes).map((key) => {
				 	const { right, top } = boxes[key];
				 	return (<Box 
								removeSelectedItem={removeSelectedItem}
								zoomToFeature={zoomToFeature}
								findFeaturesById={findFeaturesById}
								clearDraw={clearDraw}
								highlighted={highlighted}
								updateFeature={updateFeature}
								navigationState={props.navigationState}
					  			key={key} id={key} right={right} top={top} />);
				 })}
			<div className="map-buttons">
				<MapButton tooltip={"Kijelölés"} toggle={() => {setDraw("select"); setCanDraw(true)}} buttonClass={'map-button closed draw-button'} icon={['far', 'draw-polygon']} type={"select"}/>
				<MapButton tooltip={"Elemek eltávolitása"} toggle={clearDraw} buttonClass={'map-button closed clear-button'} icon={['far', 'eraser']}/>
				<MapButton tooltip={"Redukálás"} toggle={() => {setDraw("reduce"); setCanDraw(true)}} buttonClass={'map-button closed reduce-button'} icon={['far', 'cut']} type={"reduce"}/>
				<MapButton tooltip={"Távolságmérés"} toggle={() => {setMeasure("line"); setCanDraw(true)}} buttonClass={'map-button closed ruler-button'} icon={['far', 'ruler']} type={"line"}/>
				<MapButton tooltip={"Területmérés"} toggle={() => {setMeasure("area"); setCanDraw(true)}} buttonClass={'map-button closed sizer-button'} icon={['far', 'ruler-triangle']} type={"area"}/>
				<MapButton tooltip={"Négyzetes területmérés"} toggle={() => {setMeasure("Square"); setCanDraw(true)}} buttonClass={'map-button closed square-button'} icon={['far', 'draw-square']} type={"Square"}/>
				<MapButton tooltip={"Térkép mentése képként"} toggle={() => {downloadMap(olmap)}} buttonClass={'map-button closed square-button'} icon={['far', 'download']} type={null} download={true}/>
				{ /* <MapButton tooltip={"Pont lerakás"} toggle={() => {
					setCanPlacePOI(!canPlacePOI)
				}} buttonClass={'map-button closed square-button'} icon={['far', 'map-marker-alt']} type={"poi"} /> */ }

				{(canDraw || canPlacePOI) &&
					<div title="Térképre rajzolt elemek törlése" className='map-button closed text-center' onClick={() => resetInteractions()} style={{backgroundColor: "#ff6459"}}>
						<i className="icon"><FontAwesomeIcon icon={['far', 'times']} /></i>
					</div>
				}

			</div>
			<div className="zoom-display">
				{olmap !== undefined &&	<p>Zoom : { Math.floor(zoom)}</p>}
			</div>
			<MapInteractions 
				olmap={olmap} 
				layers={layers} 
				setWriteText={setWriteText} 
				setCanDraw={setCanDraw} 
				editInteractionFeature={editInteractionFeature} 
				setCanEditInteraction={setCanEditInteraction}
				type={interactionType}
				setType={setInteractionType}
				editing={interactionEditing}
				setEditing={setInteractionEditing}
				removeMeasureInteractions={clearInteractions}
				setReduxInteraction={props.setInteraction}
				/>

			{props.layerOptions.loadingLayer && <div className="loader-container-map-layer">
				<div className="loader" style={{margin:"auto"}} />
			</div>}
			
			{allCities?.length > 0 && (
				<div className="city-selector-container"><select
				className='city-selector'
				value={selectedCity || ''}
				onChange={handleCityChange}
				>
				<option value="">válassz várost</option>
				{allCities.map((city, index) => (
					<option key={index} value={city.name}>{city.name}</option>
				))}
				</select></div>
			)}

			<div id="inner-map" className="inner-map" style={{height: props.filtersHeight !== undefined ? (height-props.filtersHeight + 50) : (height  + 50) + "px", cursor: canPlacePOI ? "crosshair" : "default"}} />

			<div className="map-information-container">
				<Row>
					<Col lg={2}>
						<img alt="foldugylogo" src="/logos/foldugylogo.png" width='80px' height='80px' />
					</Col>
					<Col lg={10}>
						<p>A térkép tájékoztató jellegű, másolata semmilyen hivatalos eljárásban nem használható fel. Az ingatlanok közhiteles adatait a járási hivatalok földhivatali osztályai szolgáltatják. <a rel="noopener noreferrer" href="https://www.foldugy.hu" target="_blank">www.foldugy.hu</a></p>
					</Col>
				</Row>
			</div>
			<div ref={popup} id="popup" className="ol-popup">
                <div id="popup-content" className="popup-content-map">
					<input placeholder="ide irhat" onChange={(evt) => {setText({...text, text:evt.target.value})}} value={text.text} />
					<button className="accept" onClick={addTextToMap}><FontAwesomeIcon icon={['far', 'check']} /></button>
					<button className="exit" onClick={() => overlay.setPosition(undefined)}><FontAwesomeIcon icon={['far', 'times']} /></button>
				</div>
            </div>
			<a id="image-download" download="map.png" />
			<div className="project-sidebar col-md-12">
				<ProjectSidebar
					type={'map'}
					loadProject={handleProjectLoad}
					loadLayer={loadLayer}
					loadedLayers={activeLayers}
					modifyStyle={modifyStyle}
					loadedProjects={loadedProjects}
					setEnableCluster={setEnableCluster}
					enableCluster={enableCluster}
					loadTileLayer={loadTileLayer}
					setProjectCluster={setProjectCluster}
					projectCluster={projectCluster}
					loadedElements={{cadastre:loadedCadastreDevices, project:loadedTasks}}
					loadingTasks={loadingTasks}
					loadTitleLayers={loadTitleLayers}
				/>
			</div>
			<PointOfInterestModal show={showPOIModal} handleClose={() => setShowPOIModal(false)} coordinate={POIcoords} createPoi={createPoi}/>
		</div>
		</div>
	);

}

const mapStateToProps = state => ({
	navigationState: getNavigationState(state),
	layerOptions: getMapLayerOptions(state),
	loading : getLoadingData(state),
	activeProject: getActiveProject(state),
	cadastreData : getUserCadastreData(state),
    loadedTiles: getActiveTileLayers(state)

});

const mapDispatchToProps = (dispatch) => ({
	setMapDetail: (markerdata) => {
		dispatch(setMapDetail(markerdata))
	},
	setMapDetailHide: () => {
		dispatch(setMapDetailHide());
	},
	setMapWidth: (width) => {
		dispatch(setMapWidth(width));
	},
	toggleView: (id) => {
		dispatch(toggleView(id));
	},
	onSearch: (query) => {
		dispatch(startMapSearch(query));
	},
	setProjectLayer: (item,state) => {
		dispatch(setLayer(item,!state.layers[item]));
	},
	setInteraction: (interaction) => {
		dispatch(setMapInteraction(interaction));
	},
	setLoadingData: (loadingData) => {
        dispatch(setLoadingData(loadingData))
    },
    setLoadingMessage: (message) => {
        dispatch(setLoadingMessage(message))
	},
	setLoadedProjectLayers: (projectid) => {
		dispatch(setLoadedProjectLayers(projectid))
	},
	setLoadedCadastreLayers : (cadastre_ref) => {
		dispatch(setLoadedCadastreLayers(cadastre_ref))
	},
	setLayerLoading : (loading) => {
		dispatch(setLayerLoading(loading))
	},
	setActiveTileLayers: (tiles) => {
		dispatch(setActiveTileLayers(tiles));
	},
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapComponent);
