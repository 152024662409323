import React, { useState, useEffect } from 'react';
import { Button, Form,Row,Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { history } from '../../state/store';
import { addMapFilter, setLoadedProjectLayers, setLoadedCadastreLayers, getMapLayerOptions } from '../../state/reducers/ui';
import Config from '../../config/config';
import { MessageHandler } from '../../controllers/MessageHandlerController';
import { AlertController } from '../../controllers/AlertController';
import { UserController } from '../../controllers/UserController';

import { getUserPhoto, getUserDetails, getUserSettings, setUserDetails } from '../../state/reducers/user';

function SettingPopup(props) {

	const [activeHeaders, setActiveHeaders] = useState([]);
	const [messagesWarning, setMessagesWarning] = useState(false);
	const [messages, setMessages] = useState([]);
	const [editUserDetails, setEditUserDetails] = useState(false);
	const [alerts, _setAlerts] = useState([]);
	const [deleteAlertTrigger, setDeleteAlertTrigger] = useState("");
	const [userData, setUserData] = useState({lastname:'', firstname:'', email:'', user_mobile:''});
	const [showNotify, setShowNotify] = useState(false);

	const MessageHanderControllerInst = MessageHandler.getInstance();
	const AlertControllerInst = AlertController.getInstance();
	const UserControllerInst = UserController.getInstance();

	//refs
	const alertsRef = React.useRef(alerts);
	const setAlerts = data => {
		alertsRef.current = data;
		_setAlerts(data);
	};

	useEffect(() => {
		MessageHanderControllerInst.getMessagesByUser().then(response => {
			if(response.statusCode === 200) {
				setMessagesWarning(false);
				setMessages(response.data);
			}
			else if(response.statusCode === 404) {
				setMessagesWarning("Nem található hozzászólás")
			}
			else {
				setMessagesWarning("A hozzászólásokat nem sikerült betölteni")
			}
		});

		AlertControllerInst.getAlerts().then(response => {
			setAlerts(response.data);

			setTimeout(() => {
				readAlerts();
			}, [6000]);
		});

		setUserData({lastname:props.userDetails.lastname, firstname:props.userDetails.firstname, email:props.userDetails.email, user_mobile:props.userDetails.user_mobile});
	}, []) // eslint-disable-line

    function getSavedFilters() {
        let avalibleFilters = props.userSettings.map.savedFilters;
        return avalibleFilters ? avalibleFilters : {};
    }

	function loadFilterToMap(key) {
		let loadFilter = getSavedFilters()[key.replace(" ", "-").replace(".", "-")];

		if(loadFilter.hasOwnProperty("filters")) {
			props.addMapFilter(loadFilter.filters);
			let ld = loadFilter.layerData
			if(ld !== undefined) {
				if(ld.loadedCadastreLayers.length) {
					ld.loadedCadastreLayers.forEach(layer => {
						ld.loadedCadastreLayers.filter(x => !props.layerOptions.loadedCadastreLayers.includes(x)).forEach(cadastre_ref => {
							props.setLoadedCadastreLayers(cadastre_ref)
						})
					})
				}

				if(ld.loadedProjectLayers.length) {
                    if(ld.loadedProjectLayers.length) {
                        props.setLoadedProjectLayers(ld.loadedProjectLayers);
                    }
				}
			}
		}else {
			props.addMapFilter(loadFilter)
		}

		props.closeSetting();
		history.push("/map")
	}

	function checkHeader(header){
		if(activeHeaders.includes(header)){

			let lista = activeHeaders.filter(value =>{

				if(header == value){ // eslint-disable-line
					return false;
				}
				else{
					return true;
				}
			})
			setActiveHeaders(lista);

		}
		else{
			setActiveHeaders([...activeHeaders,header]);
		}
	};

	function disableError() {
		setTimeout(() => {
			props.resetAlert();
		}, [10000])
	};


	function readAlerts() {
		let alertsToRead = []
		alertsRef.current.forEach(alert => {
			if(alert.read_at === null) {
				alertsToRead.push(alert.rowid)
			}
		});

		AlertControllerInst.readAlert(alertsToRead).then(response => {
			let read = alertsRef.current.map(x => {
				if(x.read_at !== null) {
					x.read_at = ''
				}
				return x;
			});
			setAlerts(read)
		});
	};

	function deleteAlert(id) {
		AlertControllerInst.deleteAlert([id]).then(response => {
			setAlerts(alerts.filter(x => x.rowid !== id));
			setDeleteAlertTrigger('');
		});
	};

	function saveUserData() {
		UserControllerInst.updateUserData(userData).then(response => {
			setUserData({lastname:response.data.lastname, firstname:response.data.firstname, email:response.data.email, user_mobile:response.data.user_mobile})
			props.updateUserData({...props.userDetails, lastname:response.data.lastname, firstname:response.data.firstname, email:response.data.email, user_mobile:response.data.user_mobile})
			setEditUserDetails(false);
		})
	};

	return (
		<React.Fragment>
		<Modal show={props.shown} onHide={props.closeSetting} dialogClassName="profile-modal">
			<Modal.Body>
				<i variant="secondary" className="closebutton" onClick={props.closeSetting}><FontAwesomeIcon size="lg" icon={['fal', 'times']} /></i>
				<Row
					className="profile-header"
					style={{backgroundColor: `${(process.env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR_DEFAULT)}`}}
				>
					<Col lg={12} className="header-button-group d-flex align-items-center justify-content-end">
						{/* <i><UtuLight className="utu_light" style={{width: '56px', padding: '2px',margin:'2px'}}/></i> */}
						<p className="mqtt-status"> <FontAwesomeIcon icon={['fas', 'circle']} color={props.mqttStatus === 'Csatlakozva' ? '#7ace71' : props.mqttStatus === 'Nincs kapcsolat' ? '#d17373' : 'yellow'}/>  <span>{props.mqttStatus}</span></p>
						<button className="notifications" onClick={ () => setShowNotify(!showNotify)}><FontAwesomeIcon size="2x" icon={['fas', 'comment']} /></button>
						{props.alerts !== '' && <div className="alert-message-profile">1</div>}
						{showNotify && <div className="notification-popup">
							<i className="close-button" onClick={() => setShowNotify(false)}><FontAwesomeIcon size="lg" icon={['fal', 'times']} /></i>
							<div className="title">
								<p>Értesítések</p>
							</div>
							<div className="notify-body">
								{props.alerts !== '' && <div className="current-alert"><h6>Értesítés</h6><p><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color={'red'}/> {props.alerts.title} - {props.alerts.desc}  {disableError()}</p></div>}
									<div className="alert-list">
										{alerts.length ? alerts.map(alert => {
											return(
												<div className="alert-list-element">
													<li>{alert.read_at === null ? <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color={'red'}/> : ""} {alert.alert.title} <span className="alert-delete-icon" role="button" style={{float:'right'}} onClick={() => setDeleteAlertTrigger(alert.rowid)}><FontAwesomeIcon icon={['fas', 'trash']} /></span>
														<p><span className="alert-desc">Részletek: {alert.alert.desc}</span></p>
														<p className="alert-date">{alert.date_creation}</p>
													</li>
													{deleteAlertTrigger === alert.rowid && 
													<div className="alert-delete-confirm">
														<p>Biztosan törli a kijelölt értesítést ? </p>
														<button className="yes" onClick={() => deleteAlert(alert.rowid)}>Igen</button>
														<button className="no" onClick={() => setDeleteAlertTrigger("")}>Nem</button>
													</div>}
												</div>
												
											);
										}) : <p>Nincsenek értesítések</p>}
									</div>
							</div>
						</div>}
					</Col>
					<Col lg={12} style={{zIndex:'100'}}>
						<div className="user-img">
							<img alt="user" src={props.userPhoto ? `data:image/jpeg;base64,${props.userPhoto}` : '/logos/def_user.png'}/>
						</div>
					</Col>
				</Row>
				<Row className="profile-body">
					<Col lg={6}>
					{!editUserDetails ?
						<div className="user-data">
							<div className="edit-icon">
								<i className="icon" role="button" onClick={() => setEditUserDetails(!editUserDetails)}><FontAwesomeIcon size="lg" icon={['fal', 'pencil-alt']} /></i>
							</div>
							<div>
								<p className="user-name">{userData.firstname} {userData.lastname}</p>
							</div>
							<div>
								<p className="email">{userData.email}</p>
							</div>
							<div>
								<p className="phone">{userData.user_mobile}</p>
							</div>
						</div>
						
						:
						<div className="edit-profile">
							<Form.Group controlId="fname">
								<Form.Label>Vezetéknév</Form.Label>
								<Form.Control type="name" value={userData.firstname} onChange={(evt) => setUserData({...userData, firstname:evt.target.value})}/>
							</Form.Group>

							<Form.Group controlId="lname">
								<Form.Label>Keresztnév</Form.Label>
								<Form.Control type="name" value={userData.lastname} onChange={(evt) => setUserData({...userData, lastname:evt.target.value})}/>
							</Form.Group>
							<Form.Group controlId="phone">
								<Form.Label>Telefon</Form.Label>
								<Form.Control type="phone" value={userData.user_mobile} onChange={(evt) => setUserData({...userData, user_mobile:evt.target.value})}/>
							</Form.Group>
							<Form.Group controlId="email">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" value={userData.email} onChange={(evt) => setUserData({...userData, email:evt.target.value})}/>
							</Form.Group>
							<Row className="profil-edit-button-group">
								<Button className="save" variant="primary" onClick={() => saveUserData()}> Mentés</Button>
								<Button className="cancel" variant="warning" onClick={() => setEditUserDetails(false)} > Mégse</Button>
							</Row>
						</div>}
					</Col>
					<Col lg={6}>
						<div className="card-header align-items-center">
							Beérkezett üzenetek
						</div>
						<div className="message-container" style={{paddingTop: '10px'}}>
							<h4>Verzió {process.env.REACT_APP_VERSION_NUMBER} <i>- {process.env.REACT_APP_VERSION_DATE}</i></h4>

						</div>
					</Col>
					<Col lg={6} >
						<div className="card-header align-items-center">
							Hozzászólásaim
						</div>
						<div className="comment-container">
							{messagesWarning && <div style={{textAlign:"center", padding:"20px"}}><p>{messagesWarning}</p></div>}
							{messages.length ?
								<div className="comments">
									{messages.map(message => {
										return(
											<div>
											<span>Feladat: {message.project_task_id}</span>
											<span style={{float:'right'}}>{message.modified_at}</span>													
											<br />													
											<span>Hozzászólás: {message.message}</span>
											</div>
										)
									})}
								</div>:
								<p>Még nincsenek hozzászólásai</p>}								
						</div>
					</Col>
					<Col lg={6}>
						<div className="card-header align-items-center">
							Mentett térkép beállítások
						</div>
						<div className="settings-container">
							{Object.keys(getSavedFilters()).length ?
								<ul className="saved-set-list">
									{Object.keys(getSavedFilters()).map( function(key, index) {
										return <li>
													<div className="scs"><img alt="save" src="save1.png"/></div>
													<div className="name">{key}</div>
													<div className="action" onClick={() => loadFilterToMap(key)} role="button">
														<FontAwesomeIcon icon={['far', 'arrow-square-right']} />
													</div>
												</li>
									})}
								</ul>
							:
							<p>Még nem mentett térképbeállitásokat</p>
							}
						</div>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
		</React.Fragment>
	);
};

const mapStateToProps = state => ({
	layerOptions: getMapLayerOptions(state),
	userPhoto: getUserPhoto(state),
	userDetails: getUserDetails(state),
	userSettings: getUserSettings(state)

});


const mapDispatchToProps = (dispatch) => ({
	addMapFilter: (filterObj) => {
		dispatch(addMapFilter(filterObj));
	},
	setLoadedProjectLayers: (projectid) => {
		dispatch(setLoadedProjectLayers(projectid))
	},
	setLoadedCadastreLayers : (cadastre_ref) => {
		dispatch(setLoadedCadastreLayers(cadastre_ref))
	},
	updateUserData: (details) => {
		dispatch(setUserDetails(details))
	}
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingPopup);
