import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function setupTable(cadastre_details, update, activeEdit, width, permission = 'read') {

    let columns = [];
    let comlumnNames = [];

    cadastre_details.forEach((row) => {
        if(row.hasOwnProperty('visible')) {
            if(row.visible.visible_header) {
                comlumnNames.splice(row.visible.order_header, 0, { name:row.name, label:row.label, type:row.input, values:row.values })
            }
        }
    });


    columns.push({
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
            {!row.isGrouped ? row.isExpanded ? 
                <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-down']} /></span>
                : <span>  <FontAwesomeIcon icon={['fas', 'chevron-square-right']} /></span>
                : null
            }
            </span>
    ),
    })

    let modifiedColumns = [];

    if(width < 1350) {
        modifiedColumns = comlumnNames.slice(0, 2);
    }
    else if(width < 1460) {
        modifiedColumns = comlumnNames.slice(0, 3);
    }
    else if(width < 1650) {
        modifiedColumns = comlumnNames.slice(0, 4);
    }
    else {
        modifiedColumns = comlumnNames.slice(0, 5);
    }

    modifiedColumns.forEach((data) => {
        columns.push({
            Header:data.label, 
            disableGroupBy:true,
            accessor: row => {
                if(data.type === 'list') {
                    return data.values[row.json[data.name]]
                }else {
                    return row.json ? row.json[data.name] : ''
                }
            },
        })
    })

    if(permission === 'write'){
        columns.push({
            Header: () => null,
            id: 'comment',
            Aggregated: ({ value }) =>  "",
            Cell: ({ row, value }) => (
                <div>
                    <i role="button" onClick={() => update(row.original.id, row)}>
                        <FontAwesomeIcon size='2x' icon={['fas', 'edit']} color={activeEdit.current === row.original.id ? "#6fd15e" : "#777"}/>
                    </i>
                </div>
            ),

        })
    }

    return columns;
}