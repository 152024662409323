import React, { useState, useEffect } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Leaflet from 'leaflet';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UtuLogo } from '../assets/images/utu_dark.svg';
import Cookies from 'universal-cookie';
import {Row, Col, Modal, Button, Form} from "react-bootstrap";
import { history } from '../state/store';
import ReCAPTCHA from "react-google-recaptcha";
import Config from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import udgLogo from '../assets/images/utu_blue.svg';
import {ForgotPasswordController} from '../controllers/ForgotPasswordController';
import styled from 'styled-components';
const defaultLogo= require('../assets/images/data_light_logo.svg');

const StyledInput = styled.input`
&:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${(process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT)} inset !important;
  }
`;

Leaflet.Icon.Default.imagePath = '../../node_modules/leaflet';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../assets/images/bekescsaba_marker.svg'),
    iconUrl: require('../assets/images/bekescsaba_marker.svg'),
    shadowUrl: null
});

/**
 * Login Component
 * 
 * Basic Functionality :
 *  - Skeleton for Login Component
 * **/
const Login = ({position, center, handleLogin, warning, loading, setLoading}) => {
    const ForgotPasswordControllerInst = ForgotPasswordController.getInstance();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [recoveredPass, setRecoveredPass] = useState(false);
    const [message, setMessage] = useState("");
    const [showPassword, setShowPaessword] = useState(false);
    const [forgotLoading, setForgotLoading] = useState(false);

    const [onMouseLost, setOnMouseLost] = useState(false);

    //ForgotPWModal
    const [recoverPasswordShow, setRecoverPasswordShow] = useState(false)
    const [useremail, setUseremail] = useState("")

    const cookies = new Cookies();
    const cusername = cookies.get("username");
    const cpassword = cookies.get("password");

    const recaptchaRef = React.createRef();

    const [auth, setAuth] = useState({
        username: cusername !== undefined ? cusername : null, 
        password: cpassword !== undefined ? cpassword : null, 
        remember:false
    });

    const [logo, setLogo] = useState(defaultLogo);
    const [bgdImage, setBgdImage] = useState('');

    useEffect(() => {
        const loadLogo = async () => {
            try {
                const dynamicLogo = require(`../assets/images/${process.env.REACT_APP_LOGO}`);
                if (dynamicLogo) {
                    setLogo(dynamicLogo);
                } else {
                    throw new Error(`Logo not found`);
                }
            } catch (error) {
                console.error('Error loading dynamic logo:', error);
            }
        };

        const loadBackgroundImage = async () => {
            try {
                const dynamicBgdImage = require(`../assets/images/${process.env.REACT_APP_BGD_IMG}`);
                if (dynamicBgdImage) {
                    setBgdImage(dynamicBgdImage);
                } else {
                    throw new Error(`Background not found`);
                }
            } catch (error) {
                console.error('Error loading dynamic background:', error);
            }
          };
        
        loadLogo();
        loadBackgroundImage();
      }, []);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const recoverPasswordShowHandleShow = () => setRecoverPasswordShow(true);
    const recoverPasswordShowHandleClose = () => {
        setRecoverPasswordShow(false);
        setMessage("")
        setUseremail(null)
    }

    const handlePassInfoClose = () => setRecoveredPass(false);

    function logout() {
        cookies.remove("username");
        cookies.remove("password");
        history.push("/")
    }

    function recoverPassword() {
        setMessage("");
        setForgotLoading(true);
        ForgotPasswordControllerInst.forgot(useremail)
            .then(response => {
                if (response.statusCode === 200) {
                    setMessage(response.data.body.message);
                } else {
                    setMessage("Amennyiben a felhasználónév már létezik, küldtünk egy emailt a további teendőkkel.");
                }
            })
            .catch(error => {
                setMessage("Hiba történt próbáld újra később.");
            })
            .finally(() => {
                setForgotLoading(false);
            });
    }

    function onChange2(value) {
        if(value) {
            handleLogin(auth);
        }
    }

    return (
        <>
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Rólunk</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Ödvözöljük az oldalon.</p>
            </Modal.Body>                
        </Modal>
        <Modal size="lg" show={recoveredPass} onHide={handlePassInfoClose}>
            <Modal.Header closeButton>
                <Modal.Title>Jelszóváltoztatás</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>           
        </Modal>
        <div className="login-page">
            <div className="map-border">
                <div className="content" style={{backgroundColor: `${(process.env.REACT_APP_BGD_COLOR || process.env.REACT_APP_BGD_COLOR_DEFAULT)}`}}></div>
            </div>
            <div className="map-overlay">
                <div className="content"
                    style={{backgroundColor: `${(process.env.REACT_APP_BGD_COLOR || process.env.REACT_APP_BGD_COLOR_DEFAULT)}`,
                        ...(bgdImage && {
                            backgroundImage: `url(${bgdImage})`
                        })
                    }}
                >
                </div>
            </div>
            <div className="map">
                <Map center={center} zoom="14">
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                    />
                    <Marker position={position}>
                    </Marker>
                </Map>
            </div>
            <div id="login">
                <div className="container">
                    <div className="row">
                        <div className="logo" style={{backgroundColor: `${process.env.REACT_APP_BGD_COLOR}`}}>
                            <img style={{maxHeight: '100px', maxWidth: '93px'}} src={logo} alt={`${process.env.REACT_APP_TITLE} logo`} />
                            {process.env.REACT_APP_TITLE && <div className='city-name'>{process.env.REACT_APP_TITLE}</div>}
                        </div>
                        <div className="text-center">
                            <div className="card text-left" style={{backgroundColor: `${process.env.REACT_APP_BGD_COLOR}`}}>
                                <div className="card-title">Belépés vagy fiók létrehozása</div>
                                <div className="card-body" style={cookies.get("username") !== undefined ? {paddingTop:10} : {}}>
                                {cusername !== undefined && cpassword !== undefined && !sessionStorage.username ?
                                    <div style={{paddingBotton:10}}>
                                        <Row>
                                            <Col>
                                                <p style={{textAlign:"center"}}>{cookies.get("username")}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <form onSubmit={(evt) => {
                                                    evt.preventDefault();
                                                    handleLogin(auth)
                                                    }}>
                                                    <input type="submit" className="btn btn-primary btn-block" value={t('Login')} />
                                                </form>
                                            </Col>
                                            <Col>
                                                <input type="submit" className="btn btn-primary btn-block" value={t('Kijelentkezés')} onClick={logout}/> 
                                            </Col>
                                        </Row>
                                    </div>
                                : 
                                <>
                                    <form onSubmit={(evt) => {
                                        evt.preventDefault();
                                        setLoading(true);
                                        recaptchaRef.current.execute()
                                    }}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                                            onChange={onChange2}
                                        />
                                        <div className="form-group">
                                            <StyledInput
                                                style={{
                                                    boxShadow: `0px 2px 10px ${process.env.REACT_APP_ACTIVE_COLOR}`,
                                                    backgroundColor: `${process.env.REACT_APP_ACTIVE_COLOR}`
                                                }}
                                                type="text"
                                                id="username"
                                                name="username"
                                                className="form-control"
                                                placeholder={t('Username')}
                                                onChange={ (evt) =>
                                                    setAuth({...auth, username:evt.target.value})
                                                }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <StyledInput
                                                style={{
                                                    boxShadow: `0px 2px 10px ${process.env.REACT_APP_ACTIVE_COLOR}`,
                                                    backgroundColor: `${process.env.REACT_APP_ACTIVE_COLOR}`
                                                }}
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                className="form-control"
                                                placeholder={'Jelszó'}
                                                onChange={ (evt) =>
                                                    setAuth({...auth, password:evt.target.value})
                                                }
                                            />
                                            <FontAwesomeIcon title="Jelszó megjelnítése" role="button" onClick={() => setShowPaessword(!showPassword)} className="show-pass" size="1x" icon={['fas', showPassword ? 'eye-slash' : 'eye'] }/>
                                        </div>
                                        {warning && <span className="warning">Felhasználónév vagy jelszó hibás!</span>}
                                        <div className="form-group form-row">
                                            
                                            <div className="lost text-left col">
                                                <i
                                                    style={{
                                                        color: onMouseLost ? `${(process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT)}` : ''
                                                    }}
                                                    onMouseOver={() => setOnMouseLost(true)}
                                                    onMouseOut={() => setOnMouseLost(false)}
                                                    role="button"
                                                    onClick={recoverPasswordShowHandleShow}
                                                >
                                                    {t('Lost Password?')}
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <button
                                                style={{backgroundColor: `${(process.env.REACT_APP_SECONDARY_COLOR || process.env.REACT_APP_SECONDARY_COLOR_DEFAULT)}`}}
                                                type="submit"
                                                className="btn btn-primary btn-block"    
                                            >
                                                {loading ?
                                                    <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                :
                                                    'Belépés'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                
                                    <div className="card-bottom text-center"  style={{color: `${(process.env.REACT_APP_ACTIVE_COLOR || process.env.REACT_APP_ACTIVE_COLOR_DEFAULT)}`}}>
                                        A regisztrációval hozzájárulsz az Általános Szerződési Feltételek és az Adatkezelési Tájékoztatóhoz product of URBEN DESIGN GROUP all rights reserved
                                    </div>                                     
                                </>
                                }
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>

                <div className="bottom-logos">
                    <a target="_blank" rel="noopener noreferrer" href={'https://udg.hu/'}>
                        <img src={udgLogo} alt="Logo" />
                    </a>
                </div>
            </div>
        </div>
        <Modal size="lg" className="other-info-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <div className="logo-container"><UtuLogo className="utu-logo" /></div>
                <Modal.Title>Üdvözöljük a DataLight felületén.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>A <strong>DataLight</strong>© multifunkcionális és személyre szabható városüzemeltetési keretrendszer. Feladata, hogy összegyűjtse, tárolja és megjelenítse mindazon adatokat, amik városa üzemeltetése során keletkeznek. A rendszerben keletkező adatokból információt állít elő az önkormányzati folyamatok résztvevőiek és a döntéshozóknak, segítve a legoptimálisabb megoldások megtalálását a felmerülő problémákra.</p> <p>A belépést követően megkezdheti a rendszer használatát.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>vissza</Button>
            </Modal.Footer>             
        </Modal>

        {/* Modal for recovering PW input */}
        <Modal size="md" show={recoverPasswordShow} onHide={recoverPasswordShowHandleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Jelszóváltoztatás</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="useremail">
                    <Form.Label>Felhasználónév</Form.Label>
                    <Form.Control type="email" placeholder="Adja meg a felhasználónevét" onChange={evt => setUseremail(evt.target.value)}/>
                </Form.Group>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={recoverPasswordShowHandleClose}>Bezárás</Button>
                <Button variant="primary" onClick={recoverPassword}>
                    {forgotLoading ? <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div> : 'Jelszóváltoztatás igénylése'} 
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

Login.propTypes = {};

export default Login;
