import React, { useEffect, useState } from 'react';
import BrowseLayout from '../screens/layouts/BrowseLayout';
import ProjectView from '../components/Project/Project';
import GanttView from '../components/Project/Gantt';
import ProjectSidebar from '../components/Project/ProjectSidebar';
import connect from 'react-redux/es/connect/connect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from "../components/Project/_helper/CustomButton";
import {Row} from "react-bootstrap";
import useComponentVisible from '../helpers/useComponentVisible';
import {
	getProjectState,
	categoryChanged,
	getNavigationState,
	projectTypeChanged,
	activeProjectChanged,
	setLoadedProjectLayers,
	getActiveProject,
	removeOpenedProjectView,
	setOpenedProjectView,
	setPinndedProjectTitle,
	getMapLayerOptions
} from '../state/reducers/ui';

import { getUserProjects } from '../state/reducers/user';
import { TaskUpdateController } from '../controllers/TaskUpdateController';
import { ProjectController } from '../controllers/ProjectController';

const mainView = (pstate, selectedProject, limit, page, setPage, maxPage, tasks, setLimit, fetchingData, total) => {

	return (<>
			 {pstate.type === 'list' && <ProjectView selectedProject={selectedProject} limit={limit} page={page} setPage={setPage} maxPage={maxPage} tasks={tasks} setLimit={setLimit} fetchingData={fetchingData} totalTasks={total}/>}
			 {pstate.type !== 'list' && <GanttView selectedProject={selectedProject} limit={limit} page={page} setPage={setPage} maxPage={maxPage} tasks={tasks} setLimit={setLimit}/>}
		</>);
}

const Projects = (props) => {

	const [selectedProject, setSelectedProject] = useState("");
	const [projectsFilter, setProjectsFilter] = useState('');

	const ProjectControllerInst = ProjectController.getInstance();
	const [projects, setProjects] = useState([]);

	//Tasks
	const TaskUpdateControllerInst = TaskUpdateController.getInstance();
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [tasks, setTasks] = useState([]);
	const [total, setTotal] = useState(0);
	const [maxPage, setMaxpage] = useState(1);

	//base loading
	const [fetchingData, setFetchingData] = useState(false);

	//Helper for out of click hiding.
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	useEffect(() => {
		setFetchingData(true);
		ProjectControllerInst.getAllProject().then(response => {
			if(response.statusCode === 200){
				setProjects(response.data.data.projects);
			}
			setFetchingData(false);
		});
	}, []);

	useEffect(() => {
		if(projects.length > 0) {
			setSelectedProject(projects[0].uuid);
		}
		setSelectedProject()
		if(props.activeProject !== undefined) {
			props.setOpenedProjectView({id:props.activeProject, pinned:false});
			setSelectedProject(props.activeProject);
		}
	}, [projects]); // eslint-disable-line

	useEffect(() => {
		props.activeProjectChanged(selectedProject);
	}, [selectedProject]) // eslint-disable-line

	useEffect(() => {
		if(selectedProject) {
			setFetchingData(true);
			TaskUpdateControllerInst.getTasksByProjectId(selectedProject, limit, page, props.navigationState.query.searchFilters, props.navigationState.query.term, props.layerOptions.searchFilters).then(response => {
				if(response.statusCode === 200){
					setTasks(response.data.data.tasks);
					setTotal(response.data.data.totalTasks);
					setMaxpage(Math.round(response.data.data.totalTasks/limit));
				}
				setFetchingData(false);
			});
		}
	}, [page, limit, selectedProject, props.navigationState.query.term, props.navigationState.query.searchFilters, props.layerOptions.searchFilters]); // eslint-disable-line

	function removeProjetFromHeader(projectid) {
		if(projectid !== selectedProject) {
			props.removeOpenedProjectView(projectid);
		}
	}

	function pinProjectTitle(projectid) {
		props.updatePin(projectid)
	}


	function setupProjectChooseHeader() {
		let activeProjects = props.projectState.openedProjectViews !== undefined ? props.projectState.openedProjectViews : [];
		return activeProjects.map(project => { // eslint-disable-line
			if(Object.keys(props.projects).includes(project.id)) {
				return(
					<div className="project-header-button">
						<div className={ selectedProject === project.id ? 'active' : ''} key={project.id}>
							<FontAwesomeIcon role="button" onClick={() => pinProjectTitle(project.id)} className="project-header-pin" size="1x" icon={[project.pinned ? 'fas' : 'far', 'thumbtack']} />
							<a onClick={() =>{selectProject(project)}} href={"#" + project.id}>{props.projects[project.id].title}</a>
							{(!project.pinned && project.id !== selectedProject) && <FontAwesomeIcon role="button" onClick={() => removeProjetFromHeader(project.id)} className="project-header-remove" size="1x" icon={['fas', 'minus']} />}
						</div>
					</div>
				)
			}
		})
	}

	function selectProject(project) {
		console.log('project', project)
		props.setOpenedProjectView({id:project.uuid, pinned:false});
		setSelectedProject(project.uuid);
		setIsComponentVisible(false);
		props.setLoadedProjectLayers([project.uuid]);
		// props.activeProjectChanged(project);
	}

	return (
	    <BrowseLayout>
			<div className="projectview row align-items-start text-center">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-9">
						</div>
					</div>
				</div>
				<div className="project-sidebar col-md-12">
					<div className={props.navigationState.projectSidebar ? 'project-view' : 'project-view full'}>
						<div className="nav nav-tabs">
							<div className="back-button"><a href="/dashboard"><i><FontAwesomeIcon size="lg" icon={['fas', 'chevron-left']} /></i>Vissza</a></div>
							<div className="project-button" ref={ref}><p role="button" onClick= {() => {setIsComponentVisible(!isComponentVisible)}}>Projekt választás<FontAwesomeIcon  size="sm" color="#9f9f9f"  icon={['fas', isComponentVisible ? "chevron-down" : "chevron-up"]} style={{marginRight:"15px", marginLeft: "5px"}}/></p>							
								<div ref={ref}>
									{isComponentVisible && (
										<div className="detailed-nav-tabs">
											<input
												value={projectsFilter}
												onChange={(evt) => setProjectsFilter(evt.target.value)}
												placeholder="Szűrés"
											/>
											<div className="nav-submenu">
												{projects
													.filter((project) =>
														project.label.toLowerCase().includes(projectsFilter.toLowerCase())
													)
													.map((project) => {
														return (
															<div
																className={selectedProject === project.uuid ? 'active' : ''}
																onClick={() => selectProject(project)}
																key={project.uuid}
															>
																<a href={"#" + project.uuid}>{project.label}</a>
															</div>
														);
													})}
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="project-buttons">
								{setupProjectChooseHeader()}
							</div>
							{/* <Row className="project-nav-button-group">
								<div title="Lista nézet" className="button-wrapper first" ><CustomButton setProjectType={props.setProjectType} name="Lista nézet" type="list" icon={['far', 'list']} projectState={props.projectState} /></div>
								<div title="Gantt nézet" className="button-wrapper"><CustomButton setProjectType={props.setProjectType} name="Gantt nézet" type="gantt" icon={['far', 'stream']} projectState={props.projectState} /></div>
							</Row> */}
						</div>
						{mainView(props.projectState, selectedProject, limit, page, setPage, maxPage, tasks, setLimit, fetchingData, total)}
					</div>
					<ProjectSidebar type={'project'} resizeCallback={()=> null} selectedProject={selectedProject} setSelectedProject={setSelectedProject} loadSavedFilterMissingLayers={false}/>
				</div>
	    	</div>
	    </BrowseLayout>
    );
};

const mapStateToProps = state => ({
  	projectState: getProjectState(state),
  	navigationState: getNavigationState(state),
	projects : getUserProjects(state),
	activeProject: getActiveProject(state),
	layerOptions: getMapLayerOptions(state),
});


const mapDispatchToProps = (dispatch) => ({
	setCategory: (category) => {
		dispatch(categoryChanged(category));
	},
	setProjectType: (projecttype) => {
		dispatch(projectTypeChanged(projecttype));
	},
	activeProjectChanged: (activeProject) =>{
		dispatch(activeProjectChanged(activeProject));
	},
	setLoadedProjectLayers: (loadedProject) =>{
		dispatch(setLoadedProjectLayers(loadedProject));
	},
	setOpenedProjectView: (projectid) => {
		dispatch(setOpenedProjectView(projectid))
	},
	removeOpenedProjectView: (projectid) => {
		dispatch(removeOpenedProjectView(projectid))
	},
	updatePin: (projectid) => {
		dispatch(setPinndedProjectTitle(projectid))
	},

});


export default connect(
    mapStateToProps,
	mapDispatchToProps
)(Projects);
