import React, { useEffect, useState } from 'react';
import LightBox from '../../containers/dashboard/LightBox';
import EnvironmentBox from '../../containers/dashboard/EnvironmentBox';
import TotemBox from '../../containers/dashboard/TotemBox';
import { useTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { getSmartMode, getLoadingData, toggleView, getLoadingMessage } from '../../state/reducers/ui';
import { getUserName } from '../../state/reducers/user';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { Container } from "react-bootstrap";
import { DashboardController } from '../../controllers/DashboardController';

/**
 * All the style object are exported from helper files.
 * Currently these are only exporting an object, but for future if we need logic in these, they can be easliy converted to functions.
 * **/
import { wifi, wifi_details } from "../_helper/_styles/styles_wifi";
import { parking } from "../_helper/_styles/styles_parking";
import { traffic } from "../_helper/_styles/styles_traffic";
import { totem } from "../_helper/_styles/styles_totem";
import { history } from '../../state/store';

import { getUserRights, getUserAccess } from '../../state/reducers/user';
import WifiBox from '../../containers/dashboard/WifiBox';
import TrafficBox from '../../containers/dashboard/TrafficBox';
import ParkingBox from '../../containers/dashboard/ParkingBox';

const DashboardSmart = (props) => {
	const { t } = useTranslation();
    const [lampData, setLampData] = useState({smart: "0", smart_ready: "0", non_smart: "0"});

    const DashboardControllerInst = DashboardController.getInstance();

	const [lightsConsumption, setLightsConsumption] = useState(0);
  
    useEffect(() => {
        if(props?.userAccess?.dashboard?.smart !== "active") {
            history.push("/dashboard");
        }
        props.toggleViewState("smart");

        DashboardControllerInst.getLampData(props?.username).then(response => {
            if(response.statusCode === 200) {
                setLampData(response.data)
        }
        }).catch(err => {
            console.log("err", err);
        });

    }, []);  // eslint-disable-line

	return (
	    <BrowseLayout>
            <Container fluid className="dashboard-smart-container">
                <div className="row">
                    <EnvironmentBox status="active" boxClass="box-environment" title={t('Környezet')} bgColor="#f2e3fb" color="#ca8dee" iconType="fas" icon="temperature-hot" logo="utu_dark.svg" logo_h="30" top="8" detailedRoute={"/smart/environment"} cadastre_id={'66'}/>
                    <LightBox status="active" boxClass="box-light" title={t('Közvilágítás')} bgColor="#fff5dc" color="#ffd573" iconType="fal" icon="lights" logo="exedra-small.png" logo_h="15" top="14" link={'https://hu-bekescsaba.owlet-iot.com/'} detailedRoute={"/smart/light"} lampData={lampData} consum={lightsConsumption} cadastre_id={'55'}/>
                    <WifiBox status="active" boxClass="box-wifi" title={t('WiFi')} bgColor="#e0fdef" color="#83f4bc" iconType="fas" icon="wifi" details={wifi_details} widgets={wifi} logo="utu_dark.svg" logo_h="30"  detailedRoute={"/smart/wifi"} cadastre_id={'68'}/>
                    <TotemBox status="no-data" boxClass="box-totem" statusMessage="2020.03.12 12:23" title={t('Totem')} bgColor="#cadcd5" color="#2b7154" iconType="fal" icon="totem" widgets={totem} logo="utu_dark.svg" logo_h="30" top="8" detailedRoute={"/smart/totem"} cadastre_id={'73'} userRights={props.userRights}/>
                    <ParkingBox status="active" boxClass="box-parking" title={t('Parkolás')} bgColor="#d4dced" color="#5072b7" iconType="fas" icon="parking" widgets={parking} logo="oms_logo.jpg" logo_h="30" top="7"  link={''} detailedRoute={"/smart/park"} cadastre_id={'67'}/>
                    <TrafficBox status="no-change" boxClass="box-traffic" title={t('Közlekedés')} bgColor="#d9f3f6" color="#67ced9" iconType="fas" icon="traffic-light" widgets={traffic} logo="oms_logo.jpg" logo_h="30" top="7" detailedRoute={"/smart/kkm"} cadastre_id={'66'}/>
                </div>
            </Container>
        </BrowseLayout>

	);
};

DashboardSmart.propTypes = {};

const mapStateToProps = state => ({
    username: getUserName(state),
    smartmode: getSmartMode(state),
    loading : getLoadingData(state),
    loadingMessage: getLoadingMessage(state),
    userRights: getUserRights(state),
    userAccess: getUserAccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleViewState: (id) => {
	  dispatch(toggleView(id));
	}
});

export default connect(
  mapStateToProps,
	mapDispatchToProps
)(DashboardSmart);
