// withAuth.jsx
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default function withAuth(ComponentToProtect) {

  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        login: false,
        user: false,
        project:false,
      };
    }

    componentDidMount() {
        if(this.props.location.pathname === "/login") {
          if ((sessionStorage.username && sessionStorage.token)) 
          {
            this.setState({ loading: false, redirect: true, login: true });
          } else {
            this.setState({ loading: false });
          }
        }
        else if(this.props.location.pathname === "/dashboard" || this.props.location.pathname === "/smart" || this.props.location.pathname === "/kataszter"  || this.props.location.pathname === "/thirdparty" || this.props.location.pathname === "/citymarketing")
        {
          if ((sessionStorage.role === "PROJECTUSER") || !sessionStorage.username || !sessionStorage.token) {
              this.setState({ loading: false, redirect:true });
          } else {
              this.setState({ loading: false, redirect: false });
            }
        }
        else if(this.props.location.pathname === "/project") {
          if ((sessionStorage.role === "USER") || !sessionStorage.username || !sessionStorage.token) {
            this.setState({ loading: false, project: true, redirect:true });
        } else {
            this.setState({ loading: false, redirect: false });
          }
        }
        else {
          if ((sessionStorage.username && sessionStorage.token)) {
            this.setState({ loading: false });
          } else {
              this.setState({loading: false, redirect: true})
          }
        }
    }
    render() {

      const { loading, redirect, login, user, project} = this.state;

      if (loading) {
        return null;

      }
      if (redirect) {

        if(login) {
          return <Redirect to="/dashboard" />
        }
        else if(project) {
          return <Redirect to="/dashboard" />
        }
        else if(user) {
          return <Redirect to="/project" />
        }
        else {
          return <Redirect to="/" />
        }
      }
      return <ComponentToProtect {...this.props} />;
    }
  }
}
