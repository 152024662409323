import React, { useState } from 'react';
import BrowseLayout from '../../screens/layouts/BrowseLayout';
import { Container } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InterfaceContent from "../../components/Thirdparty/InterfaceContent";
import ExedraLogo from "../../assets/logos/exedra_big.svg";
import BSmartLogo from "../../assets/logos/bsmart_big.svg";
import BBqubeLogo from "../../assets/logos/bbqube_big.svg";
import NodeguardLogo from "../../assets/logos/nodeguard_big.svg";
import DataLightLogo from "../../assets/logos/data_light_big.svg";

function ThirdpartyDetails() {

    const [key, setKey] = useState('1');

    const logos = [
        { id: 1, content: <InterfaceContent title="Exedra" logo={ExedraLogo} />, logo: <img src={ExedraLogo} alt="Exedra" /> },
        { id: 2, content: <InterfaceContent title="Data Light" logo={DataLightLogo} />, logo: <img src={DataLightLogo} alt="Data Light" /> },
        { id: 3, content: <InterfaceContent title="B Smart" logo={BSmartLogo} />, logo: <img src={BSmartLogo} alt="B Smart" /> },
        { id: 4, content: <InterfaceContent title="Hofeka HDMR" logo={BBqubeLogo} />, logo: <img src={BBqubeLogo} alt="Hofeka HDMR" /> },
        { id: 5, content: <InterfaceContent title="Pharos-DMX" logo={NodeguardLogo} />, logo: <img src={NodeguardLogo} alt="Pharos-DMX" /> }
    ];

    return (
        <BrowseLayout>
            <Container fluid className="detailed-container-thirdparty">
                <div className="tabs-interface-section">
                    <div className="section-content">
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            id="controlled-tab-example"
                            justify
                        >
                            {logos.map((logo) =>
                                <Tab eventKey={logo.id} title={logo.logo}>
                                    {logo.content}
                                </Tab>
                            )}
                        </Tabs>
                    </div>
                </div>
            </Container>
        </BrowseLayout>
    );
}
export default ThirdpartyDetails;