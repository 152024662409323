import React from 'react';
// import React, { useState, useEffect } from 'react';
import LoginContainer from '../containers/Login'
// import { DomainStyleController } from '../controllers/DomainStyleController';

const Login = () => {
    // const [loading, setLoading] = useState(false)
    // const [domainStyle, setDomainStyle] = useState("")
    // const DomainStyleControllerInst = DomainStyleController.getInstance();

    // useEffect(() => {
    //     console.log('REACT_APP_BGD_COLOR', process.env.REACT_APP_BGD_COLOR);
    //     console.log('REACT_APP_ACTIVE_COLOR', process.env.REACT_APP_ACTIVE_COLOR);
    //     console.log('REACT_APP_SECONDARY_COLOR', process.env.REACT_APP_SECONDARY_COLOR);
    // }, []);


    // useEffect(() => {
    //     setLoading(true)
    //     let domain;
    //     if (typeof window !== 'undefined') {
    //         console.log("Current domain:", window.location.hostname);
    //         domain = window.location.hostname;
    //     }
    //     getDomainStyle(domain)
    //     setLoading(false)

    // }, []);


    // function getDomainStyle(domain) {
    //     DomainStyleControllerInst.renderStyle(domain)
    //         .then(response => {
    //             if (response.statusCode === 200) {
    //                 if(response.data.body) {
    //                     response.data.body = JSON.parse(response.data.body)
    //                         console.log('response.data.body', response.data.body);
    //                 } else {
    //                         console.log('!response.data.body', response);
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             // setWarning("An error occurred. Please try again later.");
    //         })
    // }
    const positionX = process.env.REACT_APP_POSITION_X || 46.6774182;
    const positionY = process.env.REACT_APP_POSITION_Y || 21.0926272;

    // centerY = positionY - 0.0556272
    const centerX = process.env.REACT_APP_CENTER_X || 46.6774182;
    const centerY = process.env.REACT_APP_CENTER_Y || 21.037;

    const position = [positionX,positionY];
    const center = [centerX,centerY];

    return (
        // !loading ?
        <LoginContainer position={position} center={center} />
        // :
            // <div className="login-lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    );
};

Login.propTypes = {};

export default Login;
