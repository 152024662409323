import React from 'react';
import { Doughnut } from 'react-chartjs-2'

export default function DoughnutChart(props) {

    //Modify chart thickness. Damn boi it was thick
    let options= {
        cutoutPercentage: 70,
    }

    let labels = props.types.length === 2 ?  [props.types[0], props.types[1]] : [props.types[0], props.types[1], props.types[2]]

    const data = {
        labels: labels,
        datasets: [
          {
            data: props.data,
            backgroundColor: props.color,
          },
        ],
      }

    return(
        <div>
            <div>
                {props?.loading ?
                    <div className="doughnut-loader"></div>
                :
                    <Doughnut data={data} options={options}/>
                }
            </div>
            <div className="chartinfo-doughnut">
                {props?.loading ? (
                    <div style={{marginTop: '53px'}}>
                        <div className="consumption-loader"></div>
                    </div>
                ) : (
                    <p className="data">{props.value}</p>
                )}
                <p className="title">{props.title}</p>
            </div>
        </div>
    );
}