import React from 'react';
import Config from '../../../config/config';

const StreetViewIframe = ({ lat, lon, apiKey }) => {
    const iframeSrc = `${Config.map.street_view.base_url_iframe}?key=${apiKey}&location=${lat},${lon}&fov=80&heading=70&pitch=0`;

    return (
        <iframe
            width="100%"
            height="30%"
            frameBorder="0"
            style={{ border: 0 }}
            src={iframeSrc}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
        ></iframe>
    );
};

export default StreetViewIframe;
